export const styles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  },
  title: {
    color: '#697B8C',
    fontWeight: 700,
    fontSize: 14
  },
  headColumns: {
    background: '#EFF5FC'
  }
});

export default styles;
