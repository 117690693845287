import React, {Component} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import IconExit from '@material-ui/icons/ExitToApp';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../../assets/proserint_logo2.png';
import {styles} from './styles';


export class CustomDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      link: '',
      role: '',
      anchorEl: null,
      open: false
    };
    this.handleCloseSession = this.handleCloseSession.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleGoDocuments = this.handleGoDocuments.bind(this);
    this.handleGoProfile = this.handleGoProfile.bind(this);
    this.handleGoAccountants = this.handleGoAccountants.bind(this);
    this.handleGoCustomers = this.handleGoCustomers.bind(this);
    this.renderDrawer = this.renderDrawer.bind(this);
  }

  async componentWillMount() {
    await sessionStorage.getItem('role');
    this.setState({role: sessionStorage.getItem('role')});

    if (this.state.role.includes('administrador')) {
      this.setState({link: 'Checklist'});
    } else {
      if (this.state.role.includes('contador')) {
        this.setState({link: 'Clientes'});
      }
      if (this.state.role.includes('cliente')) {
        this.setState({link: 'Perfil'});
      }
    }
  }

  handleMenu = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  // eslint-disable-next-line class-methods-use-this
  async handleCloseSession() {
    await sessionStorage.setItem('token', '');
    document.location.reload();
  }

  handleDrawerToggle = () => {
    this.setState(state => ({mobileOpen: !state.mobileOpen}));
  };

  handleGoDocuments() {  // TODO descomentar de ser necesario
    this.setState({link: 'Documentos'});
    this.props.handleGoDocuments();
  }

  handleGoProfile() {
    this.setState({link: 'Perfil'});
    this.props.handleGoProfile();
  }

  handleGoAccountants() {
    this.setState({link: 'Usuarios'});
    this.props.handleGoAccountants();
  }

  handleGoCustomers() {
    this.setState({link: 'Clientes'});
    this.props.handleGoCustomers();
  }

  // eslint-disable-next-line class-methods-use-this
  renderDrawer() {
    const {classes, isAdmin, isAccountant, isCustomer, accountant, email, password} = this.props;
    const {link} = this.state;
    console.log(link,"link");
    const adminDrawer = (
      <List >
        <Link to="/inicio" className={classes.link} href="null">
          <ListItem button className={classes.sections} >
            <Typography variant="subtitle1" className={link === 'Usuarios' ? classes.linkTextSelected : classes.linkText}>
              {/* <ArrowBack />  */}
              Inicio
            </Typography>
          </ListItem>
        </Link>
        <Link to="/checklist" className={classes.link} href="null">
          <ListItem button className={classes.sections} >
            <Typography variant="subtitle1" className={link === 'Checklist' ? classes.linkTextSelected : classes.linkText}>
              {/* <ArrowBack />  */}
              Checklist
            </Typography>
          </ListItem>
        </Link>
      </List>
    );

    if (isAdmin) {
      return adminDrawer;
    }

    return '';
  }

  render() {
    const {classes} = this.props;
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbarWrapper}>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.grow} />
            <Typography variant="subtitle1" color="secondary" noWrap>
              {this.props.name}
            </Typography>
            <IconButton
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              onClick={this.handleMenu}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleCloseSession}> <IconExit /> Cerrar sesión</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
            >
              <div className={classes.toolbar}>
                <div style={{display: "flex", height:"100%", justifyContent: "center", alignItems: "center"}}>
                  <img src={logo} alt="logo" className={classes.logo} />
                </div>
              </div>
              {this.renderDrawer()}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              <div className={classes.toolbar}>
                <div style={{display: "flex", height:"100%", justifyContent: "center", alignItems: "center"}}>
                  <img src={logo} alt="logo" className={classes.logo} />
                </div>
              </div>

              {this.renderDrawer()}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    );
  }
}

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  accountant: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  // handleGoDocuments: PropTypes.func.isRequired, // TODO descomentar de ser necesario
  // handleLoginDora: PropTypes.func.isRequired,
  // handleGoProfile: PropTypes.func.isRequired,
  // handleGoAccountants: PropTypes.func.isRequired,
  // handleGoCustomers: PropTypes.func.isRequired
};


export default (withStyles(styles)(CustomDrawer));

