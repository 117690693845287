import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {getAccountant, getCustomerOfAccountant, createAccountant, patchAccountant, getAccountants} from '../../actions/accountant';
// eslint-disable-next-line max-len
import {getCustomer, getCustomers, getTaxes, getTaxesEnterprise, getCivilStatus, getBankEntitys, getCitys, getEntitys, changeRuc, changeAddress, changeEmployees, changeName, changeLastName, changeDisability, changeObservations, changeBirthdate, changePhone, changeCellPhone, changeCity, changeSpouse, changeChildrens, changeIncomes, changeExpensesEstimation, changeTaxes, changeAccounts, changeBankAccount, changeDocuments, changeTokenDora, changeContact, changeOriginReport, patchCustomer, changeIsActive, changePlan, changeMonthlyPayment, changeAccountantId, cleanPatchCustomer, loginDora, changeFolderLink, changeSignatureExpirationDate, changeNominationExpirationDate} from '../../actions/customers';
import {customerOfAccountantCols, allCustomersCols, accountantsCols} from '../../utils/colTypes';
import {deleteUploaded, upload} from '../../actions/uploader';
import CustomSnackbar from '../common/customSnackbar';
import styles from './styles';
import Profile from '../users';
import Enterprise from '../enterprise';
import Dashboard from '../dashboard';
import DashboardCountable from '../dashboardCountable';
import CheckListCustomer from '../checklistCustomer';
import CustomersTable from './customerTable';
import AccountantTable from './accountantTable';
import Documents from '../documents';
import Drawer from './drawer';
import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';

export class Accountant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      email: '',
      adminCustomers: [],
      documents: null,
      accountants: [],
      accountant: {},
      name: '',
      searchText: '',
      order: 'asc',
      orderBy: 'name',
      id: '',
      customerId: '',
      customerName: '',
      customerLastName: '',
      customerRuc: '',
      folderLink: '',
      role: [],
      showAccountants: true,
      isAdmin: false,
      isCustomer: false,
      isAccountant: false,
      customerType: null,
      showDashboard: false,
      showChecklist: false,
      activeFilter: true
    };
    this.onRucChange = this.onRucChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onIsActiveChange = this.onIsActiveChange.bind(this);
    this.onMonthlyPaymentChange = this.onMonthlyPaymentChange.bind(this);
    this.onAccountantIdChange = this.onAccountantIdChange.bind(this);
    this.onPlanChange = this.onPlanChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmployeesChange = this.onEmployeesChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onCleanPatchCustomer = this.onCleanPatchCustomer.bind(this);
    this.onDisabilityChange = this.onDisabilityChange.bind(this);
    this.onObservationsChange = this.onObservationsChange.bind(this);
    this.onBirthdateChange = this.onBirthdateChange.bind(this);
    this.onChangeSignatureExpirationDate = this.onChangeSignatureExpirationDate.bind(this);
    this.onChangeNominationExpirationDate = this.onChangeNominationExpirationDate.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onCellPhoneChange = this.onCellPhoneChange.bind(this);
    this.onCityChange = this.onCityChange.bind(this);
    this.onSpouseChange = this.onSpouseChange.bind(this);
    this.onChildrensChange = this.onChildrensChange.bind(this);
    this.onIncomesChange = this.onIncomesChange.bind(this);
    this.onExpensesEstimationChange = this.onExpensesEstimationChange.bind(this);
    this.onTaxesChange = this.onTaxesChange.bind(this);
    this.onAccountsChange = this.onAccountsChange.bind(this);
    this.onBankAccountChange = this.onBankAccountChange.bind(this);
    this.onDocumentsChange = this.onDocumentsChange.bind(this);
    this.onTokenDoraChange = this.onTokenDoraChange.bind(this);
    this.onFolderLinkChange = this.onFolderLinkChange.bind(this);
    this.onContactChange = this.onContactChange.bind(this);
    this.onOriginReportChange = this.onOriginReportChange.bind(this);
    this.onCreateAccountant = this.onCreateAccountant.bind(this);
    this.onPatchAccountant = this.onPatchAccountant.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.onDocument = this.onDocument.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.filterData = this.filterData.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleGoDocuments = this.handleGoDocuments.bind(this);
    this.handleGoProfile = this.handleGoProfile.bind(this);
    this.handleLoginDora = this.handleLoginDora.bind(this);
    this.handleGoAccountants = this.handleGoAccountants.bind(this);
    this.handleGoCustomers = this.handleGoCustomers.bind(this);
    this.handleGoChecklist = this.handleGoChecklist.bind(this);
    this.handleGoDashboard = this.handleGoDashboard.bind(this);
    this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderSnackBarCustomer = this.renderSnackBarCustomer.bind(this);
    this.renderStatusFilter = this.renderStatusFilter.bind(this);
    this.handleDeleteUploaded = this.handleDeleteUploaded.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.onDashboard = this.onDashboard.bind(this);
    this.onChecklist = this.onChecklist.bind(this);
  }

  async componentWillMount() {
    this.props.getTaxes();
    this.props.getTaxesEnterprise();
    this.props.getCitys();
    this.props.getCivilStatus();
    this.props.getBankEntitys();
    this.props.getEntitys();
    await sessionStorage.getItem('userId');
    await sessionStorage.getItem('role');
    this.setState({role: sessionStorage.getItem('role')});
    await this.props.getAccountants(
      this.props.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
      this.state.activeFilter
    );
    this.setState({accountants: this.props.accountants});

    if (this.state.role.includes('administrador')) {
      this.setState({isAdmin: true, showAccountants: true});
      await this.props.getAccountant(sessionStorage.userId);
      this.setState({id: this.props.accountant.id});
      this.setState({name: this.props.accountant.name});
      await this.props.getCustomers(
        this.props.searchText,
        this.props.page,
        this.props.rowsPerPage,
        this.props.orderBy,
        this.props.order,
        this.state.activeFilter,
      );
      this.setState({adminCustomers: this.props.allCustomers});
    } else {
      if (this.state.role.includes('cliente')) {
        this.setState({isCustomer: true, showAccountants: false});
        await this.props.getCustomer(sessionStorage.userId);
        this.setState({customer: this.props.customer, email: this.props.customer.email, id: this.props.customer.id, documents: this.props.customer.userId, name: this.props.customer.name, showDashboard: true, showChecklist: false});
        this.props.accountants.forEach((accountant) => {
          if (accountant.id === this.props.customer.accountantId) {
            this.setState({accountant});
          }
        });
      }

      if (this.state.role.includes('contador')) {
        this.setState({isAccountant: true, showAccountants: false});
        await this.props.getAccountant(sessionStorage.userId);
        this.setState({id: this.props.accountant.id});
        this.setState({name: this.props.accountant.name});
        this.props.getCustomerOfAccountant(
          this.props.accountant.id,
          this.props.searchText,
          this.props.page,
          this.props.rowsPerPage,
          this.props.orderBy,
          this.props.order,
        );
        this.setState({customer: this.props.customer});
      }
    }
  }

  onRucChange(value) {
    this.props.changeRuc(value);
  }

  onAddressChange(value) {
    this.props.changeAddress(value);
  }

  onIsActiveChange(value) {
    this.props.changeIsActive(value);
  }

  onMonthlyPaymentChange(value) {
    this.props.changeMonthlyPayment(value);
  }

  onAccountantIdChange(value) {
    this.props.changeAccountantId(value);
  }

  onPlanChange(value) {
    this.props.changePlan(value);
  }

  onNameChange(value) {
    this.props.changeName(value);
  }

  onEmployeesChange(value) {
    this.props.changeEmployees(value);
  }

  onLastNameChange(value) {
    this.props.changeLastName(value);
  }

  onCleanPatchCustomer() {
    this.props.cleanPatchCustomer();
  }

  onDisabilityChange(value) {
    this.props.changeDisability(value);
  }

  onObservationsChange(value) {
    this.props.changeObservations(value);
  }

  onBirthdateChange(value) {
    this.props.changeBirthdate(value);
  }

  onChangeSignatureExpirationDate(value) {
    this.props.changeSignatureExpirationDate(value);
  }

  onChangeNominationExpirationDate(value) {
    this.props.changeNominationExpirationDate(value);
  }

  onPhoneChange(value) {
    this.props.changePhone(value);
  }

  onCellPhoneChange(value) {
    this.props.changeCellPhone(value);
  }

  onCityChange(value) {
    this.props.changeCity(value);
  }

  onSpouseChange(value) {
    this.props.changeSpouse(value);
  }

  onChildrensChange(value) {
    this.props.changeChildrens(value);
  }

  onIncomesChange(value) {
    this.props.changeIncomes(value);
  }

  onExpensesEstimationChange(value) {
    this.props.changeExpensesEstimation(value);
  }

  onTaxesChange(value) {
    this.props.changeTaxes(value);
  }

  onAccountsChange(value) {
    this.props.changeAccounts(value);
  }

  onBankAccountChange(value) {
    this.props.changeBankAccount(value);
  }

  onDocumentsChange(value) {
    this.props.changeDocuments(value);
  }

  onTokenDoraChange(value) {
    this.props.changeTokenDora(value);
  }

  onFolderLinkChange(value) {
    this.props.changeFolderLink(value);
  }

  onContactChange(value) {
    this.props.changeContact(value);
  }

  onOriginReportChange(value) {
    this.props.changeOriginReport(value);
  }

  async onCreateAccountant(value) {
    await this.props.createAccountant(value);
    await this.props.getAccountants(
      this.props.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
      this.state.activeFilter
    );
    this.setState({accountants: this.props.accountants});
  }

  async onPatchAccountant(id, value) {
    await this.props.patchAccountant(id, value);
    await this.props.getAccountants(
      this.props.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
      this.state.activeFilter
    );
    this.setState({accountants: this.props.accountants});
  }

  onDetail(id, type) {
    if (this.state.isAdmin) {
      const customer = this.state.adminCustomers.filter(obj => obj.id === id)[0];
      if (!customer.hasOwnProperty('tokenDora')) {
        customer.tokenDora = null;
      }
      if (type === 'Empresa') {
        this.setState({customer, customerType: 'Empresa', showDashboard: false, showChecklist: false});
      } else {
        this.setState({customer, customerType: 'Persona', showDashboard: false, showChecklist: false});
      }
    } else {
      const customer = this.props.customers.filter(obj => obj.id === id)[0];
      if (!customer.hasOwnProperty('tokenDora')) {
        customer.tokenDora = null;
      }
      this.setState({customer, customerType: type, showDashboard: false, showChecklist: false});
    }
  }

  onDashboard(id, type) {
    let customer = null;
    if (this.state.isAdmin) {
      customer = this.state.adminCustomers.filter(obj => obj.id === id)[0];
    } else {
      customer = this.props.customers.filter(obj => obj.id === id)[0];
    }
    this.setState({customer, showDashboard: true, showChecklist: false});
  }

  onChecklist(id, type) {
    let customer = null;
    if (this.state.isAdmin) {
      customer = this.state.adminCustomers.filter(obj => obj.id === id)[0];
    } else {
      customer = this.props.customers.filter(obj => obj.id === id)[0];
    }
    this.setState({customer, showChecklist: true, showDashboard: false});
  }

  onDocument(id) {
    if (this.state.isAdmin) {
      const customer = this.state.adminCustomers.filter(obj => obj.id === id)[0];
      this.setState({documents: customer.userId});
      this.setState({customerName: customer.name});
      this.setState({customerLastName: customer.lastName});
      this.setState({customerRuc: customer.ruc});
      this.setState({customerRuc: customer.ruc});
      this.setState({folderLink: customer.folderLink});
    } else {
      const customer = this.props.customers.filter(obj => obj.id === id)[0];
      this.setState({documents: customer.userId});
    }
  }

  async onSearch(searchText, table) {
    this.setState({searchText});
    if (this.state.isAdmin) {
      if (table === 'accuontants') {
        await this.props.getAccountants(
          searchText,
          this.props.page,
          this.props.rowsPerPage,
          this.props.orderBy,
          this.props.order,
          this.state.activeFilter
        );
        this.setState({accountants: this.props.accountants});
      }
      if (table === 'customers') {
        await this.props.getCustomers(
          searchText,
          this.props.page,
          this.props.rowsPerPage,
          this.props.orderBy,
          this.props.order,
          this.state.activeFilter,
        );
        this.setState({adminCustomers: this.props.allCustomers});
      }
    } else {
      this.props.getCustomerOfAccountant(this.state.id, searchText, this.props.page, this.props.rowsPerPage, this.props.orderBy, this.props.order);
    }
  }

  async onOrder(orderBy, order, table) {
    if (this.state.isAdmin) {
      if (table === 'accuontants') {
        await this.props.getAccountants(
          this.state.searchText,
          this.props.page,
          this.props.rowsPerPage,
          orderBy,
          order,
          this.state.activeFilter
        );
        this.setState({accountants: this.props.accountants});
      }
      if (table === 'customers') {
        await this.props.getCustomers(
          this.state.searchText,
          this.props.page,
          this.props.rowsPerPage,
          orderBy,
          order,
          this.state.activeFilter,
        );
        this.setState({adminCustomers: this.props.allCustomers});
      }
    } else {
      this.props.getCustomerOfAccountant(this.state.id, this.state.searchText, this.props.page, this.props.rowsPerPage, orderBy, order);
    }
    this.setState({order, orderBy});
  }

  async onChangeStatus() {
    const newStatus = !this.state.activeFilter;
    this.setState({activeFilter: newStatus});
    this.filterData(newStatus);
  }

  async filterData(activeData) {
    const {order, orderBy, searchText} = this.props;

    if (!this.state.showAccountants) {
      if (this.state.isAdmin ) {
        await this.props.getCustomers(
          this.state.searchText,
          this.props.page,
          this.props.rowsPerPage,
          orderBy,
          order,
          activeData,
        );
        this.setState({adminCustomers: this.props.allCustomers});
      }
      if (this.state.isAccountant) {
        this.props.getCustomerOfAccountant(this.state.id, searchText, this.props.page, this.props.rowsPerPage, this.props.orderBy, this.props.order, activeData);
      }
    } else {
      await this.props.getAccountants(
        searchText,
        this.props.page,
        this.props.rowsPerPage,
        this.props.orderBy,
        this.props.order,
        activeData
      );
      this.setState({accountants: this.props.accountants});
    }
  }

  async handleGoBack() {
    if (this.state.isAdmin) {
      await this.props.getCustomers(
        this.props.searchText,
        this.props.page,
        this.props.rowsPerPage,
        this.props.orderBy,
        this.props.order,
        this.state.activeFilter,
      );
      this.setState({adminCustomers: this.props.allCustomers, customer: null, showAccountants: false, searchText: '', showDashboard: false, showChecklist: false});
    } else {
      this.props.getCustomerOfAccountant(
        this.props.accountant.id,
        this.props.searchText,
        this.props.page,
        this.props.rowsPerPage,
        this.props.orderBy,
        this.props.order,
      );
      this.setState({customer: null, documents: null, showAccountants: false, searchText: '', showDashboard: false, showChecklist: false});
    }
  }

  handleGoDocuments() {
    this.setState({customer: null, showAccountants: false, searchText: '', showDashboard: false, showChecklist: false});
  }

  handleGoProfile() {
    this.props.getCustomer(sessionStorage.userId);
    this.setState({customer: this.props.customer, showAccountants: false, searchText: '', showChecklist: false, showDashboard: false});
  }

  handleGoChecklist() {
    this.props.getCustomer(sessionStorage.userId);
    this.setState({customer: this.props.customer, showChecklist: true, showDashboard: false});
  }

  handleGoDashboard() {
    this.props.getCustomer(sessionStorage.userId);
    this.setState({customer: this.props.customer, showChecklist: false, showDashboard: true});
  }

  handleLoginDora() {
    const user = {
      email: this.state.email,
      password: this.props.password
    };
    this.props.loginDora(user);
  }

  async handleGoAccountants() {
    this.setState({searchText: ''});
    await this.props.getAccountants(
      '',
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
      this.state.activeFilter
    );

    this.setState({showAccountants: true, accountants: this.props.accountants});
  }

  async handleGoCustomers() {
    this.setState({searchText: ''});
    if (this.state.isAdmin) {
      await this.props.getCustomers(
        '',
        this.props.page,
        this.props.rowsPerPage,
        this.props.orderBy,
        this.props.order,
        this.state.activeFilter,
      );
      this.setState({adminCustomers: this.props.allCustomers, showDashboard: false, showChecklist: false});
    }
    this.setState({showAccountants: false, customer: null, documents: null});
  }

  async handleUpdateCustomer() {
    await this.props.patchCustomer(this.state.customer.id);
    if (this.state.isCustomer) {
      setTimeout(async () => {
        window.location.reload();
      }, 1500);
    }
  }

  handleDeleteUploaded(name) {
    this.props.deleteUploaded(name);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          open
          message={message}
        />
      );
    }
    return '';
  }

  renderSnackBarCustomer() {
    const {errorCustomer, messageCustomer} = this.props;
    if (messageCustomer) {
      return (
        <CustomSnackbar
          variant={errorCustomer !== null ? 'error' : 'success'}
          open
          message={messageCustomer}
        />
      );
    }
    return '';
  }

  renderStatusFilter() {
    const {classes} = this.props;
    const {activeFilter} = this.state;
    return (
      <FormControl className={classes.formControl}>
        <Select
          id="demo-simple-select"
          value={activeFilter}
          onChange={this.onChangeStatus}
        >
          <MenuItem value>Estado Activo</MenuItem>
          <MenuItem value={false}>Estado Inactivo</MenuItem>
        </Select>
      </FormControl>
    );
  }

  renderComponent() {
    const {customers, dataLength, classes} = this.props;
    const {accountants, customer, showAccountants, documents, searchText, order, orderBy, customerId, customerName, customerLastName, customerRuc, isAdmin, isCustomer, isAccountant, showDashboard, adminCustomers, customerType, showChecklist, folderLink} = this.state;

    if (this.props.isLoading || this.props.customerIsLoading) {
      return (<CircularProgress className={classes.progress} size={50} thickness={7} />);
    }
    if (isAdmin && showAccountants) {
      return this.props.isLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
        <div>
          <div className={classes.FirstFormPaper}>
            <AccountantTable
              onCreateDocument={this.onCreateDocument}
              renderStatusFilter={this.renderStatusFilter}
              rows={accountants}
              cols={accountantsCols}
              onOrder={this.onOrder}
              order={order}
              orderBy={orderBy}
              dataLength={dataLength}
              onDocument={this.onDocument}
              onSearch={this.onSearch}
              searchText={searchText}
              onCreateAccountant={this.onCreateAccountant}
              onPatchAccountant={this.onPatchAccountant}
              deleteUploaded={this.handleDeleteUploaded}
              error={this.props.error}
            />
          </div>
        </div>
      );
    }

    if (isAccountant || isAdmin) {
      if (customer === null && documents === null) {
        return this.props.customerIsLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
          <div>
            <div className={classes.FirstFormPaper}>
              <CustomersTable
                accountants={accountants}
                rows={isAdmin ? adminCustomers : customers}
                cols={isAdmin ? allCustomersCols : customerOfAccountantCols}
                onOrder={this.onOrder}
                order={order}
                orderBy={orderBy}
                dataLength={dataLength}
                onDetail={this.onDetail}
                onDashboard={this.onDashboard}
                onChecklist={this.onChecklist}
                onDocument={this.onDocument}
                onSearch={this.onSearch}
                renderStatusFilter={this.renderStatusFilter}
                searchText={searchText}
                isAdmin={isAdmin}
              />
            </div>
          </div>
        );
      }
    }
    if (customer !== null && customer.entityType == 'Empresa' && showDashboard && !showChecklist) {
      return this.props.customerIsLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
        <div>
          <div className={classes.FirstFormPaper}>
            <Dashboard
              onCleanPatchCustomer={this.onCleanPatchCustomer}
              accountants={accountants}
              isAdmin={isAdmin}
              isCustomer={isCustomer}
              isAccountant={isAccountant}
              customer={this.state.customer}
              type={this.state.customerType}
              taxes={this.props.taxes}
              entitys={this.props.entitys}
              bankEntitys={this.props.bankEntitys}
              civilStatus={this.props.civilStatus}
              citys={this.props.citys}
              handleGoBack={this.handleGoBack}
              register={this.register}
              editUser={this.editUser}
              resetPassword={this.resetPassword}
              onRucChange={this.onRucChange}
              onAddressChange={this.onAddressChange}
              onIsActiveChange={this.onIsActiveChange}
              onMonthlyPaymentChange={this.onMonthlyPaymentChange}
              onAccountantIdChange={this.onAccountantIdChange}
              onPlanChange={this.onPlanChange}
              onNameChange={this.onNameChange}
              onEmployeesChange={this.onEmployeesChange}
              onLastNameChange={this.onLastNameChange}
              onDisabilityChange={this.onDisabilityChange}
              onObservationsChange={this.onObservationsChange}
              onBirthdateChange={this.onBirthdateChange}
              onPhoneChange={this.onPhoneChange}
              onCellPhoneChange={this.onCellPhoneChange}
              onCityChange={this.onCityChange}
              onSpouseChange={this.onSpouseChange}
              onChildrensChange={this.onChildrensChange}
              onIncomesChange={this.onIncomesChange}
              onExpensesEstimationChange={this.onExpensesEstimationChange}
              onTaxesChange={this.onTaxesChange}
              onAccountsChange={this.onAccountsChange}
              onBankAccountChange={this.onBankAccountChange}
              onDocumentsChange={this.onDocumentsChange}
              onTokenDoraChange={this.onTokenDoraChange}
              onFolderLinkChange={this.onFolderLinkChange}
              handleUpdateCustomer={this.handleUpdateCustomer}
              deleteUploaded={this.handleDeleteUploaded}
              isLoadingDocument={this.props.isLoadingDocument}
              upload={this.props.upload}
              password={this.props.password}
              isCustomer={this.state.isCustomer}
            />
          </div>
        </div>
      );
    }
    if (customer !== null && customer.entityType != 'Empresa' && showDashboard && !showChecklist) {
      return this.props.customerIsLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
        <div>
          <div className={classes.FirstFormPaper}>
            <DashboardCountable
              onCleanPatchCustomer={this.onCleanPatchCustomer}
              accountants={accountants}
              isAdmin={isAdmin}
              isCustomer={isCustomer}
              isAccountant={isAccountant}
              customer={this.state.customer}
              type={this.state.customerType}
              taxes={this.props.taxes}
              entitys={this.props.entitys}
              bankEntitys={this.props.bankEntitys}
              civilStatus={this.props.civilStatus}
              citys={this.props.citys}
              handleGoBack={this.handleGoBack}
              register={this.register}
              editUser={this.editUser}
              resetPassword={this.resetPassword}
              onRucChange={this.onRucChange}
              onAddressChange={this.onAddressChange}
              onIsActiveChange={this.onIsActiveChange}
              onMonthlyPaymentChange={this.onMonthlyPaymentChange}
              onAccountantIdChange={this.onAccountantIdChange}
              onPlanChange={this.onPlanChange}
              onNameChange={this.onNameChange}
              onEmployeesChange={this.onEmployeesChange}
              onLastNameChange={this.onLastNameChange}
              onDisabilityChange={this.onDisabilityChange}
              onObservationsChange={this.onObservationsChange}
              onBirthdateChange={this.onBirthdateChange}
              onPhoneChange={this.onPhoneChange}
              onCellPhoneChange={this.onCellPhoneChange}
              onCityChange={this.onCityChange}
              onSpouseChange={this.onSpouseChange}
              onChildrensChange={this.onChildrensChange}
              onIncomesChange={this.onIncomesChange}
              onExpensesEstimationChange={this.onExpensesEstimationChange}
              onTaxesChange={this.onTaxesChange}
              onAccountsChange={this.onAccountsChange}
              onBankAccountChange={this.onBankAccountChange}
              onDocumentsChange={this.onDocumentsChange}
              onTokenDoraChange={this.onTokenDoraChange}
              onFolderLinkChange={this.onFolderLinkChange}
              handleUpdateCustomer={this.handleUpdateCustomer}
              deleteUploaded={this.handleDeleteUploaded}
              isLoadingDocument={this.props.isLoadingDocument}
              upload={this.props.upload}
              password={this.props.password}
              isCustomer={this.state.isCustomer}
            />
          </div>
        </div>
      );
    }
    if (customer !== null && !showDashboard && showChecklist) {
      return this.props.customerIsLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
        <div>
          <div className={classes.FirstFormPaper}>
            <CheckListCustomer
              isAdmin={isAdmin}
              isCustomer={isCustomer}
              isAccountant={isAccountant}
              customer={this.state.customer}
              handleGoBack={this.handleGoBack}
              onOrder={this.onOrder}
            />
          </div>
        </div>
      );
    }
    if (customer !== null && !showDashboard && !showChecklist) {
      return this.props.customerIsLoading ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
        <div>
          <div className={classes.FirstFormPaper}>
            {customer.entityType !== 'Empresa' ?
              <Profile
                onCleanPatchCustomer={this.onCleanPatchCustomer}
                accountants={accountants}
                isAdmin={isAdmin}
                isCustomer={isCustomer}
                isAccountant={isAccountant}
                customer={this.state.customer}
                type={this.state.customerType}
                taxes={this.props.taxes}
                entitys={this.props.entitys}
                bankEntitys={this.props.bankEntitys}
                civilStatus={this.props.civilStatus}
                citys={this.props.citys}
                handleGoBack={this.handleGoBack}
                register={this.register}
                editUser={this.editUser}
                resetPassword={this.resetPassword}
                onRucChange={this.onRucChange}
                onAddressChange={this.onAddressChange}
                onIsActiveChange={this.onIsActiveChange}
                onMonthlyPaymentChange={this.onMonthlyPaymentChange}
                onAccountantIdChange={this.onAccountantIdChange}
                onPlanChange={this.onPlanChange}
                onNameChange={this.onNameChange}
                onEmployeesChange={this.onEmployeesChange}
                onLastNameChange={this.onLastNameChange}
                onDisabilityChange={this.onDisabilityChange}
                onObservationsChange={this.onObservationsChange}
                onBirthdateChange={this.onBirthdateChange}
                onPhoneChange={this.onPhoneChange}
                onCellPhoneChange={this.onCellPhoneChange}
                onCityChange={this.onCityChange}
                onSpouseChange={this.onSpouseChange}
                onChildrensChange={this.onChildrensChange}
                onIncomesChange={this.onIncomesChange}
                onExpensesEstimationChange={this.onExpensesEstimationChange}
                onTaxesChange={this.onTaxesChange}
                onAccountsChange={this.onAccountsChange}
                onBankAccountChange={this.onBankAccountChange}
                onDocumentsChange={this.onDocumentsChange}
                onTokenDoraChange={this.onTokenDoraChange}
                onFolderLinkChange={this.onFolderLinkChange}
                handleUpdateCustomer={this.handleUpdateCustomer}
                deleteUploaded={this.handleDeleteUploaded}
                isLoadingDocument={this.props.isLoadingDocument}
                upload={this.props.upload}
                password={this.props.password}
                isCustomer={this.state.isCustomer}
                onchangeSignatureExpirationDate={this.onChangeSignatureExpirationDate}
              />
              :
              <Enterprise
                onCleanPatchCustomer={this.onCleanPatchCustomer}
                accountants={accountants}
                isAdmin={isAdmin}
                isCustomer={isCustomer}
                isAccountant={isAccountant}
                customer={this.state.customer}
                type={this.state.customerType}
                taxes={this.props.taxesEnterprise}
                entitys={this.props.entitys}
                bankEntitys={this.props.bankEntitys}
                civilStatus={this.props.civilStatus}
                citys={this.props.citys}
                handleGoBack={this.handleGoBack}
                register={this.register}
                editUser={this.editUser}
                resetPassword={this.resetPassword}
                onRucChange={this.onRucChange}
                onAddressChange={this.onAddressChange}
                onIsActiveChange={this.onIsActiveChange}
                onMonthlyPaymentChange={this.onMonthlyPaymentChange}
                onAccountantIdChange={this.onAccountantIdChange}
                onPlanChange={this.onPlanChange}
                onNameChange={this.onNameChange}
                onEmployeesChange={this.onEmployeesChange}
                onLastNameChange={this.onLastNameChange}
                onDisabilityChange={this.onDisabilityChange}
                onObservationsChange={this.onObservationsChange}
                onBirthdateChange={this.onBirthdateChange}
                onchangeSignatureExpirationDate={this.onChangeSignatureExpirationDate}
                onchangeNominationExpirationDate={this.onChangeNominationExpirationDate}
                onPhoneChange={this.onPhoneChange}
                onCellPhoneChange={this.onCellPhoneChange}
                onCityChange={this.onCityChange}
                onSpouseChange={this.onSpouseChange}
                onChildrensChange={this.onChildrensChange}
                onIncomesChange={this.onIncomesChange}
                onExpensesEstimationChange={this.onExpensesEstimationChange}
                onTaxesChange={this.onTaxesChange}
                onAccountsChange={this.onAccountsChange}
                onBankAccountChange={this.onBankAccountChange}
                onDocumentsChange={this.onDocumentsChange}
                onTokenDoraChange={this.onTokenDoraChange}
                onFolderLinkChange={this.onFolderLinkChange}
                onContactChange={this.onContactChange}
                onOriginReportChange={this.onOriginReportChange}
                handleUpdateCustomer={this.handleUpdateCustomer}
                deleteUploaded={this.handleDeleteUploaded}
                isLoadingDocument={this.props.isLoadingDocument}
                upload={this.props.upload}
                password={this.props.password}
                isCustomer={this.state.isCustomer}
              /> }
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className={classes.FirstFormPaper}>
          <Documents
            userId={documents}
            handleGoCustomers={this.handleGoCustomers}
            entitys={this.props.entitys}
            customerId={customerId}
            customerName={customerName}
            customerLastName={customerLastName}
            customerRuc={customerRuc}
            isAdmin={isAdmin}
            isAccountant={isAccountant}
            folderLink={folderLink}
          />
        </div>
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    const {isAdmin, isCustomer, isAccountant, accountant} = this.state;
    return (
      <div className={classes.root}>
        {this.renderSnackBar()}
        {this.renderSnackBarCustomer()}
        <Drawer
          name={this.state.name}
          accountant={accountant}
          customer={this.state.customer}
          isAdmin={isAdmin}
          isCustomer={isCustomer}
          isAccountant={isAccountant}
          handleGoDocuments={this.handleGoDocuments}
          handleGoProfile={this.handleGoProfile}
          handleLoginDora={this.handleLoginDora}
          handleGoAccountants={this.handleGoAccountants}
          handleGoCustomers={this.handleGoCustomers}
          handleGoChecklist={this.handleGoChecklist}
          handleGoDashboard={this.handleGoDashboard}
          email={this.state.email}
          password={this.props.password}
        />
        <div style={{width: '100%'}}>
          {this.renderComponent()}
        </div>
      </div>

    );
  }
}

Accountant.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingDocument: PropTypes.bool.isRequired,
  customerIsLoading: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  allCustomers: PropTypes.array.isRequired,
  accountants: PropTypes.array.isRequired,
  getCustomerOfAccountant: PropTypes.func.isRequired,
  getAccountant: PropTypes.func.isRequired,
  getCustomer: PropTypes.func.isRequired,
  getTaxes: PropTypes.func.isRequired,
  getTaxesEnterprise: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
  getAccountants: PropTypes.func.isRequired,
  getCitys: PropTypes.func.isRequired,
  getEntitys: PropTypes.func.isRequired,
  getBankEntitys: PropTypes.func.isRequired,
  getCivilStatus: PropTypes.func.isRequired,
  changeRuc: PropTypes.func.isRequired,
  loginDora: PropTypes.func.isRequired,
  changeAddress: PropTypes.func.isRequired,
  changeIsActive: PropTypes.func.isRequired,
  changeMonthlyPayment: PropTypes.func.isRequired,
  changeAccountantId: PropTypes.func.isRequired,
  changePlan: PropTypes.func.isRequired,
  changeName: PropTypes.func.isRequired,
  changeEmployees: PropTypes.func.isRequired,
  changeLastName: PropTypes.func.isRequired,
  cleanPatchCustomer: PropTypes.func.isRequired,
  changeDisability: PropTypes.func.isRequired,
  changeObservations: PropTypes.func.isRequired,
  changeBirthdate: PropTypes.func.isRequired,
  changeSignatureExpirationDate: PropTypes.func.isRequired,
  changeNominationExpirationDate: PropTypes.func.isRequired,
  changePhone: PropTypes.func.isRequired,
  changeCellPhone: PropTypes.func.isRequired,
  changeCity: PropTypes.func.isRequired,
  changeSpouse: PropTypes.func.isRequired,
  changeChildrens: PropTypes.func.isRequired,
  changeIncomes: PropTypes.func.isRequired,
  changeExpensesEstimation: PropTypes.func.isRequired,
  changeTaxes: PropTypes.func.isRequired,
  changeAccounts: PropTypes.func.isRequired,
  changeBankAccount: PropTypes.func.isRequired,
  changeDocuments: PropTypes.func.isRequired,
  changeTokenDora: PropTypes.func.isRequired,
  changeFolderLink: PropTypes.func.isRequired,
  createAccountant: PropTypes.func.isRequired,
  deleteUploaded: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  patchAccountant: PropTypes.func.isRequired,
  patchCustomer: PropTypes.func.isRequired,
  accountant: PropTypes.object.isRequired,
  customer: PropTypes.object,
  citys: PropTypes.array.isRequired,
  entitys: PropTypes.array.isRequired,
  civilStatus: PropTypes.array.isRequired,
  bankEntitys: PropTypes.array.isRequired,
  taxes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number,
  errorCustomer: PropTypes.string,
  messageCustomer: PropTypes.string.isRequired,
  error: PropTypes.string
};

const mapStateToProps = (state) => {
  const {accountants, customer, user, uploader} = state;

  return {
    isLoading: accountants.sending,
    isLoadingDocument: uploader.sending,
    customers: accountants.customers,
    accountants: accountants.accountants,
    dataLength: accountants.dataLength,
    error: accountants.error,
    message: accountants.message,
    errorCustomer: customer.error,
    messageCustomer: customer.message,
    searchText: accountants.searchText,
    order: accountants.order,
    orderBy: accountants.orderBy,
    page: accountants.page,
    rowsPerPage: accountants.rowsPerPage,
    filters: accountants.filters,
    accountant: accountants.accountant,
    user: user.user,
    allCustomers: customer.customers,
    customerIsLoading: customer.sending,
    customer: customer.customer,
    password: customer.password,
    taxes: customer.taxes,
    taxesEnterprise: customer.taxesEnterprise,
    citys: customer.citys,
    entitys: customer.entitys,
    bankEntitys: customer.bankEntitys,
    civilStatus: customer.civilStatus
  };
};

const mapDispatchToProps = {
  getCustomerOfAccountant,
  getAccountant,
  getCustomer,
  getTaxes,
  getTaxesEnterprise,
  deleteUploaded,
  upload,
  getCustomers,
  getAccountants,
  getCitys,
  getCivilStatus,
  getBankEntitys,
  getEntitys,
  changeRuc,
  changeAddress,
  changeName,
  changeIsActive,
  changeMonthlyPayment,
  changeAccountantId,
  changePlan,
  changeEmployees,
  changeLastName,
  cleanPatchCustomer,
  changeDisability,
  changeObservations,
  changeBirthdate,
  changePhone,
  changeCellPhone,
  changeCity,
  changeSpouse,
  changeChildrens,
  changeIncomes,
  changeExpensesEstimation,
  changeTaxes,
  changeAccounts,
  changeBankAccount,
  changeDocuments,
  changeTokenDora,
  changeFolderLink,
  changeContact,
  changeOriginReport,
  createAccountant,
  patchAccountant,
  patchCustomer,
  loginDora,
  changeSignatureExpirationDate,
  changeNominationExpirationDate
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Accountant));
