import * as accountantActions from '../actions/types/accountant';

const initialState = {
  customers: [],
  accountants: [],
  accountant: {},
  message: '',
  dataLength: 0,
  sending: true,
  error: null,
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {customers, accountants, accountant, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_SENDING:
    return {...state, sending: true, dataLength, page, rowsPerPage, order, orderBy, searchText};
  case accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_SUCCESS:
    return {...state, sending: false, customers, error: null, dataLength};
  case accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_FAILED:
    return {...state, sending: false, customers: [], error, message};
  case accountantActions.GET_ACCOUNTANT_SENDING:
    return {...state, sending: true};
  case accountantActions.GET_ACCOUNTANT_SUCCESS:
    return {...state, sending: false, accountant, error: null};
  case accountantActions.GET_ACCOUNTANT_FAILED:
    return {...state, sending: false, error, message};
  case accountantActions.GET_ACCOUNTANTS_SENDING:
    return {...state, sending: true, accountants: [], dataLength, searchText, order, orderBy, page, rowsPerPage};
  case accountantActions.GET_ACCOUNTANTS_SUCCESS:
    return {...state, sending: false, accountants, dataLength};
  case accountantActions.GET_ACCOUNTANTS_FAILED:
    return {...state, sending: false, error, message};
  case accountantActions.CREATE_ACCOUNTANT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case accountantActions.CREATE_ACCOUNTANT_SUCCESS:
    return {...state, sending: false, message: 'Contador creado satisfactoriamente', error: null};
  case accountantActions.CREATE_ACCOUNTANT_FAILED:
    return {...state, sending: false, error, message};
  case accountantActions.PATCH_ACCOUNTANT_SENDING:
    return {...state, sending: true, message: '', error: null};
  case accountantActions.PATCH_ACCOUNTANT_SUCCESS:
    return {...state, sending: false, message: 'Contador modificado satisfactoriamente', error: null};
  case accountantActions.PATCH_ACCOUNTANT_FAILED:
    return {...state, sending: false, message, customers: [], error};
  default:
    return state;
  }
};
