export const GET_CUSTOMERS_SENDING = 'GET_CUSTOMERS_SENDING';

export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';

export const GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED';

export const GET_CUSTOMER_SENDING = 'GET_CUSTOMER_SENDING';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';

export const GET_CUSTOMER_FAILED = 'GET_CUSTOMER_FAILED';

export const GET_TAXES_SENDING = 'GET_TAXES_SENDING';

export const GET_TAXES_SUCCESS = 'GET_TAXES_SUCCESS';

export const GET_TAXES_FAILED = 'GET_TAXES_FAILED';

export const GET_TAXES_ENTERPRISE_SENDING = 'GET_TAXES_ENTERPRISE_SENDING';

export const GET_TAXES_ENTERPRISE_SUCCESS = 'GET_TAXES_ENTERPRISE_SUCCESS';

export const GET_TAXES_ENTERPRISE_FAILED = 'GET_TAXES_ENTERPRISE_FAILED';

export const GET_CITYS_SENDING = 'GET_CITYS_SENDING';

export const GET_CITYS_SUCCESS = 'GET_CITYS_SUCCESS';

export const GET_CITYS_FAILED = 'GET_CITYS_FAILED';

export const GET_ENTITYS_SENDING = 'GET_ENTITYS_SENDING';

export const GET_ENTITYS_SUCCESS = 'GET_ENTITYS_SUCCESS';

export const GET_ENTITYS_FAILED = 'GET_ENTITYS_FAILED';

export const GET_BANKENTITYS_SENDING = 'GET_BANKENTITYS_SENDING';

export const GET_BANKENTITYS_SUCCESS = 'GET_BANKENTITYS_SUCCESS';

export const GET_BANKENTITYS_FAILED = 'GET_BANKENTITYS_FAILED';

export const GET_CIVILSTATUS_SENDING = 'GET_CIVILSTATUS_SENDING';

export const GET_CIVILSTATUS_SUCCESS = 'GET_CIVILSTATUS_SUCCESS';

export const GET_CIVILSTATUS_FAILED = 'GET_CIVILSTATUS_FAILED';

export const CREATE_CUSTOMER_SENDING = 'CREATE_CUSTOMER_SENDING';

export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';

export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const EDIT_CUSTOMER_SENDING = 'EDIT_CUSTOMER_SENDING';

export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';

export const EDIT_CUSTOMER_FAILED = 'EDIT_CUSTOMER_FAILED';

export const DELETE_CUSTOMER_SENDING = 'DELETE_CUSTOMER_SENDING';

export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';

export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';

export const CHANGE_ADDRESS_INPUT = 'CHANGE_ADDRESS_INPUT';

export const CHANGE_RUC_INPUT = 'CHANGE_RUC_INPUT';

export const CHANGE_NAME_INPUT = 'CHANGE_NAME_INPUT';

export const CHANGE_CELLPHONE_INPUT = 'CHANGE_CELLPHONE_INPUT';

export const CHANGE_LASTNAME_INPUT = 'CHANGE_LASTNAME_INPUT';

export const CHANGE_PHONE_INPUT = 'CHANGE_PHONE_INPUT';

export const CHANGE_DISABILITY_INPUT = 'CHANGE_DISABILITY_INPUT';

export const CHANGE_BIRTHDATE_INPUT = 'CHANGE_BIRTHDATE_INPUT';

export const CHANGE_EMPLOYEES_INPUT = 'CHANGE_EMPLOYEES_INPUT';

export const CHANGE_OBSERVATIONS_INPUT = 'CHANGE_OBSERVATIONS_INPUT';

export const CHANGE_CITY_INPUT = 'CHANGE_CITY_INPUT';

export const CHANGE_SPOUSE_INPUT = 'CHANGE_SPOUSE_INPUT';

export const CHANGE_CHILDRENS_INPUT = 'CHANGE_CHILDRENS_INPUT';

export const CHANGE_INCOME_INPUT = 'CHANGE_INCOME_INPUT';

export const CHANGE_EXPENSESESTIMATION_INPUT = 'CHANGE_EXPENSESESTIMATION_INPUT';

export const CHANGE_TAXES_INPUT = 'CHANGE_TAXES_INPUT';

export const CHANGE_ACCOUNTS_INPUT = 'CHANGE_ACCOUNTS_INPUT';

export const CHANGE_BANKACCOUNT_INPUT = 'CHANGE_BANKACCOUNT_INPUT';

export const CHANGE_DOCUMENTS_INPUT = 'CHANGE_DOCUMENTS_INPUT';

export const CHANGE_TOKEN_DORA_INPUT = 'CHANGE_TOKEN_DORA_INPUT';

export const CHANGE_FOLDER_LINK_INPUT = 'CHANGE_FOLDER_LINK_INPUT';

export const CHANGE_ISACTIVE_INPUT = 'CHANGE_ISACTIVE_INPUT';

export const CHANGE_PLAN_INPUT = 'CHANGE_PLAN_INPUT';

export const CHANGE_ACCOUNTANTID_INPUT = 'CHANGE_ACCOUNTANTID_INPUT';

export const CHANGE_MONTHLYPAYMENT_INPUT = 'CHANGE_MONTHLYPAYMENT_INPUT';

export const CHANGE_CONTACT = 'CHANGE_CONTACT';

export const CHANGE_ORIGIN_REPORT_INPUT = 'CHANGE_ORIGIN_REPORT_INPUT';

export const LOGIN_DORA = 'LOGIN_DORA';

export const CLEAN_PATCHCUSTOMER = 'CLEAN_PATCHCUSTOMER';

export const CHANGE_SIGNATURE_EXPIRATION_DATE = 'CHANGE_SIGNATURE_EXPIRATION_DATE';

export const CHANGE_NOMINATION_EXPIRATION_DATE = 'CHANGE_NOMINTAION_EXPIRATION_DATE';

