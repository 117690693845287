import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HeadTable from "./headTable";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {getChecklistAll, patchChecklist} from '../../actions/checklist';
import {colsChecklist} from "../../utils/colTypes";
import {styles} from "./styles";

const CheckList = (props) => {
  const {classes} = props;

  const [idItemEdit, setIdItemEdit] = useState(-1);
  const [rows, setRows] = useState(null);
  const [rowEdited, setRowEdited] = useState({id: '', name: '', active: ''});

  useEffect(() => {
    const loadCheckList = async () => {
      const checklistRows = await props.getChecklistAll();
      setRows(checklistRows);
    };
    loadCheckList();
  }, []);

  const changeRow = (e, row) => {
    const value = e.target.value;
    setRowEdited({...rowEdited, name: value});
  }

  const changeRowStatus = (e) => {
    const {value} = e.target;
    setRowEdited({...rowEdited, active: value});
  }

  const saveChangeChecklistItem = async (row) => {
    try {
      await props.patchChecklist(rowEdited.id, {name: rowEdited.name, active: rowEdited.active});
      const checklistRows = await props.getChecklistAll();
      setRows(checklistRows);
      setRowEdited({id: '', name: '', active: ''});
      setIdItemEdit(-1 );
    } catch (e) {
      console.log("err",e);
    }
  }
  return (
    <div>
      <Paper className={classes.root}> 
        <div className={classes.tableWrapper}>
          <Toolbar>
            <div className={classes.title}>
              <Typography variant="h5" id="tableTitle" className={classes.title}>
                Administrar checklist
              </Typography>
            </div>
            <div className={classes.spacer} />
          </Toolbar>  
          { (rows && rows.length > 0) && <Table className={classes.table}>
            <HeadTable cols={colsChecklist} />
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id}>
                  <TableCell className={classes.rowText}>
                  { row.id !== idItemEdit ? row.name :
                    <TextField
                      value={rowEdited.name}
                      fullWidth
                      onChange={(e) => {
                        changeRow(e,rowEdited);
                      }}
                    />
                  }
                  </TableCell>
                  <TableCell className={classes.rowText}>
                    <FormControl className={classes.formControl}>
                      { row.id === idItemEdit ?
                        <Select
                          value={rowEdited.active}
                          onChange={(e)=> {changeRowStatus(e)}}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem value={true} >Activo</MenuItem>
                          <MenuItem value={false}>Inactivo</MenuItem>
                        </Select>
                      : (row.active ? 'Activo' : 'Inactivo') }
                      {row.id !== idItemEdit && row.status}
                    </FormControl>
                  </TableCell>
                  <TableCell align="left">
                    <div className={classes.iconContainer}>
                      <Tooltip title="">
                        <div>
                          {row.id !== idItemEdit && (
                            <EditIcon
                              style={{ cursor: "pointer", color: "grey" }}
                              onClick={() => {
                                setIdItemEdit(row.id);
                                setRowEdited({id: row.id, name: row.name, active: row.active});
                              }}
                            />
                          )}
                          {row.id === idItemEdit && (
                            <CheckIcon
                              style={{ cursor: "pointer", color: "grey" }}
                              onClick={() => {
                                saveChangeChecklistItem(row);
                              }}
                            />
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {rows.length > 0 && (
                <TableRow style={{ height: 48 * rows.length }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
          </Table> }
        </div>
      
      </Paper>
    </div>
  );
};

CheckList.propTypes = {
  classes: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  getChecklistAll: PropTypes.func.isRequired,
  patchChecklist: PropTypes.func.isRequired
};

export default connect(state => ({
  checklist: state.checklist.checklist
}), {
  getChecklistAll,
  patchChecklist
})(withStyles(styles)(CheckList));
