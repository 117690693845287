import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import LockOpen from '@material-ui/icons/LockOpen';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './styles';
import Pagination from './toolbarPaginationTable';
import HeadTable from './headTable';
import PasswordModals from './modalPasswords';


class CustomerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searching: false,
      searchText: '',
      rows: []
    };
    this.onSearch = this.onSearch.bind(this);
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.renderSearchBarButtons = this.renderSearchBarButtons.bind(this);
  }

  componentWillMount() {
    if (this.props.searchText !== '') {
      this.setState({searching: true, searchText: this.props.searchText});
    }

    this.props.rows.forEach((row) => {
      const newRow = row;
      this.props.accountants.forEach((accountant) => {
        if (accountant.id === row.accountantId) {
          newRow.accountantName = accountant.name;
        }
      });
      this.state.rows.push(newRow);
    });
  }

  onSearch() {
    this.props.onSearch(this.state.searchText, 'customers');
  }

  handleCancelSearch() {
    this.setState({searching: false, searchText: ''});
    this.props.onSearch('', 'customers');
  }

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({page: 0, rowsPerPage: event.target.value});
  };

  handlePressEnterSearch(e) {
    if (e.key === 'Enter') {
      this.onSearch(this.state.searchText);
      e.preventDefault();
    }
  }


  renderSearchBar() {
    if (this.state.searching === true) {
      return (
        <div className={this.props.classes.serarchInput} >
          <TextField
            label="Buscar"
            fullWidth
            autoFocus
            value={this.state.searchText}
            onChange={e => this.setState({searchText: e.target.value})}
            onKeyPress={this.handlePressEnterSearch}
          />
        </div>
      );
    }
    return '';
  }


  renderSearchBarButtons() {
    const {classes} = this.props;
    if (this.state.searching === false) {
      return (
        <div className={classes.actions}>
          <Tooltip title="Buscar">

            <Button variant="contained" className={classes.button} onClick={() => this.setState({searching: true})}>
              <SearchIcon style={{color: '#8CA0B3', marginRight: 10}} />
                 Buscar
            </Button>
          </Tooltip>

        </div>
      );
    }
    return (
      <div>
        <IconButton onClick={this.handleCancelSearch}>
          <CloseIcon style={{color: '#8CA0B3'}} />
        </IconButton>
      </div>
    );
  }

  render() {
    const {classes, order, orderBy, cols, isAdmin, rows} = this.props;
    this.props.rows.forEach((row) => {
      const newRow = row;
      this.props.accountants.forEach((accountant) => {
        if (accountant.id === row.accountantId) {
          newRow.accountantName = accountant.name;
        }
      });
      this.state.rows.push(newRow);
    });
    const {rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page * rowsPerPage));

    return (
      <Paper className={classes.root}>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h5" id="tableTitle" className={classes.title}>
                Clientes
            </Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            {this.renderSearchBarButtons()}
            {this.renderSearchBar()}
            {this.props.renderStatusFilter()}
          </div>
        </Toolbar>

        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <HeadTable
              cols={cols}
              order={order}
              orderBy={orderBy}
              onOrder={this.props.onOrder}
            />

            <TableBody>
              {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className={classes.rowText}>{row.entityType === 'Empresa' ? row.entityType : 'Persona'}</TableCell>
                  <TableCell component="th" scope="row" className={classes.rowText}>{row.ruc}</TableCell>
                  <TableCell component="th" scope="row" className={classes.rowText}>{row.rucKey !== -1 ? row.rucKey : ''}</TableCell>
                  <TableCell component="th" scope="row" className={classes.rowText}>{row.name} {row.lastName}</TableCell>
                  <TableCell align="left" className={classes.rowText}>{row.plan}</TableCell>
                  {isAdmin ? <TableCell align="left" className={classes.rowText}>{row.accountantName}</TableCell> : ''}
                  <TableCell align="left" className={classes.rowText}>{row.isActive ? 'Activo' : 'Inactivo'}</TableCell>
                  <TableCell align="left">
                    <div className={classes.iconContainer}>
                      <Tooltip title="Editar">
                        <EditIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.props.onDetail(row.id, row.entityType); }} />
                      </Tooltip>
                      {/* <Tooltip title="Checklist">
                        <AssignmentTurnedInIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.props.onChecklist(row.id, row.entityType); }} />
                      </Tooltip> */}
                      { (row.tokenDora || row.originReport == 'Formulario') &&
                        <Tooltip title="Dashboard">
                          <SignalCellularAltIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.props.onDashboard(row.id, row.entityType); }} />
                        </Tooltip>
                      }
                      <Tooltip title="Documentos">
                        <FolderIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.props.onDocument(row.id); }} />
                      </Tooltip>
                      <Tooltip title="Ver Contraseñas">
                        <PasswordModals currentRow={row} />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{height: 48 * emptyRows}}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            component="div"
            align="right"
            style={{paddingRight: 20}}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={Pagination}
            labelRowsPerPage="Número de Filas"
            labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
            classes={{
              select: classes.select
            }}
          />
        </div>

      </Paper>
    );
  }
}

CustomerTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  onDetail: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  accountants: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onOrder: PropTypes.func.isRequired,
  onDocument: PropTypes.func.isRequired,
  renderStatusFilter: PropTypes.func.isRequired,

};

export default withStyles(styles)(CustomerTable);
