import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import {DatePicker} from 'material-ui-pickers';
import {getDocumentsCustomer, createDocument, deleteDocument, getDocument} from '../../actions/documents';
import {getChecklist} from '../../actions/checklist';
import {deleteUploaded} from '../../actions/uploader';
import Uploader from '../../components/common/uploader';
import {documentsOfCustomer} from '../../utils/colTypes';
import CustomSnackbar from '../common/customSnackbar';
import styles from './styles';
import DocumentTable from './customerTable';
import {Link} from '@material-ui/core';

export class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      searchText: '',
      order: 'asc',
      orderBy: 'entity',
      open: false,
      date: null,
      month: null,
      documents: {
        entity: '',
        file: '',
        name: '',
        checklistId: '',
        link: ''
      }
    };
    this.onSearch = this.onSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.ondownloadDocument = this.ondownloadDocument.bind(this);
    this.onOrder = this.onOrder.bind(this);
    this.onCreateDocument = this.onCreateDocument.bind(this);
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onChangeCheckList = this.onChangeCheckList.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLink = this.onChangeLink.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.validate = this.validate.bind(this);
    this.renderAccountantToolBar = this.renderAccountantToolBar.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.onResendEmail = this.onResendEmail.bind(this);
  }

  componentDidMount() {
    this.props.getDocumentsCustomer(
      this.props.userId,
      this.state.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
    );

    this.props.getChecklist();
  }

  onSearch(searchText) {
    this.props.getDocumentsCustomer(this.props.userId, searchText, this.props.page, this.props.rowsPerPage, this.props.orderBy, this.props.order);
    this.setState({searchText});
  }

  onFilter(month) {
    this.setState({month});
    let monthFilter = null;

    if (month) {
      monthFilter = month.format('YYYY-MM-DD');
    }
    this.props.getDocumentsCustomer(this.props.userId, null, this.props.page, this.props.rowsPerPage, this.props.orderBy, this.props.order, monthFilter);
    this.setState({searchText: ''});
  }

  async onDelete(customerDocument) {
    this.props.deleteUploaded(customerDocument.fileId);

    await this.props.deleteDocument(customerDocument.id);
    this.props.getDocumentsCustomer(
      this.props.userId,
      this.state.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
    );
  }

  async ondownloadDocument(id) {
    await this.props.getDocument(id);
  }

  onOrder(orderBy, order) {
    this.props.getDocumentsCustomer(this.props.userId, this.state.searchText, this.props.page, this.props.rowsPerPage, orderBy, order);
    this.setState({order, orderBy});
  }

  async onCreateDocument() {
    const newDocument = {
      name: this.state.documents.name,
      entity: this.state.documents.entity,
      userId: this.props.userId,
      file: this.state.documents.file,
      checklistId: this.state.documents.checklistId,
      date: this.state.date,
      link: this.state.documents.link
    };
    await this.props.createDocument(newDocument);
    this.props.getDocumentsCustomer(
      this.props.userId,
      this.state.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
    );
    this.setState({open: false});
    this.setState({date: null});
    this.setState({documents: {
      entity: '',
      file: '',
      name: '',
      checklistId: ''
    }});
  }


  onResendEmail() {
    this.props.getDocumentsCustomer(
      this.props.userId,
      this.state.searchText,
      this.props.page,
      this.props.rowsPerPage,
      this.props.orderBy,
      this.props.order,
    );
  }

  onChangeEntity(e) {
    const newDocument = this.state.documents;
    newDocument.entity = e.target.value;
    this.setState({documents: newDocument});
  }

  onChangeName(e) {
    const newDocument = this.state.documents;
    newDocument.name = e.target.value;
    this.setState({documents: newDocument});
  }

  onChangeLink(e) {
    const newDocument = this.state.documents;
    newDocument.link = e.target.value;
    this.setState({documents: newDocument});
  }

  onChangeCheckList(e) {
    const newDocument = this.state.documents;
    newDocument.checklistId = e.target.value;
    this.setState({documents: newDocument});
  }

  onChangeDocumentDate(e) {
    const newDocument = this.state.documents;
    newDocument.date = e.target.value;
    this.setState({documents: newDocument});
  }

  onChangeFile(file) {
    const newDocument = this.state.documents;
    if (this.state.documents.file !== '') { this.props.deleteUploaded(this.state.documents.file); }
    if (file === false) { newDocument.file = ''; } else { newDocument.file = file; }
    this.setState({documents: newDocument});
  }

  handleGoBack() {
    this.props.handleGoCustomers();
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false,
      documents: {
        entity: '',
        file: '',
        name: '',
        checklistId: ''
      }});
    if (this.state.documents.file) { this.props.deleteUploaded(this.state.documents.file); }
  };

  validate() {
    const {documents} = this.state;
    if (documents.entity !== '' 
    // && documents.file !== '' 
    && documents.link !== '' 
    && documents.name !== '') {
      return false;
    }
    return true;
  }

  renderAccountantToolBar() {
    const {classes, isAdmin, isAccountant, folderLink} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Paper className={classes.FirstFormPaper} >
          <Toolbar>
            <Button variant="contained" className={classes.backddButton} onClick={this.handleGoBack}>
              Clientes
            </Button>
            <div style={{width: 10, marginLeft: 5, marginRight: 5}} >
              <Typography variant="subtitle1" color="secondary" >
                /
              </Typography>
            </div>
            <Typography variant="h5" className={classes.titleAppBar}>
              Documentos
            </Typography>
            <div>
              <Link className={classes.linkPage} target="_blank" href={folderLink}>{folderLink && 'Ir a carpeta compartida'}</Link> 
            </div>
          </Toolbar>
        </Paper>
      );
    }
    return '';
  }

  renderSnackBar() {
    const {error, message, classes} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          open
          message={message}
          className={classes.snackbar}
        />
      );
    }
    return '';
  }

  render() {
    const {localCustomer} = this.props;
    const {dataLength, classes, isLoading, documents} = this.props;
    const {order, orderBy} = this.state;
    return isLoading === true ? <CircularProgress className={classes.progress} size={50} thickness={7} /> : (
      <div >
        {this.renderAccountantToolBar()}
        {this.renderSnackBar()}
        <div>
          <DocumentTable
            onOpenDialog={this.handleClickOpen}
            onCloseDislog={this.handleClose}
            onFilter={this.onFilter}
            onDelete={this.onDelete}
            downloadDocument={this.ondownloadDocument}
            rows={documents}
            cols={documentsOfCustomer}
            onOrder={this.onOrder}
            customerName={this.props.customerName}
            customerLastName={this.props.customerLastName}
            order={order}
            orderBy={orderBy}
            onSearch={this.onSearch}
            dataLength={dataLength}
            searchText={this.state.searchText}
            onCreateDocument={this.onCreateDocument}
            isAdmin={this.props.isAdmin}
            isAccountant={this.props.isAccountant}
            month={this.state.month}
            onResendEmail={this.onResendEmail}
          />
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <Typography id="form-dialog-title" className={classes.dialogTitle}>Agregar nuevo documento </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <DialogContent>
            <FormControl className={classes.select}>
              <InputLabel htmlFor="entity" className={classes.labelInput}>Institución</InputLabel>
              <Select
                value={this.state.documents.entity}
                onChange={this.onChangeEntity}
                displayEmpty
                className={classes.textInput}
              >
                {
                  this.props.entitys.map((entity, key) => {
                    return (
                      <MenuItem value={entity.name} key={key}>{entity.name}</MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
            <TextField
              value={this.state.documents.name}
              onChange={(e) => { this.onChangeName(e); }}
              fullWidth
              label="Documento"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
            <TextField
              value={this.state.documents.link}
              onChange={(e) => { this.onChangeLink(e); }}
              fullWidth
              label="Link"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
            {/* <FormControl className={classes.select}>
              <InputLabel htmlFor="checklistId" className={classes.labelInput}>Paso (Checklist)</InputLabel>
              <Select
                value={this.state.documents.checklistId}
                onChange={this.onChangeCheckList}
                displayEmpty
                className={classes.textInput}
              >
                {
                  this.props.checklist.map((entity, key) => {
                    return (
                      <MenuItem value={entity.id} key={key}>{entity.name}</MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl> */}
            <FormControl className={classes.select}>
              <DatePicker
                // views={['year', 'month', 'date']}
                fullWidth
                keyboard
                value={this.state.date}
                format="YYYY-MM-DD"
                placeholder="YYYY-MM-DD"
                onChange={(val) => {
                  this.setState({date: val.format('YYYY-MM-DD')});
                }}
                className={classes.input}
                disableOpenOnEnter
                animateYearScrolling={false}
                InputProps={{
                  classes: {
                    input: classes.textInput
                  },
                  disabled: true
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </FormControl>
            <div>
              <Typography id="form-dialog-title" className={classes.labelInputFile}>Archivo </Typography>
              {
                this.state.documents.name.length > 0 ?
                  <Uploader
                    buttonText={this.state.documents.file ? 'Actualizar documento' : 'Subir documento'}
                    photo
                    dirName="documentos"
                    urlUploaded={this.onChangeFile}
                    customerRuc={this.props.customerRuc}
                    fileName={this.state.documents.name}
                  />
                  :
                  ''
              }


            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              CANCELAR
            </Button>
            <Button onClick={this.onCreateDocument} disabled={this.validate()} color="primary">
              AGREGAR
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  getDocumentsCustomer: PropTypes.func.isRequired,
  createDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  getDocument: PropTypes.func.isRequired,
  deleteUploaded: PropTypes.func.isRequired,
  handleGoCustomers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  customerName: PropTypes.string.isRequired,
  userId: PropTypes.string,
  customerLastName: PropTypes.string.isRequired,
  customerRuc: PropTypes.string.isRequired,
  folderLink: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dataLength: PropTypes.number,
  entitys: PropTypes.array.isRequired,
  checklist: PropTypes.array.isRequired,
  error: PropTypes.object,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {documents, checklist} = state;

  return {
    isLoading: documents.sending,
    documents: documents.documents,
    dataLength: documents.dataLength,
    error: documents.error,
    message: documents.message,
    order: documents.order,
    orderBy: documents.orderBy,
    page: documents.page,
    rowsPerPage: documents.rowsPerPage,
    checklist: checklist.checklist
  };
};

const mapDispatchToProps = {
  getDocumentsCustomer,
  deleteUploaded,
  createDocument,
  deleteDocument,
  getDocument,
  getChecklist
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Documents));
