import {combineReducers} from 'redux';
import userReducer from './user';
import customerReducer from './customers';
import accountantReducer from './accountants';
import documentsReducer from './documents';
import uploaderReducer from './uploader';
import checklistReducer from './checklist';
import historyReducer from './historyReport';
import { commentsReducer } from './comments';


export const rootReducer = combineReducers({
  customer: customerReducer,
  user: userReducer,
  accountants: accountantReducer,
  uploader: uploaderReducer,
  documents: documentsReducer,
  checklist: checklistReducer,
  comments: commentsReducer,
  historyReport: historyReducer
});

export default rootReducer;
