export const appBarHeight = 64;


export const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500
  },
  title: {
    color: '#697B8C',
    fontWeight: 500,
    fontSize: 20
  },
  searchButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: '#8CA0B3',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 20,
    padding: 20,
    paddingRight: 150,
    paddingBottom: 2,
    color: '#697B8C'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    width: '95%'
  },
  wrapDivider: {
    justifyContent: 'center'
  },
  select: {
    paddingRight: 25
  },
  selectDate: {
    width: '100%',
    maxWidth: 500,
    // marginRight: theme.spacing(2)
  },
  addButton: {
    background: 'transparent',
    boxShadow: 'none',
    color: '#2797E8 ',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  spacer: {
    flexGrow: 1
  },
  rowText: {
    color: '#8CA0B3',
    fontSize: 14
  },
  actions: {
    display: 'inline-flex',
    justifyContent: 'cemter',
    alignItems: 'center'
  },
  serarchText: {
    color: '#8CA0B3',
    fontSize: 12
  },
  addText: {
    color: '#2797E8',
    fontSize: 12
  }
});
