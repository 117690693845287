import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isNumber} from '../../../utils/utils';
import {styles} from './styles';

export class Income extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomes: {}
    };
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.onChangeOther = this.onChangeOther.bind(this);
    this.onChangeMonthlyIncome = this.onChangeMonthlyIncome.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteRegister = this.onDeleteRegister.bind(this);
    this.handleUpdateIncomes = this.handleUpdateIncomes.bind(this);
  }

  componentWillMount() {
    const incomesArray = [];
    this.props.incomes.work.forEach((element) => {
      const income = {
        company: element.company,
        monthlyIncome: element.monthlyIncome,
        startDate: element.startDate ? element.startDate.substring(0, 10) : null,
        endDate: element.endDate ? element.endDate.substring(0, 10) : null
      };
      incomesArray.push(income);
    });
    this.setState({incomes: {
      work: incomesArray,
      other: this.props.incomes.other
    }});
  }

  onAddClick() {
    const incomesArray = this.state.incomes.work;
    const income = {
      company: '',
      monthlyIncome: '',
      startDate: null,
      endDate: null
    };
    incomesArray.push(income);
    this.setState({incomes: {
      work: incomesArray,
      other: this.state.incomes.other
    }});
  }

  onDeleteRegister(key, e) {
    const incomesArray = this.state.incomes.work;
    incomesArray.splice(key, 1);
    this.setState({incomes: {
      work: incomesArray,
      other: this.state.incomes.other
    }});
    this.handleUpdateIncomes();
  }

  onChangeCompany(key, e) {
    const incomesArray = this.state.incomes.work;
    incomesArray[key].company = e.target.value;
    this.setState({incomes: {
      work: incomesArray,
      other: this.state.incomes.other
    }});
    this.handleUpdateIncomes();
  }

  onChangeOther(e) {
    if (isNumber(e.target.value)) {
      const newIncomes = this.state.incomes;
      newIncomes.other = e.target.value;
      this.setState({incomes: newIncomes});
      this.handleUpdateIncomes();
    }
    if (e.target.value[e.target.value.length - 1] === '.') {
      const newIncomes = this.state.incomes;
      newIncomes.other = e.target.value;
      this.setState({incomes: newIncomes});
      this.handleUpdateIncomes();
    }
  }

  onChangeMonthlyIncome(key, e) {
    if (isNumber(e.target.value)) {
      const incomesArray = this.state.incomes.work;
      incomesArray[key].monthlyIncome = e.target.value;
      this.setState({incomes: {
        work: incomesArray,
        other: this.state.incomes.other
      }});
      this.handleUpdateIncomes();
    }
    if (e.target.value[e.target.value.length - 1] === '.') {
      const incomesArray = this.state.incomes.work;
      incomesArray[key].monthlyIncome = e.target.value;
      this.setState({incomes: {
        work: incomesArray,
        other: this.state.incomes.other
      }});
      this.handleUpdateIncomes();
    }
  }

  onChangeStartDate(key, date) {
    const incomesArray = this.state.incomes.work;
    const birthdate = moment(date).format(dateFormat);
    incomesArray[key].startDate = birthdate;
    this.setState({incomes: {
      work: incomesArray,
      other: this.state.incomes.other
    }});
    this.handleUpdateIncomes();
  }

  onChangeEndDate(key, date) {
    const incomesArray = this.state.incomes.work;
    const birthdate = moment(date).format(dateFormat);
    incomesArray[key].endDate = birthdate;
    this.setState({incomes: {
      work: incomesArray,
      other: this.state.incomes.other
    }});
    this.handleUpdateIncomes();
  }

  handleUpdateIncomes() {
    const {incomes} = this.state;
    this.props.updateIncomes(incomes);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    return (
      <div>
        <Paper className={classes.FormPaper} >
          <Toolbar className={classes.toolbarWrapper}>
            <Typography className={classes.title}>
                Ingresos bajo relación de dependencia
            </Typography>
            <div className={classes.spacer} />
            <Button variant="contained" className={classes.addButton} onClick={this.onAddClick} >
              <AddIcon style={{cursor: 'pointer', color: '#697B8C'}} />
                Agregar registro
            </Button>
          </Toolbar>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            {
              this.state.incomes.work.map((income, key) => {
                return (
                  <Grid container spacing={3} className={classes.gridWrapper} key={key} >
                    <Grid item xs={11} className={classes.inputWrapper}>
                      <Grid container spacing={3} className={classes.gridContainer} key={key} >
                        <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                          <TextField
                            value={income.company}
                            autoFocus
                            onChange={(e) => { this.onChangeCompany(key, e); }}
                            fullWidth
                            label="Empresa"
                            className={classes.input}
                            InputProps={{
                              classes: {
                                input: classes.textInput}}}
                            InputLabelProps={{
                              FormLabelClasses: {
                                root: classes.labelInput
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                          <TextField
                            value={income.monthlyIncome}
                            onChange={(e) => { this.onChangeMonthlyIncome(key, e); }}
                            fullWidth
                            label="Ingreso mensual"
                            className={classes.input}
                            InputProps={{
                              classes: {
                                input: classes.textInput
                              },
                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            InputLabelProps={{
                              FormLabelClasses: {
                                root: classes.labelInput
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                          <InlineDatePicker
                            value={income.startDate}
                            fullWidth
                            clearable
                            onChange={(e) => { this.onChangeStartDate(key, e); }}
                            label="Fecha de ingreso"
                            format={dateFormat}
                            className={classes.input}
                            InputProps={{
                              classes: {
                                input: classes.textInput}}}
                            InputLabelProps={{
                              FormLabelClasses: {
                                root: classes.labelInput
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                          <InlineDatePicker
                            value={income.endDate}
                            fullWidth
                            clearable
                            onChange={(e) => { this.onChangeEndDate(key, e); }}
                            label="Fecha de Salida"
                            format={dateFormat}
                            className={classes.input}
                            InputProps={{
                              classes: {
                                input: classes.textInput}}}
                            InputLabelProps={{
                              FormLabelClasses: {
                                root: classes.labelInput
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} className={classes.inputWrapper}>
                      <div className={classes.deleteContainer}>
                        <Button variant="contained" className={classes.addButton} onClick={(e) => { this.onDeleteRegister(key, e); }} >
                          <DeleteIcon style={{cursor: 'pointer', color: '#697B8C'}} />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                );
              })
            }
          </form>
        </Paper>
        <Paper className={classes.FormPaper} >
          <Toolbar className={classes.toolbarWrapper}>
            <Typography className={classes.title}>
                Otros ingresos
            </Typography>
          </Toolbar>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                <TextField
                  value={this.state.incomes.other}
                  autoFocus
                  onChange={(e) => { this.onChangeOther(e); }}
                  fullWidth
                  label="Cantidad"
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    },
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

Income.propTypes = {
  classes: PropTypes.object.isRequired,
  incomes: PropTypes.object.isRequired,
  updateIncomes: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(Income));

