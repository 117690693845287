import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import logo from '../../assets/proserint_logo.png';
import CustomSnackbar from '../common/customSnackbar';
import {styles} from './styles';
import {login} from '../../actions/security';
import {isEmail} from '../../utils/utils';
import {Link} from '@material-ui/core';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      ruc: ''
    };
    this.onLoginClick = this.onLoginClick.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRucChange = this.onRucChange.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.validate = this.validate.bind(this);
  }

  onLoginClick() {
    const {password, ruc} = this.state;
    this.props.login(ruc, password);
  }

  onUsernameChange(e) {
    this.setState({username: e.target.value});
  }

  onRucChange(e) {
    this.setState({ruc: e.target.value});
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
  }

  validate() {
    // if (this.state.username !== '' && this.state.password !== '' && isEmail(this.state.username)) {
    if (this.state.ruc !== '' && this.state.password !== '') {
      return false;
    }
    return true;
  }

  renderButton() {
    const {classes, isLoading} = this.props;
    if (!isLoading) {
      return (
        <div style={{display: 'inline-grid'}}>
          <Button
            type="submit"
            variant="contained"
            disabled={this.validate()}
            color="primary"
            onClick={this.onLoginClick}
            className={classes.button}
          >
            ACCEDER
          </Button>
          <Link color="primary" to="forgot-password" href="forgot-password" style={{fontSize: 14}} >
          ¿Olvidaste tu contraseña?
          </Link>
        </div>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderSnackBar() {
    const {error, message, classes} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          open
          message={message}
          className={classes.snackbar}
        />

      );
    }
    return '';
  }


  render() {
    const {classes} = this.props;
    return (
      <div className={classes.main}>
        {this.renderSnackBar()}
        <Paper className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography gutterBottom style={{color: '#697B8C', fontSize: 22}}>
            ¡Bienvenido!
          </Typography>
          <Typography style={{color: '#8CA0B3', fontSize: 14}}>
            Ingresa tus datos de acceso
          </Typography>
          <form className={classes.form}>
            {/* <TextField
              required
              value={this.state.username}
              error={this.state.username !== '' && !isEmail(this.state.username)}
              autoFocus
              onChange={this.onUsernameChange}
              fullWidth
              label="Usuario"
              className={classes.input}
            /> */}
            <TextField
              required
              value={this.state.ruc}
              error={this.state.ruc !== ''}
              autoFocus
              onChange={this.onRucChange}
              fullWidth
              label="Ruc"
              className={classes.input}
            />
            <TextField
              required
              value={this.state.password}
              onChange={this.onPasswordChange}
              fullWidth
              type="password"
              label="Contraseña"
              className={classes.input}
            />

            {this.renderButton()}


          </form>
        </Paper>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  message: PropTypes.string,
  error: PropTypes.object
};

const mapStateToProps = (state) => {
  const {user} = state;
  return {isLoading: user.sending, error: user.error, message: user.message, userId: user.id};
};

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));

