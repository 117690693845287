/* eslint-disable no-unneeded-ternary */
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { InlineDatePicker, DatePicker } from "material-ui-pickers";
import CustomSnackbar from "../common/customSnackbar";
import { styles } from "./styles";
import axios from "axios";
import { axiosMaker, errorMessage, axiosMakerFilters } from "../../utils/utils";
import Highcharts from "highcharts/highmaps";
import {
  colsChecklistCustomer,
  adminColsChecklistCustomer
} from "../../utils/colTypes";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import HeadTable from "./headTable";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

export class ChecklistCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      date: moment(),
      statusRequest: "loading",
      rows: [],
      idItemEdit: -1,
      order: 'desc'
    };
    this.renderSnack = this.renderSnack.bind(this);
    this.renderAccountantToolBar = this.renderAccountantToolBar.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.getChecklistOrder = this.getChecklistOrder.bind(this);
  }

  async componentWillMount() {}

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.getChecklist(this.state.date);
  }

  async getChecklist(date) {
    try {
      this.setState({ statusRequest: "loading" });
      const { customer } = this.props;
      const dateAux = date.format("YYYY-MM-DD");
      const response = await axios(
        axiosMaker(
          "POST",
          `checklistCustomer`,
          {
            customerId: customer.id,
            date: dateAux
          },
          true
        )
      );
      const data = response.data;
      const rows = data.checkList.map(item => {
        item.observationAux = "";
        return item;
      });
      this.setState({ statusRequest: "success", rows });
      console.log(data);
    } catch (e) {
      this.setState({ statusRequest: "error" });
    }
  }
  async getChecklistOrder(orderBy, order) {
    try {
      console.log('orderBy, order 💦' ,orderBy, order);
      this.setState({ statusRequest: "loading" });
      const { customer } = this.props;
      const dateAux = this.state.date.format("YYYY-MM-DD");
      const response = await axios(
        axiosMaker(
          "POST",
          `checklistCustomer`,
          {
            customerId: customer.id,
            date: dateAux,
            sortBy: orderBy,
            order: order,
          },
          true
        )
      );
      const data = response.data;
      const rows = data.checkList.map(item => {
        item.observationAux = "";
        return item;
      });
      this.setState({ statusRequest: "success", rows, order: order });
      console.log(data);
    } catch (e) {
      this.setState({ statusRequest: "error" });
    }
  }
  async saveChangeChecklistItem(row) {
    try {
      this.setState({ statusRequest: "loading" });
      let { observations, status } = row;
      let observation = {
        dateCreated: moment().format("YYYY-MM-DD H:m:s"),
        comment: row.observationAux,
        userId: "todo" //TODO agregar id del usuario
      };
      observations.unshift(observation);

      let data = { observations, status };
      const response = await axios(
        axiosMaker("PATCH", `checklistCustomer/${row._id}`, data, true)
      );
      const result = response.data;
      this.setState({ statusRequest: "success", idItemEdit: -1 });
    } catch (e) {
      this.setState({ statusRequest: "error" });
    }
  }

  renderSnack() {
    const { message, error } = this.props;
    if (message) {
      return (
        <CustomSnackbar
          open
          onClose={() => {}}
          message={message}
          variant={error === null ? "success" : "error"}
        />
      );
    }
    return "";
  }
  handleGoBack() {
    if (!this.state.changed) {
      this.props.handleGoBack();
    } else {
      this.setState({ open: true });
    }
  }

  renderAccountantToolBar() {
    const {classes, isAdmin, isAccountant} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Paper className={classes.FirstFormPaper} >
          <Toolbar>
            <Button variant="contained" className={classes.backddButton} onClick={this.handleGoBack}>
              Clientes
            </Button>
            <div style={{width: 10, marginLeft: 5, marginRight: 5}} >
              <Typography variant="subtitle1" color="secondary" >
                /
              </Typography>
            </div>
            <Typography variant="h5" className={classes.titleAppBar}>
              Checklist
            </Typography>
          </Toolbar>
        </Paper>
      );
    }
    return '';
  }

  render() {
    const { classes, customer, isAdmin, isAccountant } = this.props;
    const { statusRequest, rows } = this.state;
    return statusRequest === "loading" ? (
      <CircularProgress className={classes.progress} size={50} thickness={7} />
    ) : (
      <div>
        {this.renderAccountantToolBar()}
        <div>
          <Paper className={classes.FormPaper}>
            <Grid container className={classes.contentDatePicker}>
              <Typography className={classes.title}>
                {isAdmin ? customer.name : "Checklist"}
              </Typography>
              <div>
                <DatePicker
                  views={["year", "month"]}
                  fullWidth
                  keyboard
                  value={this.state.date}
                  format={"YYYY-MM-DD"}
                  onChange={val => {
                    this.setState({ date: val });
                    this.getChecklist(val);
                  }}
                  className={classes.input}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    },
                    disabled: true
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </div>
            </Grid>
            <br />
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <HeadTable
                  cols={
                    isAdmin || isAccountant
                      ? adminColsChecklistCustomer
                      : colsChecklistCustomer
                  }
                  onOrder={this.getChecklistOrder}
                  order={this.state.order}
                />

                <TableBody>
                  {rows.map(row => (
                    <TableRow key={row._id}>
                      <TableCell className={classes.rowText}>
                        {row.checklistId.name}
                      </TableCell>
                      <TableCell className={classes.rowText}>
                        <FormControl className={classes.formControl}>
                          {row._id === this.state.idItemEdit && (
                            <Select
                              value={row.status}
                              onChange={e => {
                                const value = e.target.value;
                                let rows = this.state.rows;
                                let index = rows.findIndex(
                                  item => item._id === row._id
                                );
                                rows[index].status = value;
                                this.setState({ rows });
                              }}
                              displayEmpty
                              className={classes.selectEmpty}
                            >
                              <MenuItem value={"Pendiente"}>Pendiente</MenuItem>
                              <MenuItem value={"En proceso"}>
                                En proceso
                              </MenuItem>
                              <MenuItem value={"Listo"}>Listo</MenuItem>
                            </Select>
                          )}
                          {row._id !== this.state.idItemEdit && row.status}
                        </FormControl>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.rowText}
                      >
                        {row._id !== this.state.idItemEdit &&
                          (row.observations.length > 0
                            ? row.observations[0].comment
                            : "")}
                        {row._id === this.state.idItemEdit && (
                          <TextField
                            value={row.observationAux}
                            fullWidth
                            onChange={e => {
                              const value = e.target.value;
                              let rows = this.state.rows;
                              let index = rows.findIndex(
                                item => item._id === row._id
                              );
                              rows[index].observationAux = value;
                              this.setState({ rows });
                            }}
                          />
                        )}
                      </TableCell>

                      {isAdmin || isAccountant ? (
                        <TableCell align="left">
                          <div className={classes.iconContainer}>
                            <Tooltip title="">
                              <div>
                                {row._id !== this.state.idItemEdit && (
                                  <EditIcon
                                    style={{ cursor: "pointer", color: "grey" }}
                                    onClick={() => {
                                      this.setState({
                                        idItemEdit: row._id
                                      });
                                    }}
                                  />
                                )}
                                {row._id === this.state.idItemEdit && (
                                  <CheckIcon
                                    style={{ cursor: "pointer", color: "grey" }}
                                    onClick={() => {
                                      this.saveChangeChecklistItem(row);
                                    }}
                                  />
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  ))}
                  {rows.length > 0 && (
                    <TableRow style={{ height: 48 * rows.length }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

ChecklistCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  customer: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  onOrder: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { customer } = state;
  return {
    message: customer.message,
    error: customer.error
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChecklistCustomer));
