import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {styles} from './styles';
import {isInt} from '../../../utils/utils';

export class BankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankAccount: {}
    };
    this.onChangeType = this.onChangeType.bind(this);
    this.onChangeBank = this.onChangeBank.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.handleUpdateBankAccount = this.handleUpdateBankAccount.bind(this);
  }

  componentWillMount() {
    this.setState({bankAccount: this.props.bankAccount});
  }

  onChangeType(e) {
    const newBankAccount = this.state.bankAccount;
    newBankAccount.type = e.target.value;
    this.setState({bankAccount: newBankAccount});
    this.handleUpdateBankAccount();
  }

  onChangeBank(e) {
    const newBankAccount = this.state.bankAccount;
    newBankAccount.bank = e.target.value;
    this.setState({bankAccount: newBankAccount});
    this.handleUpdateBankAccount();
  }

  onChangeNumber(e) {
    if (isInt(e.target.value)) {
      const newBankAccount = this.state.bankAccount;
      newBankAccount.number = e.target.value;
      this.setState({bankAccount: newBankAccount});
      this.handleUpdateBankAccount();
    }
  }

  handleUpdateBankAccount() {
    const {bankAccount} = this.state;
    this.props.updateBankAccount(bankAccount);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Autorización de débito automático del SRI
        </Typography>

        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>

        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <FormControl className={classes.select}>
                <InputLabel htmlFor="type" className={classes.labelInput}>Tipo de cuenta</InputLabel>
                <Select
                  value={this.state.bankAccount.type}
                  onChange={e => this.onChangeType(e)}
                  displayEmpty
                  className={classes.textInput}
                >
                  <MenuItem value="Crédito">Crédito</MenuItem>
                  <MenuItem value="Débito">Débito</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <FormControl className={classes.select}>
                <InputLabel htmlFor="entity" className={classes.labelInput}>Institución financiera</InputLabel>
                <Select
                  value={this.state.bankAccount.bank}
                  onChange={e => this.onChangeBank(e)}
                  displayEmpty
                  className={classes.textInput}
                >
                  {
                    this.props.entitys.map((entity, key) => {
                      return (
                        <MenuItem value={entity.name} key={key}>{entity.name}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={this.state.bankAccount.number}
                onChange={e => this.onChangeNumber(e)}
                fullWidth
                label="Número de cuenta"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>

    );
  }
}

BankAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  bankAccount: PropTypes.object.isRequired,
  updateBankAccount: PropTypes.func.isRequired,
  entitys: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(BankAccount));
