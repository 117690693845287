import * as documentsActions from '../actions/types/documents';

const initialState = {
  documents: [],
  message: '',
  dataLength: 0,
  sending: true,
  error: null,
  page: 0,
  rowsPerPage: 10,
  order: 'desc',
  orderBy: 'date',
  searchText: '',
  resending: false, 
  resendingError: false,
  messageResending: ''
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {documents, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case documentsActions.GET_DOCUMENTS_SENDING:
    return {...state, sending: true, dataLength, page, rowsPerPage, order, orderBy, searchText};
  case documentsActions.GET_DOCUMENTS_SUCCESS:
    return {...state, sending: false, documents, error: null, dataLength};
  case documentsActions.GET_DOCUMENTS_FAILED:
    return {...state, sending: false, documents: [], error, message};
  case documentsActions.CREATE_DOCUMENT_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case documentsActions.CREATE_DOCUMENT_SUCCESS:
    return {...state, sending: false, response: true, message: 'Documento creado satisfactoriamente ', error: null};
  case documentsActions.CREATE_DOCUMENT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case documentsActions.DELETE_DOCUMENT_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case documentsActions.DELETE_DOCUMENT_SUCCESS:
    return {...state, sending: false, response: true, message: 'Documento eliminado satisfactoriamente ', error: null};
  case documentsActions.DELETE_DOCUMENT_FAILED:
    return {...state, sending: false, response: true, message, error};
  case documentsActions.RESEND_EMAIL_DOCUMENT_SENDING:
    return {...state, resending: true, response: false, message: '', error: null};
  case documentsActions.RESEND_EMAIL_DOCUMENT_SUCCESS:
    return {...state, resending: false, response: true, messageResending: 'Enviado con éxito', error: null};
  case documentsActions.RESEND_EMAIL_DOCUMENT_FAILED:
    return {...state, resending: false, resendingError: true, messageResending: 'Error al reenviar intentar de nuevo'};
  default:
    return state;
  }
};
