import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {requestResetPassword} from '../../actions/security';
import CustomSnackbar from '../common/customSnackbar';
import logo from '../../assets/proserint_logo.png';
import styles from './styles';
import {isEmail} from '../../utils/utils';


export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      showForm: true
    };
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onForgotPassword = this.onForgotPassword.bind(this);
    this.validate = this.validate.bind(this);
    this.renderSnack = this.renderSnack.bind(this);
    this.renderButton = this.renderButton.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  onChangeEmail(e) {
    this.setState({email: e.target.value});
  }

  onForgotPassword() {
    const {email} = this.state;
    this.props.requestResetPassword(email);
    this.setState({showForm: false});
  }

  validate() {
    if (this.state.email !== '' && isEmail(this.state.email)) {
      return false;
    }
    return true;
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <div>
          <CustomSnackbar
            open
            onClose={() => {}}
            message={message}
            variant={error === null ? 'success' : 'error'}
          />
        </div>
      );
    }
    return '';
  }

  renderButton() {
    const {classes, isLoading} = this.props;
    if (!isLoading) {
      return (
        <Button
          type="submit"
          variant="contained"
          disabled={this.validate()}
          onClick={this.onForgotPassword}
          className={classes.button}
        >
          Enviar
        </Button>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderContent() {
    const {classes} = this.props;
    const {email} = this.state;
    if (this.state.showForm) {
      return (
        <div className={classes.main}>
          <Paper className={classes.paper}>
            <img src={logo} alt="logo" className={classes.logo} />

            <Typography style={{color: '#8CA0B3', fontSize: 14, textAlign: 'center'}}>
              Ingrese tu correo para que puedas cambiar tu contraseña.
            </Typography>
            <form className={classes.form}>
              <TextField
                required
                value={email}
                error={email !== '' && !isEmail(email)}
                autoFocus
                onChange={this.onChangeEmail}
                fullWidth
                label="Email"
                className={classes.input}
              />
              {this.renderButton()}
              {this.renderSnack()}
            </form>
          </Paper>
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography gutterBottom style={{color: '#697B8C', fontSize: 22}}>
            Actualizar contraseña
          </Typography>
          <Typography style={{color: '#8CA0B3', fontSize: 14, textAlign: 'center'}}>
            Revisa a tu correo para que puedas completar el proceso de actualizacion de contraseña.
          </Typography>
        </Paper>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  message: PropTypes.string.isRequired,
  requestResetPassword: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {user} = state;
  return {
    isLoading: user.sending,
    error: user.error,
    message: user.message};
};

const mapDispatchToProps = {
  requestResetPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
