import axios from 'axios';
import * as securityActions from './types/security';
import {axiosMaker, errorMessage, axiosMakerChangePassword} from '../utils/utils';

export const login = (ruc, password) => async (dispatch) => {
  dispatch({
    type: securityActions.LOGIN_SENDING,
    payload: {
      ruc,
      password
    }
  });
  try {
    const data = {
      ruc,
      password
    };
    const response = await axios(axiosMaker('POST', 'login', data, false));
    return dispatch({
      type: securityActions.LOGIN_SUCCESS,
      payload: {
        user: response.data.user,
        token: response.data.token
      }

    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.LOGIN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const loginAccountant = (username, password) => async (dispatch) => {
  dispatch({
    type: securityActions.LOGIN_SENDING,
    payload: {
      username,
      password
    }
  });
  try {
    const data = {
      email: username,
      password
    };
    const response = await axios(axiosMaker('POST', 'loginAccountant', data, false));
    return dispatch({
      type: securityActions.LOGIN_SUCCESS,
      payload: {
        user: response.data.user,
        token: response.data.token
      }

    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.LOGIN_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const requestResetPassword = username => async (dispatch) => {
  dispatch({
    type: securityActions.REQUEST_CHANGE_PASSWORD_SENDING,
    payload: {
      username
    }
  });
  try {
    const data = {
      email: username
    };
    await axios(axiosMaker('POST', 'request-reset-password', data, false));
    return dispatch({
      type: securityActions.REQUEST_CHANGE_PASSWORD_SUCCESS,
      payload: {}

    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.REQUEST_CHANGE_PASSWORD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const changePassword = (password, confirm, token) => async (dispatch) => {
  dispatch({
    type: securityActions.CHANGE_PASSWORD_SENDING,
    payload: {}
  });
  try {
    const data = {
      password,
      confirm
    };
    const query = {
      token
    };
    await axios(axiosMakerChangePassword('POST', 'reset-password', data, query));
    return dispatch({
      type: securityActions.CHANGE_PASSWORD_SUCCESS,
      payload: {
        message: 'Usted ha cambiado su contraseña exitosamente'
      }

    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.CHANGE_PASSWORD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const activePassword = (password, confirm, token) => async (dispatch) => {
  dispatch({
    type: securityActions.ACTIVE_PASSWORD_SENDING,
    payload: {}
  });
  try {
    const data = {
      password,
      confirm
    };
    const query = {
      token
    };
    const response = await axios(axiosMakerChangePassword('POST', 'active-password', data, query));
    return dispatch({
      type: securityActions.ACTIVE_PASSWORD_SUCCESS,
      payload: {
        message: 'Usted ha cambiado su contraseña exitosamente',
        user: response.data.user

      }

    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: securityActions.ACTIVE_PASSWORD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
