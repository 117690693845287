/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {InlineDatePicker, DatePicker} from 'material-ui-pickers';
import CustomSnackbar from '../common/customSnackbar';
import {styles} from './styles';
import axios from 'axios';
import {
  axiosMaker,
  errorMessage,
  axiosMakerFilters,
  isNumber
} from '../../utils/utils';
import Highcharts from 'highcharts/highmaps';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReportComment from './ReportComment';
import MuiAlert from '@material-ui/lab/Alert';
import {Snackbar} from '@material-ui/core';
import DashboardEmailsHistory from '../dashboardEmailsHistory';

function formatValues(itemNumber) {
  const noTruncarDecimales = {maximumFractionDigits: 2, minimumFractionDigits: 2};
  return itemNumber.toLocaleString('en-US', noTruncarDecimales);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      date: moment(),
      statusRequest: 'loading',
      statusPdfReport: false,
      showResponseMessage: false,
      responseMessage: '',
      typeResponseMessage: 'success',
      reportInfo: null,
      year: moment(),
      month: moment(),
      egpIngress: 0,
      egpEgress: 0,
      egpUtility: 0,
      balanceActive: 0,
      balancePassive: 0,
      balancePatrimony: 0,
      accountPerPay: 0,
      accountPerCollect: 0,
      reportSc: undefined
    };
    this.renderSnack = this.renderSnack.bind(this);
    this.renderAccountantToolBar = this.renderAccountantToolBar.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentWillMount() { }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.renderReport(this.state.date);
  }

  async findReport(year, month) {
    try {
      const {customer} = this.props;
      if (year && month) {
        year = year.format('YYYY');
        month = month.format('M');
        const response = await axios(axiosMakerFilters(
          'GET',
          `report/find?ruc=${customer.ruc}&year=${year}&month=${month}`
        ));
        const {result} = response.data;
        if (result) {
          this.setState({
            egpIngress: result.egpIngress,
            egpEgress: result.egpEgress,
            egpUtility: result.egpUtility,
            balanceActive: result.balanceActive,
            balancePassive: result.balancePassive,
            balancePatrimony: result.balancePatrimony,
            accountPerPay: result.accountPerPay,
            accountPerCollect: result.accountPerCollect
          });
          return;
        }
      }
      this.setState({
        egpIngress: 0,
        egpEgress: 0,
        egpUtility: 0,
        balanceActive: 0,
        balancePassive: 0,
        balancePatrimony: 0,
        accountPerPay: 0,
        accountPerCollect: 0
      });
    } catch (e) {
      this.setState({
        egpIngress: 0,
        egpEgress: 0,
        egpUtility: 0,
        balanceActive: 0,
        balancePassive: 0,
        balancePatrimony: 0,
        accountPerPay: 0,
        accountPerCollect: 0
      });
    }
  }

  async renderReport(date) {
    try {
      this.setState({statusRequest: 'loading'});
      const {customer} = this.props;
      const dateAux = date.format('YYYY-MM-DD');
      const response = await axios(axiosMakerFilters(
        'GET',
        `report/dashboard?ruc=${customer.ruc}&date=${dateAux}`
      ));
      const data = response.data;
      const {reportEpg, reportResumeEpg, reportBalance, reportInfo, reportSc} = data;
      this.setState({statusRequest: 'success', reportInfo, reportSc});
      reportEpg.plotOptions = {
        series: {
          pointPadding: 0
        }
      };

      reportBalance.plotOptions.series = {
        pointPadding: 0
      };

      reportResumeEpg.plotOptions.series = {
        pointPadding: 0
      };
      Highcharts.chart('wrapper_report_column', reportEpg);
      Highcharts.chart('wrapper_report_resume', reportResumeEpg);
      Highcharts.chart('wrapper_report_balance', reportBalance);
    } catch (e) {
      this.setState({statusRequest: 'error'});
    }
  }

  async openDialog() {
    this.setState({
      year: moment(),
      month: moment()
    });
    await this.findReport(this.state.year, this.state.month);
    this.setState({
      open: true
    });
  }

  async sendPdfReport(date) {
    try {
      this.setState({statusPdfReport: true});
      const {customer} = this.props;
      const dateAux = date.format('YYYY-MM-DD');
      await axios(axiosMakerFilters(
        'GET',
        `report/dashboard-pdf?ruc=${customer.ruc}&date=${dateAux}`
      ));
      this.setState({ 
        statusPdfReport: false, 
        showResponseMessage: true, 
        responseMessage: 'Reporte enviado con éxito.',
        typeResponseMessage: 'success'
      });
    } catch (e) {
      this.setState({ 
        statusPdfReport: false, 
        showResponseMessage: true, 
        responseMessage: 'Tuvimos un error enviando el reporte, intenta nuevamente.',
        typeResponseMessage: 'error'
      });
    }
  }

  closeDialog() {
    this.setState({open: false});
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          open
          onClose={() => { }}
          message={message}
          variant={error === null ? 'success' : 'error'}
        />
      );
    }
    return '';
  }

  handleGoBack() {
    this.props.handleGoBack();
  }

  renderAccountantToolBar() {
    const {classes, isAdmin, isAccountant} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Paper className={classes.FirstFormPaper}>
          <Toolbar>
            <Button
              variant="contained"
              className={classes.backddButton}
              onClick={this.handleGoBack}
            >
              Clientes
            </Button>
            <div style={{width: 10, marginLeft: 5, marginRight: 5}}>
              <Typography variant="subtitle1" color="secondary">
                /
              </Typography>
            </div>
            <Typography variant="h5" className={classes.titleAppBar}>
              Dashboard
            </Typography>
          </Toolbar>
        </Paper>
      );
    }
    return '';
  }

  onValueChange(key, e) {
    if (
      isNumber(e.target.value) ||
      e.target.value[e.target.value.length - 1] === '.'
    ) {
      this.setState({[key]: e.target.value});
    }
  }

  async saveDataReport() {
    try {
      const {customer} = this.props;
      let {
        year,
        month,
        egpIngress,
        egpEgress,
        egpUtility,
        balanceActive,
        balancePassive,
        balancePatrimony,
        accountPerPay,
        accountPerCollect
      } = this.state;
      year = year.format('YYYY');
      month = month.format('M');
      const dataReport = {
        ruc: customer.ruc,
        year,
        month,
        egpIngress,
        egpEgress,
        egpUtility,
        balanceActive,
        balancePassive,
        balancePatrimony,
        accountPerPay,
        accountPerCollect
      };
      const response = await axios(axiosMaker('POST', 'report', dataReport));
      const data = response.data;
      this.setState({
        open: false,
        egpIngress: 0,
        egpEgress: 0,
        egpUtility: 0,
        balanceActive: 0,
        balancePassive: 0,
        balancePatrimony: 0,
        accountPerPay: 0,
        accountPerCollect: 0,
        year: moment(),
        month: moment()
      });
      await this.renderReport(this.state.date);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {classes, customer, isAdmin, isAccountant} = this.props;
    const {reportInfo, statusRequest, reportSc} = this.state;
    return statusRequest === 'loading' ? (
      <CircularProgress className={classes.progress} size={50} thickness={7} />
    ) : (
      <div>
        {this.renderAccountantToolBar()}
        <div>
          <Paper className={classes.FormPaper}>
            <Grid container className={classes.contentDatePicker}>
              <Typography className={classes.titleName}>
                {isAdmin || isAccountant ? customer.name : 'Dashboard'}
              </Typography>
              <div className={classes.actions}>
                <DatePicker
                  views={['year', 'month']}
                  keyboard
                  value={this.state.date}
                  format="YYYY-MM"
                  placeholder="YYYY-MM"
                  onChange={(val) => {
                    this.setState({date: val});
                    this.renderReport(val);
                  }}
                  className={classes.input}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    },
                    disabled: true
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
                {(isAdmin || isAccountant) &&
                  customer.originReport == 'Formulario' && (
                  <Button
                    variant="contained"
                    className={classes.addButton}
                    style={{
                      minWidth: '155px',
                      marginTop: '15px'
                    }}
                    onClick={() => {
                      this.openDialog();
                    }}
                  >
                    <AddIcon
                      style={{
                        cursor: 'pointer',
                        color: '#2797E8',
                        marginRight: 10
                      }}
                    />
                      Cargar datos
                  </Button>
                )}
              </div>
              {(isAdmin || isAccountant) && <div className={`${classes.wrapperShopping}`}>
                <Button className={classes.actionButton} onClick={() => { this.sendPdfReport(this.state.date); }} color="primary" variant="contained" disabled={this.state.statusPdfReport} >
                  Enviar  reporte
                </Button>
                {customer && 
                (<div className={classes.actionButton} >
                  <DashboardEmailsHistory customerId={this.props.customer.id} date={this.state.date} />
                </div>
                )}
                {this.state.statusPdfReport && <CircularProgress size={24} className={`${classes.buttonProgress} `} />}
              </div>}
              {this.state.showResponseMessage && <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={this.state.showResponseMessage} autoHideDuration={6000} onClose={() => { this.setState({showResponseMessage: false}); }} >
                <Alert severity={this.state.typeResponseMessage} onClose={() => { this.setState({showResponseMessage: false}); }}>
                  {this.state.responseMessage}
                </Alert>
              </Snackbar>}
            </Grid>

            <div className={classes.wrapDivider} align="center">
              <Divider className={classes.divider} />
              <br />
              {reportInfo && (
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>CxP a la fecha</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(reportInfo.perPay)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>CxC a la fecha</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(reportInfo.perCollect)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>
                        Ingresos totales
                      </div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(reportInfo.ingressYear)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>
                        Ingresos del mes
                      </div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(reportInfo.ingressMonth)}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Paper>

          <Paper className={classes.FormPaper}>
            <div className={classes.wrapDivider} align="center">
              <div id="wrapper_report_column" />
            </div>
          </Paper>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.FormPaper}>
                <div className={classes.wrapDivider} align="center">
                  <div id="wrapper_report_resume" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.FormPaper}>
                <div className={classes.wrapDivider} align="center">
                  <div id="wrapper_report_balance" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.FormPaper}>
                <div className={classes.wrapDivider} align="center">
                  <div className={classes.wrapDivider} align="center">
                    <Typography id="title indicadores" className={classes.titleName}>
                      Saldos Contables
                    </Typography>
                    <Divider className={classes.divider} />
                    <br />
                    {reportSc && (
                      <Grid container>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>Activo Total</div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.activoTotal)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>Activo Corriente</div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.activoCorriente)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Pasivo Total
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.pasivoTotal)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Pasivo Corriente
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.pasivoCorriente)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Inventarios
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.inventarios)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Ventas
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.ventas)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Costo de ventas
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.costoVentas)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Gasto
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.gasto)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Utilidad Neta
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.utilidad)}
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <div className={classes.containerReport}>
                            <div className={classes.titleReport}>
                              Patrimonio
                            </div>
                            <div className={classes.subtitleReport}>
                              ${formatValues(reportSc.patrimonioTotal)}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.FormPaper}>
                <div className={classes.wrapDivider} align="center">
                  <div className={classes.wrapDivider} align="center">
                    <Typography id="title indicadores" className={classes.titleName}>
                      Ratios Financieros
                    </Typography>
                    <Divider className={classes.divider} />
                    <br />
                    {reportSc && (
                      <div>
                        <Grid container>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>Capital de Trabajo</div>
                              <div className={classes.subtitleReport}>
                                ${formatValues(reportSc.activoCorriente - reportSc.pasivoCorriente)}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>Solvencia</div>
                              <div className={classes.subtitleReport}>
                                {formatValues(reportSc.activoCorriente / reportSc.pasivoCorriente)}
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>
                                Apalancamiento
                              </div>
                              <div className={classes.subtitleReport}>
                                {formatValues((reportSc.activoTotal / reportSc.patrimonioTotal) * 100)}%
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>
                                Rotación del Activo Total
                              </div>
                              <div className={classes.subtitleReport}>
                                {formatValues(reportSc.ventas / reportSc.activoTotal)}
                              </div>
                            </div>
                          </Grid>
                          <br />
                        </Grid>
                        <br />
                        <Grid container>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>
                                Endeudamiento del Activo
                              </div>
                              <div className={classes.subtitleReport}>
                                {formatValues((reportSc.pasivoTotal / reportSc.activoTotal) * 100)}%
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>
                                Margen Bruto de Utilidad
                              </div>
                              <div className={classes.subtitleReport}>
                                {formatValues(((reportSc.ventas - reportSc.costoVentas) / reportSc.ventas) * 100)}%
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <div className={classes.containerReport}>
                              <div className={classes.titleReport}>
                                Margen Neto de Utilidad
                              </div>
                              <div className={classes.subtitleReport}>
                                {formatValues(reportSc.utilidad / reportSc.ventas)}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <ReportComment customer={this.props.customer} date={this.state.date} />
            </Grid>
          </Grid>


        </div>
        <Dialog
          open={this.state.open}
          onClose={() => {
            this.closeDialog();
          }}
        >
          <Typography id="form-dialog-title" className={classes.dialogTitle}>
            Cargar datos para dashboard
          </Typography>
          <div className={classes.wrapDivider} align="center">
            <Divider className={classes.divider} />
          </div>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div style={{paddingRight: '3px'}}>
                  <DatePicker
                    views={['month']}
                    fullWidth
                    keyboard
                    label="Mes"
                    value={this.state.month}
                    format="MMMM"
                    onChange={(val) => {
                      this.setState({month: val});
                      this.findReport(this.state.year, val);
                    }}
                    className={classes.input}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      disabled: true
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{paddingLeft: '3px'}}>
                  <DatePicker
                    views={['year']}
                    fullWidth
                    keyboard
                    label="Año"
                    value={this.state.year}
                    format="YYYY"
                    openToYearSelection={false}
                    onChange={(val) => {
                      this.setState({year: val});
                      this.findReport(val, this.state.month);
                    }}
                    className={classes.input}
                    disableOpenOnEnter
                    animateYearScrolling={false}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      disabled: true
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={8} sm={4}>
                <div style={{paddingRight: '6px'}}>
                  <TextField
                    value={this.state.egpIngress}
                    onChange={(e) => {
                      this.onValueChange('egpIngress', e);
                      const ingress = parseFloat(e.target.value) || 0;
                      const egress = this.state.egpEgress || 0;
                      this.setState({
                        egpUtility: ingress - egress
                      });
                    }}
                    fullWidth
                    label="EGP ingreso"
                    className={classes.input}
                    error={this.state.egpIngress === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div style={{paddingLeft: '1px', paddingRight: '1px'}}>
                  <TextField
                    value={this.state.egpEgress}
                    onChange={(e) => {
                      this.onValueChange('egpEgress', e);
                      const ingress = this.state.egpIngress || 0;
                      const egress = parseFloat(e.target.value) || 0;
                      this.setState({
                        egpUtility: ingress - egress
                      });
                    }}
                    fullWidth
                    label="EGP egresos"
                    className={classes.input}
                    error={this.state.egpEgress === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div style={{paddingLeft: '6px'}}>
                  <TextField
                    value={this.state.egpUtility}
                    onChange={(e) => {
                      this.onValueChange('egpUtility', e);
                    }}
                    fullWidth
                    label="EGP Utilidad"
                    className={classes.input}
                    error={this.state.egpUtility === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={8} sm={4}>
                <div style={{paddingRight: '6px'}}>
                  <TextField
                    value={this.state.balanceActive}
                    onChange={(e) => {
                      this.onValueChange('balanceActive', e);
                    }}
                    fullWidth
                    label="Activos"
                    className={classes.input}
                    error={this.state.balanceActive === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div style={{paddingLeft: '1px', paddingRight: '1px'}}>
                  <TextField
                    value={this.state.balancePassive}
                    onChange={(e) => {
                      this.onValueChange('balancePassive', e);
                    }}
                    fullWidth
                    label="Pasivos"
                    className={classes.input}
                    error={this.state.balancePassive === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div style={{paddingLeft: '6px'}}>
                  <TextField
                    value={this.state.balancePatrimony}
                    onChange={(e) => {
                      this.onValueChange('balancePatrimony', e);
                    }}
                    fullWidth
                    label="Patrominio"
                    className={classes.input}
                    error={this.state.balancePatrimony === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={8} sm={4}>
                <div style={{paddingRight: '6px'}}>
                  <TextField
                    value={this.state.accountPerPay}
                    onChange={(e) => {
                      this.onValueChange('accountPerPay', e);
                    }}
                    fullWidth
                    label="Cuentas por pagar"
                    className={classes.input}
                    error={this.state.accountPerPay === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={8} sm={4}>
                <div style={{paddingLeft: '1px', paddingRight: '1px'}}>
                  <TextField
                    value={this.state.accountPerCollect}
                    onChange={(e) => {
                      this.onValueChange('accountPerCollect', e);
                    }}
                    fullWidth
                    label="Cuentas por cobrar"
                    className={classes.input}
                    error={this.state.accountPerCollect === ''}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      },
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.closeDialog();
                this.setState({
                  egpIngress: 0,
                  egpEgress: 0,
                  egpUtility: 0,
                  balanceActive: 0,
                  balancePassive: 0,
                  balancePatrimony: 0,
                  accountPerPay: 0,
                  accountPerCollect: 0,
                  year: moment(),
                  month: moment()
                });
              }}
              color="primary"
            >
              CANCELAR
            </Button>
            <Button
              onClick={() => {
                this.saveDataReport();
              }}
              color="primary"
              disabled={false}
            >
              GUARDAR
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  customer: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {customer} = state;
  return {
    message: customer.message,
    error: customer.error
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
