import axios from 'axios';
import * as accountantActions from './types/accountant';
import {axiosMaker, axiosMakerFilters, errorMessage} from '../utils/utils';

export const getCustomerOfAccountant = (id, searchText, page, rowsPerPage, orderBy, order, active = true) => async (dispatch) => {
  dispatch({
    type: accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_SENDING,
    payload: {searchText, page, rowsPerPage, orderBy, order}
  });
  try {
    const data = {
      searchText,
      page,
      limit: rowsPerPage,
      sortBy: orderBy,
      order,
      active
    };
    const response = await axios(axiosMakerFilters('GET', `accountants/${id}/customers`, data, true));
    return dispatch({
      type: accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_SUCCESS,
      payload: {
        customers: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: accountantActions.GET_CUSTOMERS_OF_ACCOUNTANT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getAccountant = id => async (dispatch) => {
  dispatch({
    type: accountantActions.GET_ACCOUNTANT_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', `accountants/${id}`, null, true));
    dispatch({
      type: accountantActions.GET_ACCOUNTANT_SUCCESS,
      payload: {
        accountant: response.data.result
      }
    });
    return response.data.result;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: accountantActions.GET_ACCOUNTANT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getAccountants = (searchText, page, rowsPerPage, orderBy, order, active = true) => async (dispatch) => {
  dispatch({
    type: accountantActions.GET_ACCOUNTANTS_SENDING,
    payload: {searchText, page, rowsPerPage, orderBy, order}
  });
  try {
    const data = {
      searchText,
      page,
      limit: rowsPerPage,
      sortBy: orderBy,
      order,
      active
    };
    const response = await axios(axiosMakerFilters('GET', 'accountants', data, true));
    return dispatch({
      type: accountantActions.GET_ACCOUNTANTS_SUCCESS,
      payload: {
        accountants: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: accountantActions.GET_ACCOUNTANTS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createAccountant = data => async (dispatch) => {
  dispatch({
    type: accountantActions.CREATE_ACCOUNTANT_SENDING,
    payload: {

    }
  });
  try {
    await axios(axiosMaker('POST', 'accountants', data, true));
    return dispatch({
      type: accountantActions.CREATE_ACCOUNTANT_SUCCESS,
      payload: {
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: accountantActions.CREATE_ACCOUNTANT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const patchAccountant = (id, data) => async (dispatch) => {
  dispatch({
    type: accountantActions.PATCH_ACCOUNTANT_SENDING,
    payload: {

    }
  });
  try {
    await axios(axiosMaker('PATCH', `accountants/${id}`, data, true));
    return dispatch({
      type: accountantActions.PATCH_ACCOUNTANT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: accountantActions.PATCH_ACCOUNTANT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
