
export const customerOfAccountantCols = [
  {id: 'entityType', numeric: false, disablePadding: false, label: 'Tipo'},
  {id: 'ruc', numeric: false, disablePadding: false, label: 'RUC'},
  {id: 'rucKey', numeric: false, disablePadding: false, label: '9no dígito'},
  {id: 'name', numeric: false, disablePadding: false, label: 'Nombre'},
  {id: 'plan', numeric: false, disablePadding: false, label: 'Plan'},
  {id: 'isActive', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Acciones'}
];

export const allCustomersCols = [
  {id: 'entityType', numeric: false, disablePadding: false, label: 'Tipo'},
  {id: 'ruc', numeric: false, disablePadding: false, label: 'RUC'},
  {id: 'rucKey', numeric: false, disablePadding: false, label: '9no dígito'},
  {id: 'name', numeric: false, disablePadding: false, label: 'Nombre'},
  {id: 'plan', numeric: false, disablePadding: false, label: 'Plan'},
  {id: 'accountantId', numeric: false, disablePadding: false, label: 'Contador'},
  {id: 'isActive', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Acciones'}
];

export const accountantsCols = [
  {id: 'image', numeric: false, disablePadding: false, label: ''},
  {id: 'name', numeric: false, disablePadding: false, label: 'Nombre'},
  {id: 'email', numeric: false, disablePadding: false, label: 'Email'},
  {id: 'cellPhone', numeric: false, disablePadding: false, label: 'Celular'},
  {id: 'customers', numeric: false, disablePadding: false, label: 'Cantidad Clientes'},
  {id: 'userId', numeric: false, disablePadding: false, label: 'Perfil'},
  {id: 'active', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Editar'}
];

export const documentsOfCustomer = [
  {id: 'date', numeric: false, disablePadding: false, label: 'Fecha'},
  {id: 'sendHistory', numeric: false, disablePadding: false, label: 'Fecha de último envío'},
  {id: 'entity', numeric: false, disablePadding: false, label: 'Institución'},
  {id: 'name', numeric: false, disablePadding: false, label: 'Documento'},
  {id: 'link', numeric: false, disablePadding: false, label: 'Link'},
  // {id: 'cheklist', numeric: false, disablePadding: false, label: 'Paso (Checklist)'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Acciones'}
];


export const adminColsChecklistCustomer = [
  {id: 'name', numeric: false, disablePadding: false, label: 'Concepto'},
  {id: 'status', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'observations', numeric: false, disablePadding: false, label: 'Observación'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Acciones'}
];
export const colsChecklistCustomer = [
  {id: 'name', numeric: false, disablePadding: false, label: 'Concepto'},
  {id: 'status', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'observations', numeric: false, disablePadding: false, label: 'Observación'}
];

export const colsChecklist = [
  {id: 'name', numeric: false, disablePadding: false, label: 'Nombre'},
  {id: 'status', numeric: false, disablePadding: false, label: 'Estado'},
  {id: 'id', numeric: false, disablePadding: false, label: 'Acciones'}
];
