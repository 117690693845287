const styles = theme => ({
  root: {
    display: 'flex'
  },
  progress: {
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('md')]: {
      left: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%'
    }
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: 30,
    paddingTop: 15,
    marginTop: 70,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100vw - 315px)'
    }
  },
  snackbar: {
    position: 'absolute',
    top: '0%',
    [theme.breakpoints.up('md')]: {
      left: '40%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '0%'
    }
  }
});

export default styles;
