import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import {IconButton} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {createCustomer} from '../../actions/customers';
import CustomSnackbar from '../common/customSnackbar';
import logo from '../../assets/proserint_logo.png';
import styles from './styles';
import {isEmail, isPhone, isText} from '../../utils/utils';
import Uploader from '../common/uploader';


export class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ruc: '',
      name: '',
      lastName: '',
      email: '',
      cellPhone: '',
      phone: '',
      plan: '',
      showForm: true,
      businessName: '',
      contactName: '',
      contactLastName: '',
      comments: '',
      rucFile: '',
      address: ''
    };
    this.onSignInClick = this.onSignInClick.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onRucChange = this.onRucChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onCellPhoneChange = this.onCellPhoneChange.bind(this);
    this.onAddressChange = this.onAddressChange.bind(this);
    this.onCommentsChange = this.onCommentsChange.bind(this);
    this.rendeSnack = this.renderSnack.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.validate = this.validate.bind(this);
    this.onContactNameChange = this.onContactNameChange.bind(this);
    this.onContactLastNameChange = this.onContactLastNameChange.bind(this);
    this.onBusinessNameChange = this.onBusinessNameChange.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.cleanFile = this.cleanFile.bind(this);
  }

  componentWillMount() {
    const url = new URL(window.location.href);
    const plan = url.searchParams.get('plan');
    this.setState({plan});
  }

  onNameChange(e) {
    const name = e.target.value;
    if (isText(name)) { this.setState({name}); }
  }

  onRucChange(e) {
    this.setState({ruc: e.target.value});
  }

  onBusinessNameChange(e) {
    const businessName = e.target.value;
    this.setState({businessName});
  }


  onEmailChange(e) {
    this.setState({email: e.target.value});
  }

  onLastNameChange(e) {
    const lastName = e.target.value;
    if (isText(lastName)) { this.setState({lastName}); }
  }

  onContactLastNameChange(e) {
    const contactLastName = e.target.value;
    if (isText(contactLastName)) { this.setState({contactLastName}); }
  }

  onContactNameChange(e) {
    const contactName = e.target.value;
    if (isText(contactName)) { this.setState({contactName}); }
  }


  onPhoneChange(e) {
    const phone = e.target.value;
    if (isPhone(phone)) { this.setState({phone}); }
  }

  onCellPhoneChange(e) {
    const cellPhone = e.target.value;
    if (isPhone(cellPhone)) { this.setState({cellPhone}); }
  }

  onAddressChange(e) {
    const address = e.target.value;
    this.setState({address});
  }

  onCommentsChange(e) {
    this.setState({comments: e.target.value});
  }


  async onSignInClick() {
    const {ruc, name, lastName, email, cellPhone, plan, businessName, contactLastName, contactName, comments, rucFile, address, phone} = this.state;
    let customer = null;
    if (plan === 'empresarial') {
      customer = {
        ruc,
        businessName,
        contactLastName,
        contactName,
        email,
        cellPhone,
        comments,
        rucFile,
        address,
        phone
      };
    } else {
      customer = {
        ruc,
        name,
        lastName,
        email,
        cellPhone,
        comments,
        rucFile,
        address,
        phone
      };
    }
   
    await this.props.createCustomer(customer, plan);

    if (!this.props.error) {
      this.setState({
        ruc: '',
        name: '',
        lastName: '',
        email: '',
        cellPhone: '',
        plan: '',
        comments: '',
        address: '',
        phone: '',
        showForm: false});
    }
  }

  
  onChangeFile(file) {
    this.setState({rucFile: file});
  }

  cleanFile() {
    this.setState({rucFile: ''});
  }

  validate() {
    const {state} = this;
    if (state.plan === 'empresarial') {
      if (state.contactName !== '' && state.ruc !== '' && state.businessName !== '' && state.contactName !== '' && state.email !== '' && state.cellPhone.length === 10 && isEmail(state.email)) {
        return false;
      }
    } else if (state.name !== '' && state.ruc !== '' && state.lastName !== '' && state.email !== '' && state.cellPhone.length === 10 && isEmail(state.email)) {
      return false;
    }
    return true;
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          open
          onClose={() => {}}
          message={message}
          variant={error === null ? 'success' : 'error'}
        />
      );
    }
    return '';
  }

  renderButton() {
    const {classes, isLoading} = this.props;
    if (!isLoading) {
      return (
        <Button
          type="submit"
          variant="contained"
          disabled={this.validate()}
          onClick={this.onSignInClick}
          className={classes.button}
        >
          CREAR CUENTA
        </Button>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  renderContent() {
    const {classes} = this.props;
    const states = this.state;
    if (this.state.showForm) {
      return (
        <div className={classes.main}>
          <Paper className={classes.paper}>
            <img src={logo} alt="logo" className={classes.logo} />

            <Typography variant="caption" className={classes.planTitle}>
              Plan seleccionado
            </Typography>

            <Typography gutterBottom style={{color: '#697B8C', fontSize: 22}}>
              {this.state.plan === 'basico' ? 'Básico' : (this.state.plan === 'profesional' ? 'Profesional ' : 'Empresarial ')}
            </Typography>

            <Divider className={classes.divider} />

            <Typography style={{color: '#8CA0B3', fontSize: 14}}>
              Ingresa tus datos para completar tu cuenta
            </Typography>
            <form className={classes.form}>
              <TextField
                value={states.ruc}
                autoFocus
                onChange={this.onRucChange}
                fullWidth
                label="RUC *"
                className={classes.input}
                inputProps={{maxLength: 13}}
              />
              { this.state.plan === 'empresarial' &&
                <TextField
                  value={states.businessName}
                  onChange={this.onBusinessNameChange}
                  fullWidth
                  label="Razón social *"
                  className={classes.input}
                />
              } 
              { this.state.plan === 'empresarial' && 
                <TextField
                  value={states.contactName}
                  onChange={this.onContactNameChange}
                  fullWidth
                  label="Nombres persona de contacto *"
                  className={classes.input}
                />
              }
              { this.state.plan === 'empresarial' && 
                <TextField
                  value={states.contactLastName}
                  onChange={this.onContactLastNameChange}
                  fullWidth
                  label="Apellidos persona de contacto *"
                  className={classes.input}
                />
              }
              { this.state.plan !== 'empresarial' && 
                <TextField
                  value={states.name}
                  onChange={this.onNameChange}
                  fullWidth
                  label="Nombres *"
                  className={classes.input}
                />
              }
              { this.state.plan !== 'empresarial' && 
                <TextField
                  value={states.lastName}
                  onChange={this.onLastNameChange}
                  fullWidth
                  label="Apellidos *"
                  className={classes.input}
                />
              }
              <TextField
                error={this.state.email !== '' && !isEmail(this.state.email)}
                value={states.email}
                onChange={this.onEmailChange}
                fullWidth
                label="Email *"
                className={classes.input}
              />
              <TextField
                value={states.cellPhone}
                onChange={this.onCellPhoneChange}
                fullWidth
                label="Celular *"
                className={classes.input}
                error={this.state.cellPhone.length < 10 && this.state.cellPhone !== ''}
                inputProps={{maxLength: 10}}
              />
              {/* <TextField
                value={states.phone}
                onChange={this.onPhoneChange}
                fullWidth
                label="Teléfono"
                className={classes.input}
                error={this.state.phone.length < 6 && this.state.phone !== ''}
                inputProps={{maxLength: 10}}
              /> */}
              <TextField
                value={states.address}
                onChange={this.onAddressChange}
                fullWidth
                label="Dirección"
                className={classes.input}
                error={this.state.address.length < 2 && this.state.address !== ''}
              />
              <TextField
                value={states.comments}
                onChange={this.onCommentsChange}
                fullWidth
                multiline
                rows={4}
                maxrows={4}
                label="¿Que tipo de servicio estás buscando?"
                className={classes.input}
                error={this.state.comments.length < 2 && this.state.comments !== ''}
              />
              {
                states.ruc.length > 11 && states.rucFile === '' &&
                <div className="leftUploader">
                  <Uploader
                    buttonText={states.rucFile ? 'Subir RUC' : 'Subir RUC'}
                    photo
                    dirName="documentos"
                    urlUploaded={this.onChangeFile}
                    customerRuc={states.ruc}
                    fileName={states.ruc}
                    isRuc
                    leftUploader
                  />
                </div>
              }
              {
                states.rucFile !== '' && 
                <div className={classes.fileUploaded}>Archivo de RUC subido 
                  <IconButton onClick={this.cleanFile} aria-label="delete" className={classes.margin}>
                    <DeleteIcon fontSize="large" />
                  </IconButton>
                </div>
              }
              {this.renderButton()}
              {this.renderSnack()}
            </form>
          </Paper>
        </div>
      );
    }
    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />
          <Typography gutterBottom style={{color: '#697B8C', fontSize: 22}}>
            ¡Gracias por tu registro!
          </Typography>
          <Typography style={{color: '#8CA0B3', fontSize: 14, textAlign: 'center'}}>
            Pronto uno de nuestros asesores se pondrá en contacto contigo para agendar una cita y completar tu perfil.
          </Typography>
          <br />
          <Typography style={{color: '#8CA0B3', fontSize: 14, textAlign: 'center'}}>
            Si tienes alguna inquietud, por favor visita nuestra sección de preguntas frecuentes
          </Typography>
          <Button
            type="submit"
            variant="contained"
            onClick={() => { window.location.href = 'http://micontador.ec/#preguntas_frecuentes'; }}
            className={classes.button}
            align="center"
          >
            Ir a preguntas frecuentes
          </Button>
        </Paper>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }
}

Signin.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  createCustomer: PropTypes.func
};

const mapStateToProps = (state) => {
  const {customer} = state;
  return {
    isLoading: customer.sending,
    error: customer.error,
    message: customer.message};
};

const mapDispatchToProps = {
  createCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Signin));
