import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SnackbarContent from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import {styles} from './styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

export class CustomSnackbar extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      showSnackbar: true
    };
    this.renderSnackBar = this.renderSnackBar.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderSnackBar() {
    const {message, classes, variant} = this.props;
    const {showSnackbar} = this.state;
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({showSnackbar: false});
      }
    }, variant === 'error' ? 7000 : 2500);
    if (showSnackbar === true) {
      const Icon = variantIcon[variant];
      return (
        <div>
          <SnackbarContent
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            className={classNames(classes[variant])}
            open={showSnackbar}
            ContentProps={{
              className: classes[variant]
            }}
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon className={classNames(classes.icon, classes.iconVariant)} />
                {message}
              </span>
            }
          />
        </div>
      );
    }
    return '';
  }

  render() {
    const {open} = this.props;
    const {showSnackbar} = this.state;
    return open &&
                showSnackbar &&
                    this.renderSnackBar();
  }
}

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired
};

export default ((withStyles)(styles)(CustomSnackbar));
