import axios from 'axios';
import * as checklistActions from './types/checklist';
import {axiosMaker, errorMessage, axiosMakerFilters} from '../utils/utils';
import store from '../store';

export const getChecklist = () => async (dispatch) => {
  dispatch({
    type: checklistActions.GET_CHECKLIST_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', 'checklist', {}, true));
    dispatch({
      type: checklistActions.GET_CHECKLIST_SUCCESS,
      payload: {
        checklist: response.data.results
      }
    });
    return response.data.results;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: checklistActions.GET_CHECKLIST_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};


export const changeContact = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.contact = value;
  return ({
    type: checklistActions.GET_CHECKLIST_SENDING,
    payload: {
      patchCustomer: origin
    }
  });
};

export const patchChecklist = (id, data) => async (dispatch) => {
  dispatch({
    type: checklistActions.PATCH_CHECKLIST_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('PATCH', `checklist/${id}`, data, true));
    return dispatch({
      type: checklistActions.PATCH_CHECKLIST_SUCCESS,
      payload: {
        customer: response.data.result
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: checklistActions.PATCH_CHECKLIST_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getChecklistAll = () => async (dispatch) => {
  dispatch({
    type: checklistActions.GET_CHECKLIST_SENDING,
    payload: {}
  });
  try {
    const response = await axios(axiosMaker('GET', 'checklist?all=true', {}, true));
    dispatch({
      type: checklistActions.GET_CHECKLIST_SUCCESS,
      payload: {
        checklist: response.data.results
      }
    });
    return response.data.results;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: checklistActions.GET_CHECKLIST_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

