export const GET_CUSTOMERS_OF_ACCOUNTANT_SENDING = 'GET_CUSTOMERS_OF_ACCOUNTANT_SENDING';

export const GET_CUSTOMERS_OF_ACCOUNTANT_SUCCESS = 'GET_CUSTOMERS_OF_ACCOUNTANT_SUCCESS';

export const GET_CUSTOMERS_OF_ACCOUNTANT_FAILED = 'GET_CUSTOMERS_OF_ACCOUNTANT_FAILED';

export const GET_ACCOUNTANT_SENDING = 'GET_ACCOUNTANT_SENDING';

export const GET_ACCOUNTANT_SUCCESS = 'GET_ACCOUNTANT_SUCCESS';

export const GET_ACCOUNTANT_FAILED = 'GET_ACCOUNTANT_FAILED';

export const GET_ACCOUNTANTS_SENDING = 'GET_ACCOUNTANTS_SENDING';

export const GET_ACCOUNTANTS_SUCCESS = 'GET_ACCOUNTANTS_SUCCESS';

export const GET_ACCOUNTANTS_FAILED = 'GET_ACCOUNTANTS_FAILED';

export const CREATE_ACCOUNTANT_SENDING = 'CREATE_ACCOUNTANT_SENDING';

export const CREATE_ACCOUNTANT_SUCCESS = 'CREATE_ACCOUNTANT_SUCCESS';

export const CREATE_ACCOUNTANT_FAILED = 'CREATE_ACCOUNTANT_FAILED';

export const PATCH_ACCOUNTANT_SENDING = 'PATCH_ACCOUNTANT_SENDING';

export const PATCH_ACCOUNTANT_SUCCESS = 'PATCH_ACCOUNTANT_SUCCESS';

export const PATCH_ACCOUNTANT_FAILED = 'PATCH_ACCOUNTANT_FAILED';
