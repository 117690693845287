export const GET_DASHBOARD_HISTORY_EMAILS_SENDING = 'GET_DASHBOARD_HISTORY_EMAILS_SENDING';

export const GET_DASHBOARD_HISTORY_EMAILS_SUCCESS = 'GET_DASHBOARD_HISTORY_EMAILS_SUCCESS';

export const GET_DASHBOARD_HISTORY_EMAILS_FAILED = 'GET_DASHBOARD_HISTORY_EMAILS_FAILED';

export const RESEND_DASHBOARD_HISTORY_EMAILS_SENDING = 'RESEND_DASHBOARD_HISTORY_EMAILS_SENDING';

export const RESEND_DASHBOARD_HISTORY_EMAILS_SUCCESS = 'RESEND_DASHBOARD_HISTORY_EMAILS_SUCCESS';

export const RESEND_DASHBOARD_HISTORY_EMAILS_FAILED = 'RESEND_DASHBOARD_HISTORY_EMAILS_FAILED';

