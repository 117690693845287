
import * as customersActions from '../actions/types/customer';

const initialState = {
  customer: null,
  password: '',
  taxes: [],
  taxesEnterprise: [],
  citys: [],
  entitys: [],
  civilStatus: [],
  bankEntitys: [],
  patchCustomer: {},
  sending: false,
  sendingCitys: false,
  sendingTaxes: false,
  sendingTaxesEnterPrise: false,
  sendingEntitys: false,
  message: '',
  customers: [],
  dataLength: 0,
  error: null,
  searchText: '',
  order: 'asc',
  orderBy: 'name',
  page: 0,
  rowsPerPage: 10
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    customer,
    password,
    patchCustomer,
    taxes,
    taxesEnterprise,
    citys,
    entitys,
    civilStatus,
    bankEntitys,
    error,
    customers,
    message,
    dataLength,
    searchText,
    order,
    orderBy,
    page,
    rowsPerPage
  } = action.payload;

  switch (action.type) {
  case customersActions.GET_CUSTOMERS_SENDING:
    return {...state, sending: true, response: false, customers: [], dataLength, searchText, order, orderBy, page, rowsPerPage};
  case customersActions.GET_CUSTOMERS_SUCCESS:
    return {...state, sending: false, response: true, customers, dataLength};
  case customersActions.GET_CUSTOMERS_FAILED:
    return {...state, sending: false, response: true, message, error};
  case customersActions.CREATE_CUSTOMER_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case customersActions.CREATE_CUSTOMER_SUCCESS:
    return {...state, sending: false, response: true, message: 'Usted se ha registrado exitosamente ', error: null};
  case customersActions.CREATE_CUSTOMER_FAILED:
    return {...state, sending: false, response: true, message, error};
  case customersActions.EDIT_CUSTOMER_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case customersActions.EDIT_CUSTOMER_SUCCESS:
    return {...state, sending: false, response: true, patchCustomer, message: 'Cliente modificado satisfactoriamente', error: null};
  case customersActions.EDIT_CUSTOMER_FAILED:
    return {...state, sending: false, response: true, message, customers: [], error};
  case customersActions.GET_CUSTOMER_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case customersActions.GET_CUSTOMER_SUCCESS:
    return {...state, sending: false, message: '', error: null, customer, password};
  case customersActions.GET_CUSTOMER_FAILED:
    return {...state, sending: false, response: true, message, customers: null, error};
  case customersActions.GET_TAXES_SENDING:
    return {...state, sendingTaxes: true, response: false, message: '', error: null};
  case customersActions.GET_TAXES_SUCCESS:
    return {...state, sendingTaxes: false, error: null, taxes};
  case customersActions.GET_TAXES_FAILED:
    return {...state, sendingTaxes: false, response: true, message, taxesEnterprise: [], error};
  case customersActions.GET_TAXES_ENTERPRISE_SENDING:
    return {...state, sendingTaxesEnterPrise: true, response: false, message: '', error: null};
  case customersActions.GET_TAXES_ENTERPRISE_SUCCESS:
    return {...state, sendingTaxesEnterPrise: false, error: null, taxesEnterprise};
  case customersActions.GET_TAXES_ENTERPRISE_FAILED:
    return {...state, sendingTaxesEnterPrise: false, response: true, message, taxes: [], error};
  case customersActions.GET_CITYS_SENDING:
    return {...state, sendingCitys: true, response: false, message: '', error: null};
  case customersActions.GET_CITYS_SUCCESS:
    return {...state, sendingCitys: false, error: null, citys};
  case customersActions.GET_CITYS_FAILED:
    return {...state, sendingCitys: false, response: true, message, citys: [], error};
  case customersActions.GET_ENTITYS_SENDING:
    return {...state, sendingEntitys: true, response: false, message: '', error: null};
  case customersActions.GET_ENTITYS_SUCCESS:
    return {...state, sendingEntitys: false, error: null, entitys};
  case customersActions.GET_ENTITYS_FAILED:
    return {...state, sendingEntitys: false, response: true, message, entitys: [], error};
  case customersActions.GET_BANKENTITYS_SENDING:
    return {...state, sendingEntitys: true, response: false, message: '', error: null};
  case customersActions.GET_BANKENTITYS_SUCCESS:
    return {...state, sendingEntitys: false, error: null, bankEntitys};
  case customersActions.GET_BANKENTITYS_FAILED:
    return {...state, sendingEntitys: false, response: true, message, bankEntitys: [], error};
  case customersActions.GET_CIVILSTATUS_SENDING:
    return {...state, sendingEntitys: true, response: false, message: '', error: null};
  case customersActions.GET_CIVILSTATUS_SUCCESS:
    return {...state, sendingEntitys: false, error: null, civilStatus};
  case customersActions.GET_CIVILSTATUS_FAILED:
    return {...state, sendingEntitys: false, response: true, message, civilStatus: [], error};
  case customersActions.CHANGE_RUC_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_ADDRESS_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_NAME_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_CELLPHONE_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_LASTNAME_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_PHONE_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_DISABILITY_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_BIRTHDATE_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_SIGNATURE_EXPIRATION_DATE:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_NOMINATION_EXPIRATION_DATE:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_OBSERVATIONS_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_CITY_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_SPOUSE_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_CHILDRENS_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_INCOME_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_EXPENSESESTIMATION_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_TAXES_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_ACCOUNTS_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_BANKACCOUNT_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_DOCUMENTS_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_TOKEN_DORA_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_CONTACT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_ORIGIN_REPORT_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CHANGE_EMPLOYEES_INPUT:
    return {...state, sending: false, patchCustomer};
  case customersActions.CLEAN_PATCHCUSTOMER:
    return {...state, sending: false, patchCustomer};
  case customersActions.LOGIN_DORA:
    return {...state, sending: false};
  default:
    return state;
  }
};
