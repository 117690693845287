export const GET_DOCUMENTS_SENDING = 'GET_DOCUMENTS_SENDING';

export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';

export const GET_DOCUMENTS_FAILED = 'GET_DOCUMENTS_FAILED';

export const GET_DOCUMENT_SENDING = 'GET_DOCUMENT_SENDING';

export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';

export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED';

export const CREATE_DOCUMENT_SENDING = 'CREATE_DOCUMENT_SENDING';

export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';

export const CREATE_DOCUMENT_FAILED = 'CREATE_DOCUMENT_FAILED';

export const DELETE_DOCUMENT_SENDING = 'DELETE_DOCUMENT_SENDING';

export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';

export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

export const RESEND_EMAIL_DOCUMENT_SENDING = 'RESEND_DOCUMENT_SENDING';

export const RESEND_EMAIL_DOCUMENT_SUCCESS = 'RESEND_DOCUMENT_SUCCESS';

export const RESEND_EMAIL_DOCUMENT_FAILED = 'RESEND_DOCUMENT_FAILED';
