export const styles = () => ({
  hide: {
    display: 'none'
  },
  button: {
    marginTop: 20,
    marginBottom: 20
  },
  icon: {
    marginRight: 10
  },
    leftUploader: {
      textAlign: "left",
      background:'red'
  },
  emptyContainer: {
  }
});

export default styles;
