import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import SearchIcon from '@material-ui/icons/Search';
import FilterList from '@material-ui/icons/FilterList';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import {DatePicker} from 'material-ui-pickers';
import {dateFormat, apiHost} from '../../../utils/utils';
import {styles} from './styles';
import Pagination from './toolbarPaginationTable';
import HeadTable from './headTable';
import {Link} from '@material-ui/core';
import EmailsHistory from '../../historyEmails';

moment.locale('America/Guayaquil');

class DocumentsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searching: false,
      searchText: '',
      open: false,
      id: '',
      openFilters: false,
      date: null
    };
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseFilters = this.handleCloseFilters.bind(this);
    this.handleOpenFilters = this.handleOpenFilters.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.handleFilterAction = this.handleFilterAction.bind(this);
    console.log('asdasdas');
  }

  componentWillMount() {
    const {searchText, month} = this.props;
    if (searchText) {
      if (searchText !== '') {
        this.setState({searching: true, searchText});
      }
    }
    if (month) {
      this.setState({date: month});
    }
  }

  onSearch() {
    this.props.onSearch(this.state.searchText);
  }

  onFilter() {
    let month = null;
    if (this.state.date) {
      month = this.state.date;
    }
    this.props.onFilter(month);
  }

  onDelete() {
    const {id} = this.state;
    const customerDocument = this.props.rows.filter(obj => obj.id === id)[0];
    this.props.onDelete(customerDocument);
    this.setState({open: false});
  }

  handleClose = () => {
    this.setState({open: false});
  };

  handleCloseFilters = () => {
    this.setState({openFilters: false});
  };

  handleFilterAction = () => {
    let month = null;
    if (this.state.date) {
      month = this.state.date;
    }
    this.props.onFilter(month);
  };

  handleOpenFilters = () => {
    this.setState({openFilters: true});
  };

  handleChangePage = (event, page) => {
    console.log('page: ', page);
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({page: 0, rowsPerPage: event.target.value});
  };

  handleCancelSearch() {
    this.setState({searching: false, searchText: ''});
    this.props.onSearch('');
  }

  handlePressEnterSearch(e) {
    if (e.key === 'Enter') {
      this.onSearch(this.state.searchText);
      e.preventDefault();
    }
  }

  renderSearchBar() {
    if (this.state.searching === true) {
      return (
        <div className={this.props.classes.search} >
          <TextField
            label="Buscar"
            fullWidth
            autoFocus
            value={this.state.searchText}
            onChange={e => this.setState({searchText: e.target.value})}
            onKeyPress={this.handlePressEnterSearch}
          />
        </div>
      );
    }
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  renderDate(date) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

  // eslint-disable-next-line class-methods-use-this
  renderSendDate(row) {
    const {sendHistory} = row;
    if (sendHistory.length > 0) {
      const sendDate = sendHistory[sendHistory.length - 1];
      return (<EmailsHistory description={sendDate.sendDate ? moment.utc(sendDate.sendDate).format('YYYY-MM-DD') : 'Reenviar'} row={row} onResendEmail={this.props.onResendEmail} />);
    }
    return <EmailsHistory description="Reenviar" row={row} />;
  }

  renderAddButton() {
    const {classes, isAdmin, isAccountant} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Button variant="contained" className={classes.addButton} onClick={() => this.props.onOpenDialog()}>
          <AddIcon style={{cursor: 'pointer', color: '#2797E8', marginRight: 10}} />
          Agregar documento
        </Button>
      );
    }
    return '';
  }

  renderSearchBarButtons() {
    const {classes} = this.props;
    if (this.state.searching === false) {
      return (
        <div>
          <Button variant="contained" className={classes.searchButton} onClick={() => this.setState({searching: true})}>
            <SearchIcon style={{color: '#8CA0B3', marginRight: 10}} />
              Buscar
          </Button>

          <Button variant="contained" className={classes.searchButton} onClick={this.handleOpenFilters}>
            <FilterList style={{color: '#8CA0B3', marginRight: 10}} />
              Filtrar
          </Button>

          {this.renderAddButton()}
        </div>
      );
    }
    return (
      <div>
        <IconButton onClick={this.handleCancelSearch}>
          <CloseIcon style={{color: '#8CA0B3'}} />
        </IconButton>
      </div>
    );
  }

  renderTiteleTable() {
    const {classes, isAdmin, isAccountant} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Typography id="tableTitle" className={classes.title}>
          {this.props.customerName} {this.props.customerLastName}
        </Typography>
      );
    }
    return (
      <Typography id="tableTitle" className={classes.title}>
        Comprobantes
      </Typography>
    );
  }

  render() {
    const {classes, rows, order, orderBy, cols} = this.props;
    const {rowsPerPage, page} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page * rowsPerPage));

    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <Typography id="form-dialog-title" className={classes.dialogTitle}>¿Desea eliminar el documento selecionado? </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              NO
            </Button>
            <Button onClick={this.onDelete} color="primary" >
              SI
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openFilters}
          onClose={this.handleCloseFilters}
        >
          <Typography id="form-dialog-title" className={classes.dialogTitle}>Filtrar documentos </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <DialogContent>
            <FormControl className={classes.selectDate}>
              <DatePicker
                views={['year', 'month']}
                fullWidth
                keyboard
                value={this.state.date}
                format="YYYY-MM"
                placeholder="YYYY-MM"
                cancelLabel="Cancelar"
                onChange={(val) => {
                  this.setState({date: val});
                }}
                className={classes.input}
                disableOpenOnEnter
                animateYearScrolling={false}
                InputProps={{
                  classes: {
                    input: classes.textInput
                  },
                  disabled: true
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </FormControl>
            
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseFilters} color="primary">
              CANCELAR
            </Button>
            <Button
              onClick={() => {
                this.props.onFilter(null);
              }}
              color="primary"
            >
              Limpiar
            </Button>
            <Button onClick={this.handleFilterAction} color="primary">
              Filtrar
            </Button>
          </DialogActions>
        </Dialog>

        <Paper className={classes.root}>
          <Toolbar className={classes.toolbarWrapper}>
            <div className={classes.title}>
              {this.renderTiteleTable()}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              {this.renderSearchBarButtons()}
              {this.renderSearchBar()}
            </div>
          </Toolbar>

          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <HeadTable
                cols={cols}
                order={order}
                orderBy={orderBy}
                onOrder={this.props.onOrder}
              />

              <TableBody>
                {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(row => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row" className={classes.rowText}>{this.renderDate(row.date)}</TableCell>
                    <TableCell component="th" scope="row" className={classes.rowText}>{this.renderSendDate(row)}</TableCell>
                    <TableCell component="th" scope="row" className={classes.rowText}>{row.entity}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.name}</TableCell>
                    <TableCell align="left" className={classes.rowText}>
                      <Link target="_blank" href={row.link}>{row.link ? 'Ver Documento' : '' }</Link> 
                    </TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.checklistItem && row.checklistItem.checklistId && row.checklistItem.checklistId.name ? row.checklistItem.checklistId.name : '-' }</TableCell>
                    <TableCell align="left">
                      <div>
                        <a href={`${apiHost}documents/${row.id}`} download>
                          {row.fileId && <Tooltip title="Descargar">
                            <DownloadIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.props.downloadDocument(row.id); }} />
                                         </Tooltip>}
                        </a>
                        <Tooltip title="Borrar">
                          <DeleteIcon style={{cursor: 'pointer', color: 'grey', marginLeft: 25}} onClick={() => { this.setState({open: true, id: row.id}); }} />
                        </Tooltip>


                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{height: 48 * emptyRows}}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              align="right"
              style={{paddingRight: 20}}
              colSpan={3}
              count={this.props.dataLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={Pagination}
              labelRowsPerPage="Número de Filas"
              labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
              classes={{
                select: classes.select
              }}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

DocumentsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  customerName: PropTypes.string.isRequired,
  customerLastName: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  dataLength: PropTypes.number.isRequired,
  onOrder: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  onResendEmail: PropTypes.func


};

export default withStyles(styles)(DocumentsTable);
