import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import {styles} from './styles';
import {isEmail, isPhone, isText} from '../../../utils/utils';
import HeadTable from './headTable';
import Pagination from './toolbarPaginationTable';
import Uploader from '../../../components/common/uploader';

class AccountantTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      searching: false,
      searchText: '',
      open: false,
      id: '',
      accountant: {
        name: '',
        cellPhone: '',
        email: '',
        image: ''
      },
      patchAccountant: {},
      isNew: false
    };
    this.validate = this.validate.bind(this);
    this.handleCancelSearch = this.handleCancelSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handlePressEnterSearch = this.handlePressEnterSearch.bind(this);
    this.renderSearchBar = this.renderSearchBar.bind(this);
    this.renderSearchBarButtons = this.renderSearchBarButtons.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeActive = this.onChangeActive.bind(this);
    this.onChangeCellPhone = this.onChangeCellPhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onCreateAccountant = this.onCreateAccountant.bind(this);
    this.onPatchAccountant = this.onPatchAccountant.bind(this);
    this.onDetail = this.onDetail.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleUrlUploaded = this.handleUrlUploaded.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillMount() {
    const {searchText} = this.props;
    if (searchText) {
      if (searchText !== '') {
        this.setState({searching: true, searchText});
      }
    }
  }

  onChangeName(e) {
    const newAccountant = this.state.accountant;
    newAccountant.name = e.target.value;
    if (isText(newAccountant.name)) {
      this.setState({accountant: newAccountant});
      const {patchAccountant} = this.state;
      patchAccountant.name = e.target.value;
      this.setState({patchAccountant});
    }
  }

  onChangeActive(e) {
    const newAccountant = this.state.accountant;
    newAccountant.active = e.target.value;
    this.setState({accountant: newAccountant});
    const {patchAccountant} = this.state;
    patchAccountant.active = e.target.value;
    this.setState({patchAccountant});
  }

  onChangeCellPhone(e) {
    const newAccountant = this.state.accountant;
    const cellPhone = e.target.value;
    if (isPhone(cellPhone) && cellPhone.length <= 10) {
      newAccountant.cellPhone = cellPhone;
      this.setState({accountant: newAccountant});
      const {patchAccountant} = this.state;
      patchAccountant.cellPhone = e.target.value;
      this.setState({patchAccountant});
    }
  }

  onChangeEmail(e) {
    const newAccountant = this.state.accountant;
    newAccountant.email = e.target.value;
    this.setState({accountant: newAccountant});
    const {patchAccountant} = this.state;
    patchAccountant.email = e.target.value;
    this.setState({patchAccountant});
  }

  onDetail(id) {
    const accountant = this.props.rows.filter(obj => obj.id === id)[0];
    this.setState({accountant: {
      name: accountant.name,
      email: accountant.email,
      cellPhone: accountant.cellPhone,
      image: accountant.image,
      active: accountant.active
    },
    open: true,
    id,
    isNew: false});
  }

  onSearch() {
    this.props.onSearch(this.state.searchText, 'accuontants');
  }

  onCreateAccountant() {
    this.props.onCreateAccountant(this.state.accountant);
    this.setState({open: false});
  }

  onPatchAccountant() {
    const accountant = this.props.rows.filter(obj => obj.id === this.state.id)[0];
    if (this.state.accountant.image !== accountant.image) {
      this.props.deleteUploaded(accountant.image);
    }

    this.props.onPatchAccountant(this.state.id, this.state.patchAccountant);
    this.setState({open: false});
  }

  validate() {
    const {accountant} = this.state;
    if (accountant.name !== '' && accountant.cellPhone !== '' && accountant.email && isEmail(accountant.email) && isPhone(accountant.cellPhone) && accountant.cellPhone.length === 10) {
      return false;
    }
    return true;
  }

  handleUrlUploaded(image) {
    const newAccountant = this.state.accountant;
    if (image === false) { newAccountant.image = ''; } else { newAccountant.image = image; }
    this.setState({accountant: newAccountant});
    const {patchAccountant} = this.state;
    patchAccountant.image = image;
    this.setState({patchAccountant});
  }

  handleClickOpen = () => {
    this.setState({accountant: {
      name: '',
      cellPhone: '',
      email: '',
      image: '',
      active: true
    }});
    this.setState({open: true, isNew: true});
  };

  handleClose = () => {
    this.setState({open: false});
    if (this.state.accountant.image) { this.props.deleteUploaded(this.state.accountant.image); }
  };

  handleCancelSearch() {
    this.setState({searching: false, searchText: ''});
    this.props.onSearch('', 'accuontants');
  }

  handleChangePage = (event, page) => {
    this.setState({page});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({page: 0, rowsPerPage: event.target.value});
  };

  handlePressEnterSearch(e) {
    if (e.key === 'Enter') {
      this.onSearch(this.state.searchText);
      e.preventDefault();
    }
  }

  renderSearchBar() {
    if (this.state.searching === true) {
      return (
        <div className={this.props.classes.serarchInput} >
          <TextField
            label="Buscar"
            fullWidth
            autoFocus
            value={this.state.searchText}
            onChange={e => this.setState({searchText: e.target.value})}
            onKeyPress={this.handlePressEnterSearch}
          />
        </div>
      );
    }
    return '';
  }

  renderSearchBarButtons() {
    const {classes} = this.props;
    if (this.state.searching === false) {
      return (
        <div className={classes.actions}>
          <Tooltip title="Buscar">
            <Button variant="contained" className={classes.addButton} onClick={this.handleClickOpen}>
              <AddIcon style={{cursor: 'pointer', color: '#2797E8', marginRight: 10}} />
              Agregar Contador
            </Button>
          </Tooltip>
          <Tooltip title="Buscar">
            <Button variant="contained" className={classes.button} onClick={() => this.setState({searching: true})}>
              <SearchIcon style={{color: '#8CA0B3', marginRight: 10}} />
                 Buscar
            </Button>
          </Tooltip>

        </div>
      );
    }
    return (
      <div>
        <IconButton onClick={this.handleCancelSearch}>
          <CloseIcon style={{color: '#8CA0B3'}} />
        </IconButton>
      </div>
    );
  }

  render() {
    const {classes, order, orderBy, cols, rows} = this.props;
    const {rowsPerPage, page, isNew, accountant} = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - (page * rowsPerPage));
    return (
      <div>
        <Paper className={classes.root}>
          <Toolbar>
            <div>
              <Typography variant="h5" id="tableTitle" className={classes.title}>
                  Usuarios
              </Typography>
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
              {this.renderSearchBarButtons()}
              {this.renderSearchBar()}
              {this.props.renderStatusFilter()}
            </div>
          </Toolbar>

          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <HeadTable
                cols={cols}
                order={order}
                orderBy={orderBy}
                onOrder={this.props.onOrder}
              />

              <TableBody>
                {rows.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage).map(row => (
                  <TableRow key={row.id}>
                    <TableCell className={classes.avatarCell}>
                      <Avatar src={row.image} className={classes.avatar} />
                    </TableCell>
                    <TableCell component="th" scope="row" className={classes.rowText}>{row.name} {row.lastName}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.email}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.cellPhone}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.customers.length}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.userId === sessionStorage.userId ? 'Administrador' : 'Contador'}</TableCell>
                    <TableCell align="left" className={classes.rowText}>{row.active ? 'Activo' : 'Inactivo'}</TableCell>
                    <TableCell align="left">
                      <div className={classes.iconContainer}>
                        <Tooltip title="Editar">
                          <EditIcon style={{cursor: 'pointer', color: 'grey'}} onClick={() => { this.onDetail(row.id); }} />
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{height: 48 * emptyRows}}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              align="right"
              style={{paddingRight: 20}}
              count={this.props.dataLength}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage="Número de Filas"
              ActionsComponent={Pagination}
              labelDisplayedRows={({from, to, count}) => `${from}-${to} de ${count}`}
              classes={{
                select: classes.selectRows
              }}
            />
          </div>
        </Paper>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
        >
          <Typography id="form-dialog-title" className={classes.dialogTitle}>Agregar nuevo contador </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <DialogContent>
            <TextField
              value={accountant.name}
              onChange={(e) => { this.onChangeName(e); }}
              fullWidth
              label="Nombre"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
            <TextField
              value={accountant.email}
              onChange={(e) => { this.onChangeEmail(e); }}
              fullWidth
              label="Email"
              className={classes.input}
              error={this.state.accountant.email !== '' && !isEmail(this.state.accountant.email)}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
            <TextField
              value={this.state.accountant.cellPhone}
              onChange={(e) => { this.onChangeCellPhone(e); }}
              fullWidth
              label="Celular"
              className={classes.input}
              error={this.state.accountant.cellPhone.length < 10 && this.state.accountant.cellPhone !== ''}
              InputProps={{
                classes: {
                  input: classes.textInput
                }
              }}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
            <FormControl className={classes.select}>
              <InputLabel className={classes.labelInput}>Estado</InputLabel>
              <Select
                value={accountant.active}
                onChange={this.onChangeActive}
                className={classes.textInput}
              >
                <MenuItem value>Activo</MenuItem>
                <MenuItem value={false}>Inactivo</MenuItem>
              </Select>
            </FormControl>
            <div>
              <Typography id="form-dialog-title" className={classes.labelInputFile}>Foto </Typography>
              {
                this.state.accountant.image &&
                <Avatar src={this.state.accountant.image} className={classes.avatar} />
              }
              {
                this.state.accountant.name.length > 0 ?
                  <Uploader
                    buttonText={this.state.accountant.image ? 'Actualizar foto' : 'Subir foto'}
                    photo
                    dirName="fotos"
                    urlUploaded={this.handleUrlUploaded}
                    customerRuc={Date.now()}
                    fileName={this.state.accountant.name}
                  />
                  :
                  ''
              }
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              CANCELAR
            </Button>
            <Button onClick={isNew ? this.onCreateAccountant : this.onPatchAccountant} color="primary" disabled={this.validate()}>
              {isNew ? 'AGREGAR' : 'EDITAR'}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AccountantTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCreateAccountant: PropTypes.func.isRequired,
  onPatchAccountant: PropTypes.func.isRequired,
  renderStatusFilter: PropTypes.func.isRequired,
  deleteUploaded: PropTypes.func.isRequired,
  dataLength: PropTypes.number,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onOrder: PropTypes.func.isRequired

};

export default withStyles(styles)(AccountantTable);
