export const styles = theme => ({
  root: {
    display: 'flex'
  },
  content: {
    height: '100%',
    width: '100%',
    backgroundColor: '#EEF4F9'
  }
});

export default styles;
