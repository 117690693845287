/* eslint-disable no-unneeded-ternary */
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { InlineDatePicker, DatePicker } from "material-ui-pickers";
import CustomSnackbar from "../common/customSnackbar";
import { styles } from "./styles";
import axios from "axios";
import { axiosMaker, errorMessage, axiosMakerFilters } from "../../utils/utils";
import Highcharts from "highcharts/highmaps";

function formatValues(itemNumber) {
  const newItemNumber= parseFloat(itemNumber);
  const noTruncarDecimales = {maximumFractionDigits: 2, minimumFractionDigits: 2};
  return newItemNumber.toLocaleString('en-US', noTruncarDecimales);
}

export class DashboardCountable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruc: "",
      name: "",
      lastName: "",
      cellPhone: "",
      email: "",
      city: "",
      phone: "",
      disability: false,
      address: "",
      birthDate: "",
      observations: "",
      createdDate: "",
      plan: "",
      accountantId: "",
      isActive: "",
      monthlyPayment: "",
      employees: "",
      accountants: [],
      changed: false,
      documentsArray: [],
      tokenDora: "",
      open: false,
      date: moment(),
      statusRequest: "loading",
      report: null
    };
    this.renderSnack = this.renderSnack.bind(this);
    this.renderAccountantToolBar = this.renderAccountantToolBar.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentWillMount() {}

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.renderReport(this.state.date);
  }

  async renderReport(date) {
    try {
      this.setState({ statusRequest: "loading" });
      const { customer } = this.props;
      const dateAux = date.format("YYYY-MM");
      const response = await axios(
        axiosMakerFilters(
          "GET",
          `report/countable_balance?ruc=${customer.ruc}&year=${dateAux}`
        )
      );
      const { report } = response.data;
      this.setState({ statusRequest: "success", report });
    } catch (e) {
      this.setState({ statusRequest: "error" });
    }
  }

  renderSnack() {
    const { message, error } = this.props;
    if (message) {
      return (
        <CustomSnackbar
          open
          onClose={() => {}}
          message={message}
          variant={error === null ? "success" : "error"}
        />
      );
    }
    return "";
  }
  handleGoBack() {
    if (!this.state.changed) {
      this.props.handleGoBack();
    } else {
      this.setState({ open: true });
    }
  }

  renderAccountantToolBar() {
    const { classes, isAdmin, isAccountant } = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Paper className={classes.FirstFormPaper}>
          <Toolbar>
            <Button
              variant="contained"
              className={classes.backddButton}
              onClick={this.handleGoBack}
            >
              Clientes
            </Button>
            <div style={{ width: 10, marginLeft: 5, marginRight: 5 }}>
              <Typography variant="subtitle1" color="secondary">
                /
              </Typography>
            </div>
            <Typography variant="h5" className={classes.titleAppBar}>
              Dashboard
            </Typography>
          </Toolbar>
        </Paper>
      );
    }
    return "";
  }

  render() {
    const { classes, customer, isAdmin, isAccountant } = this.props;
    const { report, statusRequest } = this.state;
    return statusRequest === "loading" ? (
      <CircularProgress className={classes.progress} size={50} thickness={7} />
    ) : (
      <div>
        {this.renderAccountantToolBar()}
        <div>
          <Paper className={classes.FormPaper}>
            <Grid container className={classes.contentDatePicker}>
              <Typography className={classes.titleName}>
                {isAdmin || isAccountant ? customer.name : "Dashboard"}
              </Typography>
              <div>
                <DatePicker
                  views={["year"]}
                  fullWidth
                  keyboard
                  value={this.state.date}
                  format={"YYYY"}
                  onChange={val => {
                    this.setState({ date: val });
                    this.renderReport(val);
                  }}
                  className={classes.input}
                  disableOpenOnEnter
                  animateYearScrolling={false}
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    },
                    disabled: true
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </div>
            </Grid>

            <div className={classes.wrapDivider} align="center">
              <Divider className={classes.divider} />
              <br />
              {report && (
                <Grid container>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>Ingresos</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(report.ingress.total)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>Egresos</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(report.egress.total)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>Utilidad</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(report.utility.total)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <div className={classes.containerReport}>
                      <div className={classes.titleReport}>Impuestos</div>
                      <div className={classes.subtitleReport}>
                        ${formatValues(report.vatPerPay.total)}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </Paper>

          <Paper className={classes.FormPaper}>
            <Grid container className={classes.contentDatePicker}>
              <Typography className={classes.titleName}>
                Reporte mensual
              </Typography>
            </Grid>
            <br />
            <div className={classes.wrapDivider}>
              <div className={classes.tableWrapper}>
                {report && (
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow className={classes.headColumns}>
                        <TableCell>
                          <Typography id="tableTitle" className={classes.title}>
                            Concepto
                          </Typography>
                        </TableCell>
                        {report.months.map((item, key) => (
                          <TableCell key={key}>
                            <Typography
                              id="tableTitle"
                              className={classes.title}
                            >
                              {item}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography id="tableTitle" className={classes.title}>
                            Total
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography>{report.ingress.label}</Typography>
                        </TableCell>
                        {report.ingress.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.ingress.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.egress.label}</Typography>
                        </TableCell>
                        {report.egress.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.egress.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className={classes.title}>
                            {report.utility.label}
                          </Typography>
                        </TableCell>
                        {report.utility.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography className={classes.title}>
                              ${formatValues(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.utility.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.dividerRow}>
                        <TableCell colSpan={14}></TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.retentionVat.label}</Typography>
                        </TableCell>
                        {report.retentionVat.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.retentionVat.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>
                            {report.tributaryCredit.label}
                          </Typography>
                        </TableCell>
                        {report.tributaryCredit.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.tributaryCredit.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography className={classes.title}>
                            {formatValues(report.vatPerPay.label)}
                          </Typography>
                        </TableCell>
                        {report.vatPerPay.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography className={classes.title}>
                              ${formatValues(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.vatPerPay.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.dividerRow}>
                        <TableCell colSpan={14}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={14}>
                          <Typography className={classes.title}>
                            Gastos personales
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>{formatValues(report.feed.label)}</Typography>
                        </TableCell>
                        {report.feed.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${item}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.feed.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.education.label}</Typography>
                        </TableCell>
                        {report.education.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.education.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.health.label}</Typography>
                        </TableCell>
                        {report.health.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.health.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.clothes.label}</Typography>
                        </TableCell>
                        {report.clothes.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.clothes.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography>{report.livingPlace.label}</Typography>
                        </TableCell>
                        {report.livingPlace.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.livingPlace.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.title}>
                            {report.totalGp.label}
                          </Typography>
                        </TableCell>
                        {report.totalGp.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography className={classes.title}>
                              ${formatValues(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.totalGp.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.dividerRow}>
                        <TableCell colSpan={14}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={14}>
                          <Typography className={classes.title}>
                            Otros gastos
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography>{report.other.label}</Typography>
                        </TableCell>
                        {report.other.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography>${formatValues(item)}</Typography>
                          </TableCell>
                        ))}

                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.other.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow className={classes.dividerRow}>
                        <TableCell colSpan={14}></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.title}>
                            {report.total.label}
                          </Typography>
                        </TableCell>
                        {report.total.data.map((item, key) => (
                          <TableCell key={key}>
                            <Typography className={classes.title}>
                              ${formatValues(item)}
                            </Typography>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Typography className={classes.title}>
                            ${formatValues(report.total.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

DashboardCountable.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  customer: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  const { customer } = state;
  return {
    message: customer.message,
    error: customer.error
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardCountable));
