export const LOGIN_SENDING = 'LOGIN_SENDING';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_FAILED = 'LOGIN_FAILED';

export const CHANGE_PASSWORD_SENDING = 'CHANGE_PASSWORD_SENDING';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const ACTIVE_PASSWORD_SENDING = 'ACTIVE_PASSWORD_SENDING';

export const ACTIVE_PASSWORD_SUCCESS = 'ACTIVE_PASSWORD_SUCCESS';

export const ACTIVE_PASSWORD_FAILED = 'ACTIVE_PASSWORD_FAILED';

export const REQUEST_CHANGE_PASSWORD_SENDING = 'REQUEST_CHANGE_PASSWORD_SENDING';

export const REQUEST_CHANGE_PASSWORD_SUCCESS = 'REQUEST_CHANGE_PASSWORD_SUCCESS';

export const REQUEST_CHANGE_PASSWORD_FAILED = 'REQUEST_CHANGE_PASSWORD_FAILED';
