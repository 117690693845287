import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isText} from '../../../utils/utils';
import {styles} from './styles';

export class InfomationCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruc:'',
      name:'',
      lastName:'',
      birthDate:'',
      email:'',
      cellPhone:'',
      phone:'',
      disability:'',
      address:'',
      city:'',
      employees:'',
    };
  }

  componentWillMount() {
    this.setState({...this.props.customer});
    console.log(this.state);
  }
  
  onChangeInput(value, field) {
    this.setState({ [field]: value});
  }


  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.FormPaper}>
      <Typography className={classes.title}>
          Información Personal
      </Typography>

      <div className={classes.wrapDivider} align="center" >
        <Divider className={classes.divider} />
      </div>
      <form className={classes.container} noValidate autoComplete="off">
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.ruc}
              autoFocus
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'ruc');
                this.props.onChangeRuc(e);
              }}
              fullWidth
              label="RUC"
              error={this.state.ruc.length <= 0}
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.name}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'name');
                this.props.onChangeName(e);
              }}
              fullWidth
              label="Nombres"
              error={this.state.name.length <= 0}
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.lastName}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'lastName');
                this.props.onChangeLastName(e);
              }}
              fullWidth
              label="Apellidos"
              error={this.state.lastName && this.state.lastName.length <= 0}
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <InlineDatePicker
              value={this.state.birthDate}
              fullWidth
              clearable
              onChange={ (date)=> {
                const birthdate = moment(date).format(dateFormat);
                this.onChangeInput(birthdate, 'birthDate');
                this.props.onChangeBirthDate(date);
              }}
              label="Fecha de nacimiento"
              format={dateFormat}
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              disabled
              value={this.state.email}
              fullWidth
              label="Correo electrónico"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.cellPhone}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'cellPhone');
                this.props.onChangeCellPhone(e);
              }}
              fullWidth
              label="Celular"
              className={classes.input}
              error={this.state.cellPhone.length < 10}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.phone}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'phone');
                this.props.onChangePhone(e);
              }}
              fullWidth
              label="Teléfono"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <FormControl className={classes.select}>
              <InputLabel htmlFor="disability" className={classes.labelInput}>¿Tiene discapacidad?</InputLabel>
              <Select
                value={this.state.disability}
                onChange={ (e)=> {
                  this.onChangeInput(e.target.value, 'disability');
                  this.props.onChangeDisability(e);
                }}
                displayEmpty
                className={classes.textInput}
              >
                <MenuItem value>Si</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.address}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'address');
                this.props.onChangeAddress(e);
              }}
              fullWidth
              label="Dirección"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <FormControl className={classes.select}>
              <InputLabel htmlFor="city" className={classes.labelInput}>Ciudad</InputLabel>
              <Select
                value={this.state.city}
                onChange={ (e)=> {
                  this.onChangeInput(e.target.value, 'city');
                  this.props.onChangeCity(e);
                }}
                displayEmpty
                className={classes.textInput}
              >
                {
                  this.props.citys.map((city) => {
                    return (
                      <MenuItem value={city.name} key={city.name}>{city.name}</MenuItem>
                    );
                  })
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
            <TextField
              value={this.state.employees}
              onChange={ (e)=> {
                this.onChangeInput(e.target.value, 'employees');
                this.props.onChangeEmployees(e);
              }}
              fullWidth
              label="Número de empleados"
              className={classes.input}
              InputProps={{
                classes: {
                  input: classes.textInput}}}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.labelInput
                }
              }}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
    );
  }
}

InfomationCustomer.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.any,
  citys: PropTypes.array.isRequired
};

export default (withStyles(styles)(InfomationCustomer));

