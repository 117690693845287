import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isText} from '../../../utils/utils';
import {styles} from './styles';

export class Childrens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childrens: []
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCi = this.onChangeCi.bind(this);
    this.onChangeDisability = this.onChangeDisability.bind(this);
    this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteRegister = this.onDeleteRegister.bind(this);
    this.handleUpdateChildrens = this.handleUpdateChildrens.bind(this);
  }

  componentWillMount() {
      const childrensArray = [];
      this.props.childrens.forEach((child) => {
        const CustomerChild = {
          ci: child.ci,
          name: child.name,
          disability: child.disability,
          birthDate: child.birthDate.substring(0, 10)
        };
        childrensArray.push(CustomerChild);
      });
      this.setState({childrens: childrensArray});
  }

  onAddClick() {
    const childrensArray = this.state.childrens;
    const child = {
      ci: '',
      name: '',
      disability: false,
      birthDate: null
    };
    childrensArray.push(child);
    this.setState({childrens: childrensArray});
  }

  onDeleteRegister(key, e) {
    const childrensArray = this.state.childrens;
    childrensArray.splice(key, 1);
    this.setState({childrens: childrensArray});
    this.handleUpdateChildrens();
  }

  onChangeName(key, e) {
    if (isText(e.target.value)) {
      const childrensArray = this.state.childrens;
      childrensArray[key].name = e.target.value;
      this.setState({childrens: childrensArray}); this.handleUpdateChildrens();
    }
  }

  onChangeCi(key, e) {
    const childrensArray = this.state.childrens;
    childrensArray[key].ci = e.target.value;
    this.setState({childrens: childrensArray});
    this.handleUpdateChildrens();
  }

  onChangeDisability(key, e) {
    const childrensArray = this.state.childrens;
    childrensArray[key].disability = e.target.value;
    this.setState({childrens: childrensArray});
    this.handleUpdateChildrens();
  }

  onChangeBirthDate(key, date) {
    const childrensArray = this.state.childrens;
    const birthdate = moment(date).format(dateFormat);
    childrensArray[key].birthDate = birthdate;
    this.setState({childrens: childrensArray});
    this.handleUpdateChildrens();
  }

  handleUpdateChildrens() {
    const {childrens} = this.state;
    this.props.updateChildrens(childrens);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.FormPaper}>
        <Toolbar className={classes.toolbarWrapper}>
          <Typography className={classes.title}>
                Información de los hijos que dependen de usted
          </Typography>
          <div className={classes.spacer} />
          <Button variant="contained" className={classes.addButton} onClick={this.onAddClick} >
            <AddIcon style={{cursor: 'pointer', color: '#697B8C'}} />
              Agregar registro
          </Button>
        </Toolbar>
        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          {
            this.state.childrens.map((children, key) => {
              return (
                <Grid container spacing={3} className={classes.gridWrapper} key={key} >
                  <Grid item xs={11} className={classes.inputWrapper}>
                    <Grid container spacing={3} className={classes.gridContainer} key={key} >
                      <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                        <TextField
                          value={children.ci}
                          autoFocus
                          onChange={(e) => { this.onChangeCi(key, e); }}
                          fullWidth
                          label="Identificación"
                          className={classes.input}
                          InputProps={{
                            classes: {
                              input: classes.textInput}}}
                          InputLabelProps={{
                            FormLabelClasses: {
                              root: classes.labelInput
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                        <TextField
                          value={children.name}
                          onChange={(e) => { this.onChangeName(key, e); }}
                          fullWidth
                          label="Nombre del hijo"
                          className={classes.input}
                          InputProps={{
                            classes: {
                              input: classes.textInput}}}
                          InputLabelProps={{
                            FormLabelClasses: {
                              root: classes.labelInput
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                        <InlineDatePicker
                          value={children.birthDate}
                          fullWidth
                          clearable
                          onChange={(e) => { this.onChangeBirthDate(key, e); }}
                          label="Fecha de nacimiento"
                          format={dateFormat}
                          className={classes.input}
                          InputProps={{
                            classes: {
                              input: classes.textInput}}}
                          InputLabelProps={{
                            FormLabelClasses: {
                              root: classes.labelInput
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper}>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="disability" className={classes.labelInput}>¿Tiene discapacidad?</InputLabel>
                          <Select
                            value={children.disability}
                            onChange={(e) => { this.onChangeDisability(key, e); }}
                            displayEmpty
                            className={classes.textInput}
                          >
                            <MenuItem value>Si</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.inputWrapper}>
                    <div className={classes.deleteContainer}>
                      <Button variant="contained" className={classes.addButton} onClick={(e) => { this.onDeleteRegister(key, e); }}>
                        <DeleteIcon style={{cursor: 'pointer', color: '#697B8C'}} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              );
            })
          }
        </form>
      </Paper>
    );
  }
}

Childrens.propTypes = {
  classes: PropTypes.object.isRequired,
  childrens: PropTypes.array.isRequired,
  updateChildrens: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(Childrens));
