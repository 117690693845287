import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';

export class Taxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxesCatalogue: [],
      taxes: []
    };
    this.onActiveTax = this.onActiveTax.bind(this);
    this.onActiveMonth = this.onActiveMonth.bind(this);
    this.renderMonths = this.renderMonths.bind(this);
    this.handleUpdateTaxes = this.handleUpdateTaxes.bind(this);
    this.handleChangeDay = this.handleChangeDay.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
  }

  componentWillMount() {
    this.setState({taxes: this.props.customerTaxes});

    const taxesArray = [];
    this.props.taxes.forEach((tax) => {
      let visible = false;
      let months = [false, false, false, false, false, false, false, false, false, false, false, false];
      let day;
      if (tax.name === 'IVA mensual' || tax.name === 'IVA semestral' || tax.name === 'Impuesto a la renta' || tax.name === 'Anticipo impuesto a la renta' || tax.name === 'Gastos personales') {
        switch (this.props.ruc.charAt(this.props.ruc.length - 2)) {
        case '1':
          day = 10;
          break;
        case '2':
          day = 12;
          break;
        case '3':
          day = 14;
          break;
        case '4':
          day = 16;
          break;
        case '5':
          day = 18;
          break;
        case '6':
          day = 20;
          break;
        case '7':
          day = 22;
          break;
        case '8':
          day = 24;
          break;
        case '9':
          day = 26;
          break;
        case '0':
          day = 28;
          break;
        default:
          break;
        }
      } else {
        day = 1;
      }
      this.props.customerTaxes.forEach((customerTax) => {
        if (customerTax.name === tax.name) {
          visible = true;
          day = customerTax.day;
          months = customerTax.months;
        }
      });

      const newTax = {
        name: tax.name,
        entity: tax.entity,
        month: tax.month,
        months,
        visible,
        day
      };
      taxesArray.push(newTax);
    });
    this.setState({taxesCatalogue: taxesArray});
  }

  onActiveTax(key, e) {
    const taxesArray = this.state.taxesCatalogue;
    let customerTaxIndex;
    if (taxesArray[key].month) {
      if (taxesArray[key].visible) {
        taxesArray[key].visible = false;

        const customerTaxesArray = this.state.taxes;
        this.state.taxes.forEach((tax) => {
          if (tax.name === taxesArray[key].name) {
            customerTaxIndex = this.state.taxes.indexOf(tax);
            customerTaxesArray.splice(customerTaxIndex, 1);
            this.setState({taxes: customerTaxesArray});
          }
        });
        customerTaxesArray.splice(customerTaxIndex, 1);
        this.setState({taxes: customerTaxesArray});
        this.handleUpdateTaxes();
      } else {
        taxesArray[key].visible = true;

        const tax = {
          name: taxesArray[key].name,
          months: taxesArray[key].months,
          day: taxesArray[key].day
        };
        const customerNewTaxesArray = this.state.taxes;
        customerNewTaxesArray.push(tax);
        this.setState({taxes: customerNewTaxesArray});
        this.handleUpdateTaxes();
      }
    } else {
      const customerNewTaxesArray = this.state.taxes;
      const newTax = {
        name: taxesArray[key].name,
        months: null,
        day: null
      };
      if (taxesArray[key].visible) {
        taxesArray[key].visible = false;
        this.state.taxes.forEach((tax) => {
          if (tax.name === newTax.name) {
            customerTaxIndex = customerNewTaxesArray.indexOf(tax);
          }
        });
        customerNewTaxesArray.splice(customerTaxIndex, 1);
        this.setState({taxes: customerNewTaxesArray});
      } else {
        taxesArray[key].visible = true;
        customerNewTaxesArray.push(newTax);
        this.setState({taxes: customerNewTaxesArray});
      }
      this.handleUpdateTaxes();
      this.setState({taxesCatalogue: taxesArray});
    }
    this.handleUpdateTaxes();
    this.setState({taxesCatalogue: taxesArray});
  }

  onActiveMonth(key, month, e) {
    const taxesArray = this.state.taxesCatalogue;
    if (taxesArray[key].months[month]) {
      taxesArray[key].months[month] = false;
    } else {
      taxesArray[key].months[month] = true;
    }
    this.setState({taxesCatalogue: taxesArray});
    let customerTaxIndex;
    const customerTaxesArray = this.state.taxes;
    this.state.taxes.forEach((tax) => {
      if (tax.name === taxesArray[key].name) {
        customerTaxIndex = this.state.taxes.indexOf(tax);
        customerTaxesArray.splice(customerTaxIndex, 1);
        this.setState({taxes: customerTaxesArray});
      }
    });
    const tax = {
      name: this.state.taxesCatalogue[key].name,
      months: this.state.taxesCatalogue[key].months,
      day: this.state.taxesCatalogue[key].day
    };
    const customerNewTaxesArray = this.state.taxes;
    customerNewTaxesArray.push(tax);
    this.setState({taxes: customerNewTaxesArray});
    this.handleUpdateTaxes();
  }

  handleUpdateTaxes() {
    const {taxes} = this.state;
    this.props.updateTaxes(taxes);
    this.props.onChange();
  }

  handleChangeDay(key, e) {
    const taxesArray = this.state.taxesCatalogue;
    taxesArray[key].day = e.target.value;
    this.setState({taxesCatalogue: taxesArray});
  }

  // eslint-disable-next-line consistent-return
  renderMonths(key, isCustomer) {
    const {classes} = this.props;
    if (this.state.taxesCatalogue[key].visible) {
      return (
        <TableCell align="right" className={classes.checkBox}>
          <FormControl className={classes.input}>
            <InputLabel htmlFor="disability">Día</InputLabel>
            <Select
              value={this.state.taxesCatalogue[key].day}
              onChange={(e) => { this.handleChangeDay(key, e); }}
              disabled={isCustomer}
              displayEmpty
            >
              <MenuItem value={1}>{1}</MenuItem>
              <MenuItem value={2}>{2}</MenuItem>
              <MenuItem value={3}>{3}</MenuItem>
              <MenuItem value={4}>{4}</MenuItem>
              <MenuItem value={5}>{5}</MenuItem>
              <MenuItem value={6}>{6}</MenuItem>
              <MenuItem value={7}>{7}</MenuItem>
              <MenuItem value={8}>{8}</MenuItem>
              <MenuItem value={9}>{9}</MenuItem>
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={11}>{11}</MenuItem>
              <MenuItem value={12}>{12}</MenuItem>
              <MenuItem value={13}>{13}</MenuItem>
              <MenuItem value={14}>{14}</MenuItem>
              <MenuItem value={15}>{15}</MenuItem>
              <MenuItem value={16}>{16}</MenuItem>
              <MenuItem value={17}>{17}</MenuItem>
              <MenuItem value={18}>{18}</MenuItem>
              <MenuItem value={19}>{19}</MenuItem>
              <MenuItem value={20}>{20}</MenuItem>
              <MenuItem value={21}>{21}</MenuItem>
              <MenuItem value={22}>{22}</MenuItem>
              <MenuItem value={23}>{23}</MenuItem>
              <MenuItem value={24}>{24}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={26}>{26}</MenuItem>
              <MenuItem value={27}>{27}</MenuItem>
              <MenuItem value={28}>{28}</MenuItem>
              <MenuItem value={29}>{29}</MenuItem>
              <MenuItem value={30}>{30}</MenuItem>
              <MenuItem value={31}>{31}</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[0]}
                onChange={(e) => { this.onActiveMonth(key, 0, e); }}
              />}
            label="Ene"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[1]}
                onChange={(e) => { this.onActiveMonth(key, 1, e); }}
              />}
            label="Feb"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[2]}
                onChange={(e) => { this.onActiveMonth(key, 2, e); }}
              />}
            label="Mar"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[3]}
                onChange={(e) => { this.onActiveMonth(key, 3, e); }}
              />}
            label="Abr"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[4]}
                onChange={(e) => { this.onActiveMonth(key, 4, e); }}
              />}
            label="May"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[5]}
                onChange={(e) => { this.onActiveMonth(key, 5, e); }}
              />}
            label="Jun"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[6]}
                onChange={(e) => { this.onActiveMonth(key, 6, e); }}
              />}
            label="Jul"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[7]}
                onChange={(e) => { this.onActiveMonth(key, 7, e); }}
              />}
            label="Ago"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[8]}
                onChange={(e) => { this.onActiveMonth(key, 8, e); }}
              />}
            label="Sep"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[9]}
                onChange={(e) => { this.onActiveMonth(key, 9, e); }}
              />}
            label="Oct"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[10]}
                onChange={(e) => { this.onActiveMonth(key, 10, e); }}
              />}
            label="Nov"
            labelPlacement="top"
            className={classes.checkBox}
          />
          <FormControlLabel
            value="top"
            control={
              <Checkbox
                color="primary"
                className={classes.checkBox}
                disabled={isCustomer}
                checked={this.state.taxesCatalogue[key].months[11]}
                onChange={(e) => { this.onActiveMonth(key, 11, e); }}
              />}
            label="Dic"
            labelPlacement="top"
            className={classes.checkBox}
          />
        </TableCell>
      );
    }
  }

  renderTitle(isCustomer, title) {
    const {classes} = this.props;
    let render = false;
    if (isCustomer) {
      this.props.taxes.forEach((tax) => {
        this.props.customerTaxes.forEach((customerTax) => {
          if (tax.name === customerTax.name) {
            console.log('customerTax.name: ', customerTax.name);
            console.log('tax name: ', tax.name);
            if (tax.entity === title) {
              console.log('tax: ', tax);
              console.log('title: ', title);
              render = true;
            }
          }
        });
      });
    } else {
      render = true;
    }
    if (render) {
      return (
        <TableRow>
          <TableCell colSpan={9} className={classes.entityTitle}>
            {title}
          </TableCell>
        </TableRow>
      );
    }
  }

  render() {
    const {classes, isCustomer} = this.props;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Trámites a realizar
        </Typography>

        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>

        <Table className={classes.table}>
          <TableBody>
            {this.renderTitle(this.props.isCustomer, 'SRI')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'SRI') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'Municipio')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'Municipio') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'IESS')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'IESS') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              checked={tax.visible}
                              disabled
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'Ministerio de trabajo')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'Ministerio de trabajo') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'Nómina')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'Nómina') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'Superintendencia de Compañías')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'Superintendencia de Compañías') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
            {this.renderTitle(this.props.isCustomer, 'Contable')}
            {
              this.state.taxesCatalogue.map((tax, key) => {
                if (tax.entity === 'Contable') {
                  if (isCustomer) {
                    if (tax.visible) {
                      return (
                        <TableRow scope="row" key={key} >
                          <TableCell align="left" className={classes.initialCheckBox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkBox}
                              disabled
                              checked={tax.visible}
                              onChange={(e) => { this.onActiveTax(key, e); }}
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                          <TableCell align="right" className={classes.checkBox}>
                            { tax.month ? this.renderMonths(key, isCustomer) : ''}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  } else {
                    return (
                      <TableRow scope="row" key={key} >
                        <TableCell align="left" className={classes.initialCheckBox}>
                          <Checkbox
                            color="primary"
                            className={classes.checkBox}
                            checked={tax.visible}
                            onChange={(e) => { this.onActiveTax(key, e); }}
                          />
                        </TableCell>
                        <TableCell align="left" className={classes.taxName}>{tax.name} </TableCell>
                        <TableCell align="right" className={classes.checkBox}>
                          { tax.month ? this.renderMonths(key, isCustomer) : ''}
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
                return '';
              })
            }
          </TableBody>
        </Table>

      </Paper>
    );
  }
}

Taxes.propTypes = {
  classes: PropTypes.object.isRequired,
  customerTaxes: PropTypes.array.isRequired,
  updateTaxes: PropTypes.func.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  ruc: PropTypes.string.isRequired,
  taxes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};


export default (withStyles(styles)(Taxes));
