export const drawerWidth = 240;

export const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: 'none'
  },
  linkText: {
    color: '#8CA0B3',
    marginLeft: 20,
    fontWeight: 300
  },
  linkTextSelected: {
    color: '#697B8C',
    marginLeft: 20,
    fontWeight: 900
  },
  logo: {
    width: '60%'
  },
  sections:
  {
    marginTop: 5,
    marginBottom: 5,
    paddingLeft: 40
  },
  appBar: {
    height: 90,
    marginLeft: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    background: '#EEF4F9',
    boxShadow: 'none',
    paddingRight: 30
  },
  toolbarWrapper: {
    width: '100%',
    height: '72%',
    background: '#FFFFFF',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  information: {
    justifyContent: 'center',
    marginTop: 0,
    marginBottom: 0,
    padding: 0
  },
  avatar: {
    margin: 15,
    width: 70,
    height: 70
  },
  divider: {
    marginTop: 50,
    marginBottom: 50,
    width: '30%',
    backgroundColor: '#8CA0B3'
  },
  wrapDivider: {
    justifyContent: 'center',
    margin: 0
  },
  grow: {
    flexGrow: 1
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: 30,
    paddingTop: 15,
    marginTop: 70,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  root: {
    display: 'flex'
  }

});
