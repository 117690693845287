import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {isNumber} from '../../../utils/utils';
import {styles} from './styles';

export class ExpensesEstimation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expensesEstimation: {
        year: '',
        details: []
      }
    };

    this.onExpensChange = this.onExpensChange.bind(this);
    this.handleUpdateExpensesEstimation = this.handleUpdateExpensesEstimation.bind(this);
  }

  componentWillMount() {
    this.setState({expensesEstimation: this.props.expensesEstimation});
  }

  onExpensChange(key, e) {
    if (isNumber(e.target.value)) {
      const expensesChangeArray = this.state.expensesEstimation.details;
      expensesChangeArray[key].value = e.target.value;
      const expensesChange = {
        year: this.state.expensesEstimation.year,
        details: expensesChangeArray
      };
      this.setState({expensesEstimation: expensesChange});
      this.handleUpdateExpensesEstimation();
    }
    if (e.target.value[e.target.value.length - 1] === '.') {
      const expensesChangeArray = this.state.expensesEstimation.details;
      expensesChangeArray[key].value = e.target.value;
      const expensesChange = {
        year: this.state.expensesEstimation.year,
        details: expensesChangeArray
      };
      this.setState({expensesEstimation: expensesChange});
      this.handleUpdateExpensesEstimation();
    }
  }

  handleUpdateExpensesEstimation() {
    const {expensesEstimation} = this.state;
    this.props.updateExpensesEstimation(expensesEstimation);
    this.props.onChange();
  }


  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Proyección de gastos para este año
        </Typography>

        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3} className={classes.gridContainer}>
            {
              this.state.expensesEstimation.details.map((expens, key) => {
                return (
                  <Grid item xs={12} sm={6} md={3} className={classes.inputWrapper} key={key}>
                    <TextField
                      value={expens.value}
                      autoFocus
                      onChange={(e) => { this.onExpensChange(key, e); }}
                      fullWidth
                      label={expens.name}
                      className={classes.input}
                      InputProps={{
                        classes: {
                          input: classes.textInput
                        },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          root: classes.labelInput
                        }
                      }}
                    />
                  </Grid>
                );
              })
            }
          </Grid>
        </form>
      </Paper>

    );
  }
}

ExpensesEstimation.propTypes = {
  classes: PropTypes.object.isRequired,
  expensesEstimation: PropTypes.object.isRequired,
  updateExpensesEstimation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};


export default (withStyles(styles)(ExpensesEstimation));
