const styles = theme => ({

  dialogTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    padding: 20,
    paddingRight: 150,
    paddingBottom: 2,
    color: '#697B8C'
  },
  inputFileButton: {
    marginTop: 20,
    marginBottom: 20
  },
  backddButton: {
    background: 'transparent',
    paddingRight: 5,
    paddingLeft: 0,
    boxShadow: 'none',
    color: '#8CA0B3',
    fontSize: 20,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  titleAppBar:
  {
    flex: 'auto',
    color: '#697B8C',
    fontSize: 20,
    fontWeight: 'bold'
  },
  titleAppBarPrev: {
    flex: 'auto',
    color: '#8CA0B3',
    fontSize: 20,
    fontWeight: 'normal',
    paddingLeft: 5
  },
  progress: {
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('md')]: {
      left: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%'
    }
  },
  select: {
    width: '100%',
    marginTop: 20
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 30,
    width: '100%',
    marginRight: theme.spacing(3)
  },
  snackbar: {
    position: 'absolute',
    top: '0%',
    [theme.breakpoints.up('md')]: {
      left: '40%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '0%'
    }
  },
  input: {
    width: '100%',
    marginTop: 20
  },
  labelInput: {
    color: '#8CA0B3',
    fontSize: 18
  },
  labelInputFile: {
    color: '#8CA0B3',
    fontSize: 16,
    marginTop: 30
  },
  textInput: {
    color: '#697B8C',
    fontWeight: 500,
    fontSize: 14
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    width: '95%'
  },
  wrapDivider: {
    justifyContent: 'center'
  },
  linkPage:{
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  }
});

export default styles;
