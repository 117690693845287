import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LockOpen from '@material-ui/icons/LockOpen';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';

const PasswordsModal = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderPasswords = () => {
    const {currentRow, classes} = props;
    if (currentRow.accounts) {
      return (
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Entidad</TableCell>
              <TableCell align="left">Usuario</TableCell>
              <TableCell align="left">Contraseña</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentRow.accounts.map(account => (
              <TableRow key={account._id}>
                <TableCell component="th" scope="row">
                  {account.entity}
                </TableCell>
                <TableCell align="left">{account.user}</TableCell>
                <TableCell align="left">{account.password}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }
  };

  return (
    <div style={{display: 'inline-block'}} >
      <LockOpen style={{cursor: 'pointer', color: 'grey'}} onClick={handleClickOpen} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cuentas de Usuario.</DialogTitle>
        <DialogContent>
          {renderPasswords()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PasswordsModal;
