import React, {useEffect, useState, Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAccountant} from '../../actions/accountant';
import {styles} from './styles';
import Drawer from '../../components/drawer';

const BasicLayout = (props) => {
  const [isAdmin, setAdmin] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [isAccountant, setIsAccountant] = useState(false);
  const [accountant, setAccountant] = useState({});
  const [user, setUser] = useState({name: null, id: null});
  const {classes} = props;

  useEffect(() => {
    // await sessionStorage.getItem('userId');
    // await sessionStorage.getItem('role');
    const loadPermissions = async () => {

      await sessionStorage.getItem('userId');
      await sessionStorage.getItem('role');
      const role = sessionStorage.getItem('role');
      if (role.includes('administrador')) {
        setAdmin(true);
        // this.setState({isAdmin: true, showAccountants: true});
        const userAccountant = await props.getAccountant(sessionStorage.userId);
        setUser({name: userAccountant.name, id: userAccountant.id});

        // this.setState({id: this.props.accountant.id});
        // this.setState({name: this.props.accountant.name});
        // await this.props.getCustomers(
        //   this.props.searchText,
        //   this.props.page,
        //   this.props.rowsPerPage,
        //   this.props.orderBy,
        //   this.props.order,
        // );
        // this.setState({adminCustomers: this.props.allCustomers});
      } else {
        if (role.includes('cliente')) {
          setIsCustomer(true);
        //   this.setState({isCustomer: true, showAccountants: false});
          const customer = await this.props.getCustomer(sessionStorage.userId);
        //   this.setState({customer: this.props.customer, email: this.props.customer.email, id: this.props.customer.id, documents: this.props.customer.userId, name: this.props.customer.name});
        //   this.props.accountants.forEach((accountant) => {
        //     if (accountant.id === this.props.customer.accountantId) {
        //       this.setState({accountant});
        //     }
        //   });
        }
  
        if (role.includes('contador')) {
        //   this.setState({isAccountant: true, showAccountants: false});
        //   await this.props.getAccountant(sessionStorage.userId);
        //   this.setState({id: this.props.accountant.id});
        //   this.setState({name: this.props.accountant.name});
        //   this.props.getCustomerOfAccountant(
        //     this.props.accountant.id,
        //     this.props.searchText,
        //     this.props.page,
        //     this.props.rowsPerPage,
        //     this.props.orderBy,
        //     this.props.order,
        //   );
        //   this.setState({customer: this.props.customer});
        }
      }

      console.log(role,'role');
    };
    loadPermissions();
  }, []);

  return (
    <div className={classes.root}>
      {user.name && <Drawer
        name={user.name}
        accountant={accountant}
        customer={customer}
        isAdmin={isAdmin}
        isCustomer={isCustomer}
        isAccountant={isAccountant}
      />}
      <div style={{width: '100%'}}>
        <div className={classes.FirstFormPaper}>
          {isAdmin && React.cloneElement(props.children, {isAdmin})}
        </div>
      </div>
    </div>
  );
};

BasicLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  getAccountant: PropTypes.func.isRequired
};


export default connect(state => ({
  userInfo: state.accountants
}), {
  getAccountant
})(withStyles(styles)(BasicLayout));
