import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import {withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {UPLOAD_SUCCESS} from '../../../actions/types/uploader';
import {upload, uploadRucFile} from '../../../actions/uploader';
import {styles} from './styles';
import CustomSnackbar from '../customSnackbar';

export class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleUpload = this.handleUpload.bind(this);
    this.renderSnackBar = this.renderSnackBar.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
  }

  
  handleUpload(e) {
    const {isRuc} = this.props;
    const file = e.target.files[0];
    const name = this.props.fileName.replace(/ /g, '_');
    console.log('name: ', name);
    const fileName = `${this.props.customerRuc}_${name}`;
    const formData = new FormData();
    formData.append('file', file);
    if (isRuc) {
      this.props.uploadRucFile(this.props.dirName, formData, fileName).then((value) => {
        if (value.type === UPLOAD_SUCCESS) {
          if (value.payload.response.Location) {
            if (typeof this.props.uploaderId !== 'undefined') {
              this.props.urlUploaded(value.payload.response.Location, this.props.uploaderId);
            } else {
              this.props.urlUploaded(value.payload.response.Location);
            }
          }
        }
      });
    } else {
      this.props.upload(this.props.dirName, formData, fileName).then((value) => {
        if (value.type === UPLOAD_SUCCESS) {
          if (value.payload.response.Location) {
            if (typeof this.props.uploaderId !== 'undefined') {
              this.props.urlUploaded(value.payload.response.Location, this.props.uploaderId);
            } else {
              this.props.urlUploaded(value.payload.response.Location);
            }
          }
        }
      });
    }
  }

  uploaderIcon() {
    const {classes} = this.props;
    return (<SaveIcon className={classes.icon} />);
  }

  renderSnackBar() {
    const {error, message} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          variant={error !== null ? 'error' : 'success'}
          open
          message={message}
        />
      );
    }
    return '';
  }

  renderLabel() {
    const {uploaderId, classes, buttonText} = this.props;
    const htmlFor = uploaderId ? `contained-button-file${uploaderId}` : 'contained-button-file';
    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label htmlFor={htmlFor}>
        <Button component="span" color="primary" className={classes.button}>
          {buttonText}
        </Button>
      </label>
    );
  }

  render() {
    const {classes, uploaderId, isLoading, leftUploader} = this.props;
    console.log('leftUploader:', leftUploader)
    return isLoading === true ? <CircularProgress size={50} thickness={7} /> : (
      <div classes={classes.leftUploader}>
        {this.renderSnackBar()}
        <input
          accept="*"
          className={classes.hide}
          multiple
          id={uploaderId ? `contained-button-file${uploaderId}` : 'contained-button-file'}
          type="file"
          onChange={this.handleUpload}
        />
        {this.renderLabel()}
      </div>
    );
  }
}
Uploader.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  dirName: PropTypes.string.isRequired,
  urlUploaded: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  uploadRucFile: PropTypes.func,
  uploaderId: PropTypes.string,
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  leftUploader: PropTypes.bool,
  error: PropTypes.object,
  customerRuc: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const {uploader} = state;
  return {isLoading: uploader.sending, error: uploader.error, message: uploader.message};
};

const mapDispatchToProps = {
  upload,
  uploadRucFile
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Uploader));
