import axios from 'axios';
import * as uploaderActions from './types/uploader';
import {axiosFormData, axiosMaker, errorMessage} from '../utils/utils';

export const upload = (dirName, form, fileName) => async (dispatch) => {
  dispatch({
    type: uploaderActions.UPLOAD_SENDING,
    payload: {
      dirName,
      form
    }
  });
  try {
    const response = await axios(axiosFormData('POST', `uploadFile/${dirName}/${fileName}`, form, true));
    return dispatch({
      type: uploaderActions.UPLOAD_SUCCESS,
      payload: {
        response: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: uploaderActions.UPLOAD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const uploadRucFile = (dirName, form, fileName) => async (dispatch) => {
  dispatch({
    type: uploaderActions.UPLOAD_SENDING,
    payload: {
      dirName,
      form
    }
  });
  try {
    const response = await axios(axiosFormData('POST', `uploadRucFile/${dirName}/${fileName}`, form, true));
    return dispatch({
      type: uploaderActions.UPLOAD_SUCCESS,
      payload: {
        response: response.data
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: uploaderActions.UPLOAD_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const deleteUploaded = name => async (dispatch) => {
  const indexOfFileName = name.indexOf('.com/');
  const data = {
    name
  };
  const response = await axios(axiosMaker('POST', 'deleteFile', data, true));
  return dispatch({
    type: uploaderActions.DELETE_UPLOADED,
    payload: {}
  });
};

export default upload;
