export const appBarHeight = 64;
const drawerWidth = 240;

export const styles = theme => ({
  main: {
    paddingTop: 40
  },
  snackbar: {
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('md')]: {
      left: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%'
    }
  },
  progress: {
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.up('md')]: {
      left: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      left: '50%'
    }
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 25
  },
  FormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 25,
    paddingBottom: 30,
    paddingTop: 15
  },
  select: {
    width: '100%',
    maxWidth: 500,
    marginRight: theme.spacing(2)
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 25,
    paddingBottom: 30,
    paddingTop: 15,
    marginTop: 50
  },
  toolbar: {
    height: 300
  },
  papperWraper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 20
  },
  link: {
    textDecoration: 'none'
  },
  titleName:
  {
    flex: 'auto',
    color: '#697B8C',
    fontSize: 20,
    paddingLeft: 40,
    fontWeight: 'bold',
    textAlign: 'left',
    marginLeft: '10px'
  },
  titleAppBarPrev:
  {
    flex: 'auto',
    color: '#8CA0B3',
    fontSize: 20,
    fontWeight: 'normal',
    paddingLeft: 5
  },
  titleAppBar:
  {
    flex: 'auto',
    color: '#697B8C',
    fontSize: 20,
    fontWeight: 'bold'
  },
  form: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  backddButton: {
    background: 'transparent',
    paddingRight: 5,
    boxShadow: 'none',
    color: '#8CA0B3',
    fontSize: 20,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  button: {
    backgroundColor: '#2797E8',
    color: 'white',
    fontSize: 14,
    fontWeight: 'normal',
    margin: 10,
    marginRight: 40,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 10,
    paddingBottom: 10
  },
  content: {
    flexGrow: 1
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20
  },
  input: {
    maxWidth: 500,
    marginRight: theme.spacing(2)
  },
  labelInput: {
    color: '#8CA0B3 !important',
    fontSize: 18
  },
  textInput: {
    color: '#697B8C',
    fontWeight: 500,
    fontSize: 14,
    '&:disabled': {
      color: '#697B8C'
    }
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 20,
    padding: 20,
    paddingRight: 150,
    paddingBottom: 2,
    color: '#697B8C'
  },
  inputWrapper: {
    paddingLeft: 20,
    paddingRight: 20
  },
  gridContainer:
  {
    width: '100%',
    paddingLeft: 28
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    width: '95%'
  },
  wrapDivider: {
    justifyContent: 'center'
  },
  appBar: {
    marginTop: 90,
    display: 'flex',
    right: 'auto',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - (${drawerWidth}px + 65px))`
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 65px)'
    },
    background: '#FFFFFF'
  },
  contentDatePicker: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginLeft: '-23px'
  },
  titleReport: {
    "lineHeight": "1.75",
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontWeight": "400",
    "color": "gray"
  },
  subtitleReport: {
    "lineHeight": "1.75",
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontWeight": "700",
    "color": "gray",
    fontSize: '32px'
  },
  containerReport: {
    borderRight: '1px solid lightgray'
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 30,
    width: '100%',
    marginRight: theme.spacing(3)
  },
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  },
  title: {
    color: '#697B8C',
    fontWeight: 700,
    fontSize: 14
  },
  headColumns: {
    background: '#EFF5FC'
  },
  dividerRow:{
    height: '10px',
    background: '#EEF4F9'
  },
  tableWrapper: {
    overflowY: 'auto'
  },
});
