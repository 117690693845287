import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Divider, Grid, Paper, TextField, Typography } from '@material-ui/core';
import styles from './styles';
import { createComment, getComment } from '../../../actions/comments';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ReportComment = ({ customer, date }) => {
    const classes = styles();
    const dispatch = useDispatch();
    const { comments } = useSelector(state => state.comments);
    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    useEffect(() => {
        const dateAux = date.format('YYYY-MM');
        dispatch(getComment(customer.id, dateAux));
    }, []);

    useEffect(() => {
        if (comments && comments.length > 0) {
            setComment(comments[0].text)
        } else {
            setComment("");
        }
    }, [comments]);
    const handleChangeComment = (e) => {
        setComment(e.target.value);
    };

    const handleSaveComment = async () => {
        setLoading(true);
        await dispatch(createComment({
            customerId: customer.id,
            date: date,
            text: comment
        }));
        setResponseMessage('Comentario guardado con éxito.');
        setShowResponseMessage(true);
        setLoading(false);
    }
    return (
        <Paper className={classes.FormPaper}>
            <div className={classes.wrapDivider} align="center">
                <div className={classes.wrapDivider} align="center">
                    <Typography id="title indicadores" className={classes.titleName}>
                        Comentario
                    </Typography>
                    <Divider className={classes.divider} />
                    <br />
                    {comments && (
                        <div>
                            <Grid container>
                                <Grid item xs={12} className={classes.inputContainer}>
                                    <TextField disabled={!sessionStorage.role.includes('contador')} onChange={handleChangeComment} value={comment} fullWidth multiline rows={3} name="comment" placeholder="Añada su comentario" variant="outlined" />
                                </Grid>
                                {sessionStorage.role.includes('contador') &&
                                    <Grid item xs={12} className={classes.buttonContainer}>
                                        <div className={`${classes.wrapperShopping} ${classes.mt1}`}>
                                            <Button onClick={handleSaveComment} color="primary" variant="contained" disabled={loading} fullWidth>
                                                Guardar
                                            </Button>
                                            {loading && <CircularProgress size={24} className={`${classes.buttonProgress} `} />}
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <br />
                        </div>
                    )}
                    {showResponseMessage && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={showResponseMessage} autoHideDuration={6000} onClose={() => { setShowResponseMessage(false) }} >
                        <Alert severity={'success'} onClose={() => { setShowResponseMessage(false) }}>
                            {responseMessage}
                        </Alert>
                    </Snackbar>}
                </div>
            </div>
        </Paper>
    );
};

ReportComment.propTypes = {
    customer: PropTypes.object,
    date: PropTypes.any
};

export default ReportComment;
