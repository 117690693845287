import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {styles} from './styles';


export class HeadTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderColumn(col) {
    const {order, orderBy, classes} = this.props;
    if (col.id !== 'id') {
      return (
        <TableCell
          key={col.id}
          align={col.numeric ? 'right' : 'left'}
          padding={col.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === col.id ? order : false}
        >
          <Tooltip
            title="Ordenar"
            placement={col.numeric ? 'bottom-end' : 'bottom-start'}
            enterDelay={300}
          >
            <TableSortLabel
              active={orderBy === col.id}
              direction={order}
              onClick={(e) => { this.props.onOrder(col.id, order === 'asc' ? 'desc' : 'asc','customers'); }}
              style={{color: '#697B8C'}}
            >
              <Typography id="tableTitle" className={classes.title}>
                {col.label}
              </Typography>

            </TableSortLabel>
          </Tooltip>
        </TableCell>
      );
    }
    return (
      <TableCell
        key={col.id}
        align={col.numeric ? 'right' : 'left'}
        padding={col.disablePadding ? 'none' : 'default'}
      >
        <Typography id="tableTitle" className={classes.title}>
          {col.label}
        </Typography>
      </TableCell>
    );
  }

  render() {
    const {cols, classes} = this.props;
    return (
      <TableHead>
        <TableRow className={classes.headColumns} >
          {cols.map(
            col => (
              this.renderColumn(col)
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

HeadTable.propTypes = {
  classes: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
};

export default (withStyles(styles, {withTheme: true})(HeadTable));
