import { makeStyles } from "@material-ui/core/styles";
export const appBarHeight = 64;
const drawerWidth = 240;
const styles = makeStyles(theme => ({
    FormPaper: {
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 25,
        paddingBottom: 30,
        paddingTop: 15
    },
    wrapDivider: {
        justifyContent: 'center'
    },
    titleName: {
        flex: 'auto',
        color: '#697B8C',
        fontSize: 20,
        paddingLeft: 40,
        fontWeight: 'bold',
        textAlign: 'left',
        marginLeft: '10px'
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
        width: '95%'
    },
    containerReport: {
        borderRight: '1px solid lightgray'
    },
    titleReport: {
        "lineHeight": "1.75",
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "fontWeight": "400",
        "color": "gray",
    },
    subtitleReport: {
        "lineHeight": "1.75",
        "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        "fontWeight": "700",
        "color": "gray",
        fontSize: '32px'
    },
    inputContainer:{
        padding: "0px 32px"
    },
    buttonContainer:{
        display: "flex",
        justifyContent: "flex-end",
        margin: "16px 32px 0px 0px"
    },
    wrapperShopping: {
        position: "relative"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },
}));
export default styles;
