import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {Route, BrowserRouter, Redirect, Switch} from 'react-router-dom';
import Accountant from '../components/accountants';
import Signin from '../components/signin';
import ChangePassword from '../components/changePassword';
import Login from '../components/login';
import store from '../store';
import {styles} from './styles';
import ForgotPassword from '../components/forgotPassword';
import CheckList from '../components/checklist';
import BasicLayout from '../layouts/basic';
import LoginAccountant from '../components/loginAccountant';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({component: Component, authed, userId, role, classes, title, ...rest}) => {
  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (authed === true) {
            return (
              <div className={classes.root}>
                <div className={classes.content}>
                  <Component {...props} />
                </div>
              </div>
            );
          }
          // eslint-disable-next-line react/prop-types
          return (<Redirect to={{pathname: '/login', state: {from: props.location}}} />);
        }
      }
    />
  );
};

// eslint-disable-next-line react/prop-types
const PublicRoute = ({component: Component, authed, ...rest}) => {
  return (
    <Route
      {...rest}
      render={
        props => (authed === false ?
          <Component {...props} />
          :
          <Redirect to="/inicio" />)
      }
    />
  );
};

export class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      authed: false,
      userId: '',
      role: []
    };
  }

  componentWillMount() {
    if (sessionStorage.token) {
      this.setState({authed: true});
      if (sessionStorage.userId) {
        this.setState({userId: sessionStorage.userId});
      }
      if (sessionStorage.role) {
        this.setState({role: sessionStorage.userId});
      }
    } else {
      if (store.getState().user.token !== null && store.getState().user.user !== null) {
        sessionStorage.setItem('token', store.getState().user.token);
        this.setState({authed: true});

        sessionStorage.setItem('userId', store.getState().user.user.id);
        this.setState({userId: store.getState().user.user.id});

        sessionStorage.setItem('role', store.getState().user.user.role);
        this.setState({role: store.getState().user.user.role});
      }
      store.subscribe(() => {
        if (store.getState().user.token !== null && store.getState().user.user !== null) {
          sessionStorage.setItem('token', store.getState().user.token);
          this.setState({authed: true});

          sessionStorage.setItem('userId', store.getState().user.user.id);
          this.setState({userId: store.getState().user.user.id});

          sessionStorage.setItem('role', store.getState().user.user.role);
          this.setState({role: store.getState().user.user.role});
        }
      });
    }
  }

  render() {
    const {isLoading, authed, userId, role} = this.state;
    const {classes} = this.props;
    return isLoading === true ? <CircularProgress style={{position: 'absolute', left: '50%', top: '50%'}} size={50} thickness={7} /> : (
      <BrowserRouter basename={process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '/'}>
        <Switch>
          <PrivateRoute
            authed={authed}
            exact
            path="/"
            component={Accountant}
            userId={userId}
            classes={classes}
          />
          <PublicRoute
            authed={authed}
            path="/signin"
            component={Signin}
          />
          <PublicRoute
            authed={authed}
            path="/login"
            component={Login}
          />
          <PublicRoute
            authed={authed}
            path="/loginAccountant"
            component={LoginAccountant}
          />
          <PublicRoute
            authed={authed}
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute
            authed={authed}
            path="/reset-password"
            component={ChangePassword}
          />
          <PrivateRoute
            authed={authed}
            userId={userId}
            role={role}
            path="/inicio"
            component={Accountant}
            classes={classes}
          />
          <PrivateRoute
            authed={authed}
            userId={userId}
            role={role}
            path="/checklist"
            component={() => <BasicLayout ><CheckList /></BasicLayout>}
            classes={classes}
          />
          <Route render={() => <h6>Error 404 No Encontrado</h6>} />
        </Switch>
      </BrowserRouter>
    );
  }
}
Router.propTypes = {
  classes: PropTypes.object.isRequired
};

export default (withStyles(styles)(Router));
