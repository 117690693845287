import axios from 'axios';
import * as commentsActions from './types/comments';
import { errorMessage, axiosMakerFilters, axiosMaker } from '../utils/utils';

export const getComment = (customerId, date) => async (dispatch) => {
    dispatch({
        type: commentsActions.GET_COMMENT_SENDING,
        payload: {
            loading: true
        }
    });
    try {
        const response = await axios(axiosMakerFilters('GET',`comments?customerId=${customerId}&date=${date}`));
        return dispatch({
            type: commentsActions.GET_COMMENT_SUCCESS,
            payload: {
                comments: response.data.comment,
                loading: false,
            }
        });
    } catch (e) {
        const error = e.response;
        return dispatch({
            type: commentsActions.GET_COMMENT_FAILED,
            payload: {
                error,
                message: errorMessage(error),
                loading: false
            }
        });
    }
};

export const createComment = (data) => async (dispatch) => {
    dispatch({
        type: commentsActions.CREATE_COMMENT_SENDING,
        payload: {
            loading: true
        }
    });
    try {
        await axios(axiosMaker('POST', `comments/create`, data));
        await dispatch(getComment(data.customerId, data.date));
        return dispatch({
            type: commentsActions.CREATE_COMMENT_SUCCESS,
            payload: {
                loading: false,
            }
        });
    } catch (e) {
        const error = e.response;
        return dispatch({
            type: commentsActions.CREATE_COMMENT_FAILED,
            payload: {
                error,
                message: errorMessage(error),
                loading: false,
            }
        });
    }
};