import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import DownloadIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import {UPLOAD_SUCCESS} from '../../../actions/types/uploader';
import {apiHost} from '../../../utils/utils';
import {styles} from './styles';

export class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ci: {},
      ruc: {},
      votingBallot: {},
      bankCertificate: {},
      id: '',
      fileChanging: ''
    };
    this.onChangeCiFile = this.onChangeCiFile.bind(this);
    this.onChangeRucFile = this.onChangeRucFile.bind(this);
    this.onChangeBankFile = this.onChangeBankFile.bind(this);
    this.onChangeVotingFile = this.onChangeVotingFile.bind(this);
    this.renderLabel = this.renderLabel.bind(this);
    this.renderCiInput = this.renderCiInput.bind(this);
    this.renderRucInput = this.renderRucInput.bind(this);
    this.renderVotingBallotInput = this.renderVotingBallotInput.bind(this);
    this.renderBankCertificatetInput = this.renderBankCertificatetInput.bind(this);
  }

  componentWillMount() {
    this.setState({id: this.props.id});
    this.props.documents.forEach((doc) => {
      const newDocument = {
        file: doc.file,
        name: doc.name,
        slug: '',
        hasFile: doc.file !== '' ? true : false
      };
      if (doc.name === 'Cédula de identidad') {
        newDocument.slug = 'cedula';
        this.setState({ci: newDocument});
      }
      if (doc.name === 'Papeleta de votación') {
        newDocument.slug = 'papeleta';
        this.setState({votingBallot: newDocument});
      }
      if (doc.name === 'RUC') {
        newDocument.slug = 'ruc';
        this.setState({ruc: newDocument});
      }
      if (doc.name === 'Certificado bancario') {
        newDocument.slug = 'banco';
        this.setState({bankCertificate: newDocument});
      }
    });
  }

  onChangeCiFile(e) {
    this.setState({fileChanging: this.state.ci.slug});
    const ciDocument = this.state.ci;
    const file = e.target.files[0];
    const formData = new FormData();
    const fileName = `${this.props.customerRuc}_CI`;
    formData.append('file', file);
    this.props.upload('documentos', formData, fileName).then((value) => {
      if (value.type === UPLOAD_SUCCESS) {
        if (value.payload.response.Location) {
          const docPath = value.payload.response.Location;
          if (docPath === false) { ciDocument.file = ''; } else { ciDocument.file = docPath; }
          this.setState({ci: ciDocument});
          const documentsArray = [];
          documentsArray.push(this.state.ci);
          documentsArray.push(this.state.ruc);
          documentsArray.push(this.state.votingBallot);
          documentsArray.push(this.state.bankCertificate);
          this.props.onChange();
          this.props.onDocumentsChange(documentsArray);
        }
      }
    });
  }

  onChangeRucFile(e) {
    this.setState({fileChanging: this.state.ruc.slug});
    const rucDocument = this.state.ruc;
    const file = e.target.files[0];
    const formData = new FormData();
    const fileName = `${this.props.customerRuc}_RUC`;
    formData.append('file', file);
    this.props.upload('documentos', formData, fileName).then((value) => {
      if (value.type === UPLOAD_SUCCESS) {
        if (value.payload.response.Location) {
          const docPath = value.payload.response.Location;
          if (docPath === false) { rucDocument.file = ''; } else { rucDocument.file = docPath; }
          this.setState({ruc: rucDocument});
          const documentsArray = [];
          documentsArray.push(this.state.ci);
          documentsArray.push(this.state.ruc);
          documentsArray.push(this.state.votingBallot);
          documentsArray.push(this.state.bankCertificate);
          this.props.onChange();
          this.props.onDocumentsChange(documentsArray);
        }
      }
    });
  }

  onChangeBankFile(e) {
    this.setState({fileChanging: this.state.bankCertificate.slug});
    const bankDocument = this.state.bankCertificate;
    const file = e.target.files[0];
    const formData = new FormData();
    const fileName = `${this.props.customerRuc}_Certificado_Bancario`;
    formData.append('file', file);
    this.props.upload('documentos', formData, fileName).then((value) => {
      if (value.type === UPLOAD_SUCCESS) {
        if (value.payload.response.Location) {
          const docPath = value.payload.response.Location;
          if (docPath === false) { bankDocument.file = ''; } else { bankDocument.file = docPath; }
          this.setState({bankCertificate: bankDocument});
          const documentsArray = [];
          documentsArray.push(this.state.ci);
          documentsArray.push(this.state.ruc);
          documentsArray.push(this.state.votingBallot);
          documentsArray.push(this.state.bankCertificate);
          this.props.onChange();
          this.props.onDocumentsChange(documentsArray);
        }
      }
    });
  }

  onChangeVotingFile(e) {
    this.setState({fileChanging: this.state.votingBallot.slug});
    const votingDocument = this.state.votingBallot;
    const file = e.target.files[0];
    const formData = new FormData();
    const fileName = `${this.props.customerRuc}_Papeleta_de_votacion`;
    formData.append('file', file);
    this.props.upload('documentos', formData, fileName).then((value) => {
      if (value.type === UPLOAD_SUCCESS) {
        if (value.payload.response.Location) {
          const docPath = value.payload.response.Location;
          if (docPath === false) { votingDocument.file = ''; } else { votingDocument.file = docPath; }
          this.setState({votingBallot: votingDocument});
          const documentsArray = [];
          documentsArray.push(this.state.ci);
          documentsArray.push(this.state.ruc);
          documentsArray.push(this.state.votingBallot);
          documentsArray.push(this.state.bankCertificate);
          this.props.onChange();
          this.props.onDocumentsChange(documentsArray);
        }
      }
    });
  }

  renderLabel(buttonText, key) {
    const {classes} = this.props;
    const htmlFor = `contained-button-file${key}`;
    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <label htmlFor={htmlFor}>
        <Button component="span" color="primary" className={classes.uploadButton}>
          {buttonText}
        </Button>
      </label>
    );
  }

  renderCiInput() {
    const {classes, isLoadingDocument} = this.props;
    const {ci, id} = this.state;
    if (isLoadingDocument && this.state.fileChanging === ci.slug) { return (<CircularProgress size={50} thickness={7} />); }
    if (ci.file !== '') {
      return (
        <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {
            ci.hasFile ?
              <a href={`${apiHost}customers/${id}/${ci.slug}`} download>
                <Tooltip title="Descargar">
                  <DownloadIcon style={{cursor: 'pointer', color: 'grey', marginRight: 15}} onClick={() => {}} />
                </Tooltip>
              </a>
              :
              ''
          }
          <input
            accept="*"
            className={classes.hide}
            multiple
            id={`contained-button-file${ci.slug}`}
            type="file"
            onChange={this.onChangeCiFile}
          />
          {this.renderLabel('actualizar documento', ci.slug)}
        </div>
      );
    }
    return (
      <div>
        <input
          accept="*"
          className={classes.hide}
          multiple
          id={`contained-button-file${ci.slug}`}
          type="file"
          onChange={this.onChangeCiFile}
        />
        {this.renderLabel('subir documento', ci.slug)}
      </div>
    );
  }

  renderRucInput() {
    const {classes, isLoadingDocument} = this.props;
    const {ruc, id} = this.state;
    if (isLoadingDocument && this.state.fileChanging === ruc.slug) { return (<CircularProgress size={50} thickness={7} />); }
    if (ruc.file !== '') {
      return (
        <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {
            ruc.hasFile ?
              <a href={`${apiHost}customers/${id}/${ruc.slug}`} download>
                <Tooltip title="Descargar">
                  <DownloadIcon style={{cursor: 'pointer', color: 'grey', marginRight: 15}} onClick={() => {}} />
                </Tooltip>
              </a>
              :
              ''
          }
          <input
            accept="*"
            className={classes.hide}
            multiple
            id={`contained-button-file${ruc.slug}`}
            type="file"
            onChange={this.onChangeRucFile}
          />
          {this.renderLabel('actualizar documento', ruc.slug)}
        </div>
      );
    }
    return (
      <div>
        <input
          accept="*"
          className={classes.hide}
          multiple
          id={`contained-button-file${ruc.slug}`}
          type="file"
          onChange={this.onChangeRucFile}
        />
        {this.renderLabel('subir documento', ruc.slug)}
      </div>
    );
  }

  renderVotingBallotInput() {
    const {classes, isLoadingDocument} = this.props;
    const {votingBallot, id} = this.state;
    if (isLoadingDocument && this.state.fileChanging === votingBallot.slug) { return (<CircularProgress size={50} thickness={7} />); }
    if (votingBallot.file !== '') {
      return (
        <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {
            votingBallot.hasFile ?
              <a href={`${apiHost}customers/${id}/${votingBallot.slug}`} download>
                <Tooltip title="Descargar">
                  <DownloadIcon style={{cursor: 'pointer', color: 'grey', marginRight: 15}} onClick={() => {}} />
                </Tooltip>
              </a>
              :
              ''
          }
          <input
            accept="*"
            className={classes.hide}
            multiple
            id={`contained-button-file${votingBallot.slug}`}
            type="file"
            onChange={this.onChangeVotingFile}
          />
          {this.renderLabel('actualizar documento', votingBallot.slug)}
        </div>
      );
    }
    return (
      <div>
        <input
          accept="*"
          className={classes.hide}
          multiple
          id={`contained-button-file${votingBallot.slug}`}
          type="file"
          onChange={this.onChangeVotingFile}
        />
        {this.renderLabel('subir documento', votingBallot.slug)}
      </div>
    );
  }

  renderBankCertificatetInput() {
    const {classes, isLoadingDocument} = this.props;
    const {bankCertificate, id} = this.state;
    if (isLoadingDocument && this.state.fileChanging === bankCertificate.slug) { return (<CircularProgress size={50} thickness={7} />); }
    if (bankCertificate.file !== '') {
      return (
        <div style={{display: 'inline-flex', justifyContent: 'center', alignItems: 'center', height: 50}}>
          {
            bankCertificate.hasFile ?
              <a href={`${apiHost}customers/${id}/${bankCertificate.slug}`} download>
                <Tooltip title="Descargar">
                  <DownloadIcon style={{cursor: 'pointer', color: 'grey', marginRight: 15}} onClick={() => {}} />
                </Tooltip>
              </a>
              :
              ''
          }
          <input
            accept="*"
            className={classes.hide}
            multiple
            id={`contained-button-file${bankCertificate.slug}`}
            type="file"
            onChange={this.onChangeBankFile}
          />
          {this.renderLabel('actualizar documento', bankCertificate.slug)}
        </div>
      );
    }
    return (
      <div>
        <input
          accept="*"
          className={classes.hide}
          multiple
          id={`contained-button-file${bankCertificate.slug}`}
          type="file"
          onChange={this.onChangeBankFile}
        />
        {this.renderLabel('subir documento', bankCertificate.slug)}
      </div>
    );
  }

  render() {
    const {classes} = this.props;
    const {ci, votingBallot, bankCertificate, ruc} = this.state;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Documentos
        </Typography>
        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>

        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} className={classes.inputWrapper}>
              <div>
                <Typography id="form-dialog-title" className={classes.labelInputFile}>{ci.name} </Typography>
                {this.renderCiInput()}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputWrapper}>
              <div>
                <Typography id="form-dialog-title" className={classes.labelInputFile}>{votingBallot.name} </Typography>
                {this.renderVotingBallotInput()}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputWrapper}>
              <div>
                <Typography id="form-dialog-title" className={classes.labelInputFile}>{bankCertificate.name} </Typography>
                {this.renderBankCertificatetInput()}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputWrapper}>
              <div>
                <Typography id="form-dialog-title" className={classes.labelInputFile}>{ruc.name} </Typography>
                {this.renderRucInput()}
              </div>
            </Grid>
          </Grid>
        </form>

      </Paper>

    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  customerRuc: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  isLoadingDocument: PropTypes.bool.isRequired,
  onDocumentsChange: PropTypes.func.isRequired
};


export default (withStyles(styles)(Documents));
