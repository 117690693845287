
//export const apiHost = 'https://micontadorback.herokuapp.com/'; // prod
// export const apiHost = 'https://mi-contador-back.herokuapp.com/'; // develop
// export const apiHost = 'http://localhost:5000/'; // develop
export const apiHost = process.env.REACT_APP_API_HOST;
// console.log('process.env.REACT_APP_API_HOST: ', process.env.REACT_APP_API_HOST);

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isPhone(phone) {
  const re = /^\d*$/;
  return re.test(String(phone));
}

export function isText(text) {
  const re = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/;
  return re.test(String(text));
}

export function isInt(text) {
  const re = /^[0-9]*$/;
  return re.test(String(text));
}

export function isNumber(number) {
  if (number) {
    if (number === '') {
      return true;
    }
    const re = /^\d*(\.\d{1})?\d{0,1}$/;
    const test = re.test(String(number));
    return test;
  }
  return true;
}

export const dateFormat = 'YYYY-MM-DD';

export function copyObject(obj) {
  return (JSON.parse(JSON.stringify(obj)));
}

export function axiosMaker(method, url, data = null, token) {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  };
  if (token) {
    headers['x-access-token'] = sessionStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers
  };
  if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data !== null) {
    options.data = data;
  }
  return options;
}

export function axiosMakerLoginDora(method, data = null) {
  console.log('data: ', data);

  const params = {
    usuario: 'ecuadordora10@practisis.com',
    pass: '123456',
    apikey: 'xmai2skjdl912039kshqpdwwir21242',
    url: 'https://app.micontador.ec'
  };
  const options = {
    headers: null,
    method,
    url: 'https://www.practisis.online/triggerLoginOutside.php',
    params
  };

  return options;
}

export function axiosMakerChangePassword(method, url, data = null, query) {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  };
  const params = {
    token: query.token
  };
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
    params
  };
  if ((method === 'POST' || method === 'PUT' || method === 'PATCH') && data !== null) {
    options.data = data;
  }
  return options;
}

export function axiosMakerFilters(method, url, data = null, token) {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  };
  let params = {};
  if (data) {
    params = {
      searchText: data.searchText,
      page: data.page,
      limit: data.limit,
      sortBy: data.sortBy,
      order: data.order,
      filter: data.filter,
      active: data.active
    };
  }
  if (token) {
    headers['x-access-token'] = sessionStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
    params
  };
  return options;
}

export function axiosFormData(method, url, formData, token) {
  const headers = {
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-cache'
  };
  if (token) {
    headers['x-access-token'] = sessionStorage.token;
  }
  const options = {
    async: true,
    crossDomain: true,
    method,
    url: apiHost + url,
    headers,
    data: formData
  };
  return options;
}

export const errorMessage = (error) => {
  // eslint-disable-next-line no-console
  console.log('error: ', error);
  if (error) {
    const {status, data} = error;
    switch (status) {
    case 400:
      if (data.errors) {
        if (data.errors.length > 0) {
          return data.errors.map(obj => obj.message).join(', ');
        }
      }
      if (data.errors.message) {
        return data.errors.message;
      }
      return 'Ha ocurrido un error';
    case 401:
      return 'No has iniciado sesión';
    case 403:
      return 'No tienes permiso para acceder a este recurso';
    case 404:
      return 'No se ha encontrado el recurso';
    case 405:
      return 'No existe el recurso';
    default:
      return 'Ha ocurrido un error';
    }
  }
  return 'Ha ocurrido un error';
};
