const styles = theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 500
  },
  iconContainer: {
    minWidth: 120
  },
  select: {
    paddingRight: 25
  },
  button: {
    background: 'transparent',
    boxShadow: 'none',
    color: '#8CA0B3',
    fontSize: 14,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  title: {
    color: '#697B8C',
    fontWeight: 700
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  spacer: {
    flexGrow: 1
  },
  rowText: {
    color: '#8CA0B3',
    fontSize: 14
  },
  actions: {
    display: 'inline-flex',
    justifyContent: 'cemter',
    alignItems: 'center',
    paddingRight: 20
  },
  serarchInput: {
    marginLeft: 10
  }
});

export default styles;
