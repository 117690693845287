
import * as customersActions from '../actions/types/historyReport';

const initialState = {
  dashboardHistory: [],
  message: '',
  hasError: false
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {
    dashboardHistory
  } = action.payload;

  switch (action.type) {
  case customersActions.GET_DASHBOARD_HISTORY_EMAILS_SENDING:
    return {...state, sending: true, response: false, message: '', error: null};
  case customersActions.GET_DASHBOARD_HISTORY_EMAILS_SUCCESS:
    return {...state, sending: false, response: true, error: null, dashboardHistory};
  case customersActions.GET_DASHBOARD_HISTORY_EMAILS_FAILED:
    return {...state, sending: false, response: true};
  case customersActions.RESEND_DASHBOARD_HISTORY_EMAILS_SENDING:
    return {...state, sending: true, response: false, message: '', error: null, hasError: false};
  case customersActions.RESEND_DASHBOARD_HISTORY_EMAILS_SUCCESS:
    return {...state, sending: false, response: true, error: null, message: 'Enviado con éxito'};
  case customersActions.RESEND_DASHBOARD_HISTORY_EMAILS_FAILED:
    return {...state, sending: false, response: true, message: 'Error al reenviar', hasError: true};
  default:
    return state;
  }
};
