import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isText} from '../../../utils/utils';
import {styles} from './styles';

export class ContactPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      email: '',
      cellPhone: ''
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeCellphone = this.onChangeCellphone.bind(this);
    this.handleUpdateContact = this.handleUpdateContact.bind(this);
  }

  componentWillMount() {
    this.setState({contact: this.props.contact, email: this.props.email, cellPhone: this.props.cellPhone});
  }

  onChangeName(e) {
    if (isText(e.target.value)) {
      const newContact = this.state.contact;
      newContact.name = e.target.value;
      this.setState({contact: newContact});
      this.handleUpdateContact();
    }
  }

  onChangeLastName(e) {
    const newContact = this.state.contact;
    newContact.lastName = e.target.value;
    this.setState({contact: newContact});
    this.handleUpdateContact();
  }

  onChangeEmail(e) {
    this.setState({email: e.target.value});
    // this.handleUpdateContact();
  }

  onChangeCellphone(e) {
    this.setState({cellPhone: e.target.value});
    this.props.onChangeCellPhone(e);
    // this.handleUpdateContact();
  }


  handleUpdateContact() {
    const {contact} = this.state;
    this.props.updateContact(contact);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    const {contact, email, cellPhone} = this.state;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Información de contacto
        </Typography>
        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={contact.name}
                onChange={this.onChangeName}
                fullWidth
                label="Nombres"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={contact.lastName}
                autoFocus
                onChange={(e) => { this.onChangeLastName(e); }}
                fullWidth
                label="Apellidos"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                disabled
                value={email}
                autoFocus
                onChange={(e) => { this.onChangeEmail(e); }}
                fullWidth
                label="Email"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={cellPhone}
                autoFocus
                onChange={(e) => { this.onChangeCellphone(e); }}
                fullWidth
                label="Celular"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>

    );
  }
}

ContactPerson.propTypes = {
  classes: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  cellPhone: PropTypes.array.isRequired,
  email: PropTypes.array.isRequired,
  updateContact: PropTypes.func.isRequired,
  onChangeCellPhone: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(ContactPerson));

