/* eslint-disable no-unneeded-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from 'react-redux';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isPhone, isText, isInt} from '../../utils/utils';
import CustomSnackbar from '../common/customSnackbar';
import ContactPerson from './contact';
import Childrens from './childrens';
import ExpensesEstimation from './expensesEstimation';
import Income from './income';
import Accounts from './accounts';
import BankAccount from './bankAccount';
import Documents from './documents';
import InformationCustomer from './informationCustomer';
import Taxes from './taxes';
import {styles} from './styles';
import {Link} from '@material-ui/core';

export class Enterprise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ruc: '',
      name: '',
      lastName: '',
      cellPhone: '',
      email: '',
      city: '',
      phone: '',
      disability: false,
      address: '',
      birthDate: '',
      observations: '',
      createdDate: '',
      plan: '',
      accountantId: '',
      isActive: '',
      monthlyPayment: '',
      employees: '',
      accountants: [],
      changed: false,
      documentsArray: [],
      tokenDora: '',
      originReport: '',
      open: false,
      folderLink: '',
      comments: '',
      signatureExpiration: '',
      nominationExpiration: '',
    };
    this.renderSnack = this.renderSnack.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleGoBackConfirmation = this.handleGoBackConfirmation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDocumentsChange = this.onDocumentsChange.bind(this);
    this.onChangeAccountantId = this.onChangeAccountantId.bind(this);
    this.onChangeIsActive = this.onChangeIsActive.bind(this);
    this.onChangePlan = this.onChangePlan.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeRuc = this.onChangeRuc.bind(this);
    this.onChangeTokenDora = this.onChangeTokenDora.bind(this);
    this.onChangeFolderLink = this.onChangeFolderLink.bind(this);
    this.onChangeOriginReport = this.onChangeOriginReport.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeCellPhone = this.onChangeCellPhone.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeDisability = this.onChangeDisability.bind(this);
    this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
    this.onChangeObservations = this.onChangeObservations.bind(this);
    this.onChangeEmployees = this.onChangeEmployees.bind(this);
    this.onChangeMonthlyPayment = this.onChangeMonthlyPayment.bind(this);
    this.onSaveChangesAndGoBack = this.onSaveChangesAndGoBack.bind(this);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.renderPlanInformation = this.renderPlanInformation.bind(this);
    this.renderAccountantToolBar = this.renderAccountantToolBar.bind(this);
    this.disableIsActive = this.disableIsActive.bind(this);
    this.onchangeSignatureExpirationDate = this.onchangeSignatureExpirationDate.bind(this);
    this.onchangeNominationExpirationDate = this.onchangeNominationExpirationDate.bind(this);
  }

  componentWillMount() {
    const {customer} = this.props;
    this.props.onCleanPatchCustomer();
    this.setState({
      ruc: customer.ruc,
      name: customer.name,
      lastName: customer.lastName,
      cellPhone: customer.cellPhone,
      email: customer.email,
      city: customer.city,
      phone: customer.phone,
      disability: customer.disability,
      address: customer.address,
      birthDate:
        customer.birthDate === null
          ? customer.birthDate
          : customer.birthDate.substring(0, 10),
      accountantId: customer.accountantId,
      observations: customer.observations,
      plan: customer.plan,
      createdDate: customer.createdDate,
      isActive: customer.isActive,
      monthlyPayment: customer.monthlyPayment,
      employees: customer.employees,
      tokenDora: customer.tokenDora,
      originReport: customer.originReport,
      folderLink: customer.folderLink,
      comments: customer.comments,
      signatureExpiration: customer.signatureExpirationDate,
      nominationExpiration: customer.nominationExpirationDate
    });
    console.log('fecha cumpleaños: ', this.state.birthDate);
    this.props.accountants.forEach((accountant) => {
      const newAccountant = {
        name: accountant.name,
        id: accountant.id
      };
      this.state.accountants.push(newAccountant);
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {}

  onChange() {
    this.setState({changed: true});
  }

  onDocumentsChange(documentsArray) {
    this.setState({documentsArray});
    this.props.onDocumentsChange(documentsArray);
  }

  onChangeEmployees(e) {
    const employees = e.target.value;
    if (isInt(employees)) {
      this.setState({employees, changed: true});
      this.props.onEmployeesChange(e.target.value);
    }
  }

  onChangeIsActive(e) {
    this.setState({isActive: e.target.value, changed: true});
    this.props.onIsActiveChange(e.target.value);
  }

  onChangeAccountantId(e) {
    this.setState({accountantId: e.target.value, changed: true});
    this.props.onAccountantIdChange(e.target.value);
  }

  onchangeSignatureExpirationDate(date) {
    const signatureExpiration = moment(date).format(dateFormat);
    this.setState({signatureExpiration, changed: true});
    this.props.onchangeSignatureExpirationDate(signatureExpiration);
  }

  onchangeNominationExpirationDate(date) {
    const nominationExpiration = moment(date).format(dateFormat);
    this.setState({nominationExpiration, changed: true});
    this.props.onchangeNominationExpirationDate(nominationExpiration);
  }

  onChangeMonthlyPayment(e) {
    this.setState({monthlyPayment: e.target.value, changed: true});
    this.props.onMonthlyPaymentChange(e.target.value);
  }

  onChangePlan(e) {
    this.setState({plan: e.target.value, changed: true});
    this.props.onPlanChange(e.target.value);
  }

  onChangeName(e) {
    const name = e.target.value;
    if (isText(name)) {
      this.setState({name, changed: true});
    }
    this.props.onNameChange(name);
  }

  onChangeLastName(e) {
    const lastName = e.target.value;
    if (isText(lastName)) {
      this.setState({lastName, changed: true});
    }
    this.props.onLastNameChange(lastName);
  }

  onChangeRuc(e) {
    this.setState({ruc: e.target.value, changed: true});
    this.props.onRucChange(e.target.value);
  }

  onChangeAddress(e) {
    this.setState({address: e.target.value, changed: true});
    this.props.onAddressChange(e.target.value);
  }

  onChangePhone(e) {
    const phone = e.target.value;
    if (isPhone(phone) && phone.length <= 7) {
      this.setState({phone, changed: true});
      this.props.onPhoneChange(phone);
    }
  }

  onChangeCellPhone(e) {
    const cellPhone = e.target.value;
    if (isPhone(cellPhone) && cellPhone.length <= 10) {
      this.setState({cellPhone, changed: true});
      this.props.onCellPhoneChange(cellPhone);
    }
  }

  onChangeCity(e) {
    this.setState({city: e.target.value, changed: true});
    this.props.onCityChange(e.target.value);
  }

  onChangeDisability(e) {
    this.setState({disability: e.target.value, changed: true});
    this.props.onDisabilityChange(e.target.value);
  }

  onChangeBirthDate(date) {
    const birthdate = moment(date).format(dateFormat);
    this.setState({birthDate: birthdate, changed: true});
    this.props.onBirthdateChange(birthdate);
  }

  onChangeObservations(e) {
    this.setState({observations: e.target.value, changed: true});
    this.props.onObservationsChange(e.target.value);
  }

  onChangeTokenDora(e) {
    this.setState({tokenDora: e.target.value, changed: true});
    this.props.onTokenDoraChange(e.target.value);
  }

  onChangeFolderLink(e) {
    this.setState({folderLink: e.target.value, changed: true});
    this.props.onFolderLinkChange(e.target.value);
  }

  onChangeOriginReport(e) {
    this.setState({originReport: e.target.value, changed: true});
    this.props.onOriginReportChange(e.target.value);
  }

  onSaveChangesAndGoBack() {
    this.setState({changed: false, open: false});
    if (this.state.documentsArray.length >= 0) {
      this.props.customer.documents.forEach((oldDocument) => {
        this.state.documentsArray.forEach((newDocument) => {
          if (oldDocument.name === newDocument.name) {
            if (oldDocument.file !== '') {
              if (oldDocument.file !== newDocument.file) {
                this.props.deleteUploaded(oldDocument.file);
              }
            }
          }
        });
      });
    }

    this.props.handleUpdateCustomer();
    this.props.onCleanPatchCustomer();
    this.props.handleGoBack();
  }

  onSaveChanges() {
    this.setState({changed: false, open: false});
    if (this.state.documentsArray.length >= 0) {
      this.props.customer.documents.forEach((oldDocument) => {
        this.state.documentsArray.forEach((newDocument) => {
          if (oldDocument.name === newDocument.name) {
            if (oldDocument.file !== '') {
              if (oldDocument.file !== newDocument.file) {
                this.props.deleteUploaded(oldDocument.file);
              }
            }
          }
        });
      });
    }

    this.props.handleUpdateCustomer();
    this.props.onCleanPatchCustomer();
  }

  handleGoBack() {
    if (!this.state.changed) {
      this.props.handleGoBack();
    } else {
      this.setState({open: true});
    }
  }

  handleGoBackConfirmation() {
    if (this.state.documentsArray.length >= 0) {
      this.props.customer.documents.forEach((oldDocument) => {
        this.state.documentsArray.forEach((newDocument) => {
          if (oldDocument.name === newDocument.name) {
            if (oldDocument.file !== newDocument.file) {
              this.props.deleteUploaded(newDocument.file);
            }
          }
        });
      });
    }
    this.props.handleGoBack();
  }

  handleClose = () => {
    this.setState({open: false});
  };

  disableIsActive() {
    if (this.state.accountantId.length === 0 || !this.props.isAdmin) {
      return true;
    }
    return false;
  }

  renderPlanInformation() {
    const {classes, isAdmin, isAccountant} = this.props;
    const isActiveSection = isAdmin || isAccountant;
    console.log("isAdmin: ", isAdmin);
    console.log("isAccountant: ", isAccountant);
    console.log("validation: ", !isAdmin && isAccountant);
    console.log("isActiveSection: ", isActiveSection);
    return (
      <>
        <Paper className={classes.FirstFormPaper}>
          <Typography className={classes.title}>Información del plan</Typography>

          <div className={classes.wrapDivider} align="center">
            <Divider className={classes.divider} />
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <TextField
                  disabled
                  value={this.state.createdDate.substring(0, 10)}
                  autoFocus
                  fullWidth
                  label="Fecha de registro"
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <FormControl className={classes.select}>
                  <InputLabel className={classes.labelInput}>
                  Plan contratado
                  </InputLabel>
                  <Select
                    disabled={!isActiveSection}
                    value={this.state.plan}
                    onChange={this.onChangePlan}
                    className={classes.textInput}
                    inputProps={{
                      classes: {
                        root: classes.textInput
                      }
                    }}
                  >
                    <MenuItem value="Básico" style={{color: '#697B8C'}}>
                    Básico
                    </MenuItem>
                    <MenuItem value="Profesional" style={{color: '#697B8C'}}>
                    Profesional
                    </MenuItem>
                    <MenuItem value="Empresarial" style={{color: '#697B8C'}}>
                    Empresarial
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <TextField
                  disabled={!isActiveSection}
                  value={this.state.monthlyPayment}
                  onChange={this.onChangeMonthlyPayment}
                  fullWidth
                  label="Monto a cobrar"
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    classes: {
                      input: classes.textInput
                    }
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <FormControl className={classes.select}>
                  <InputLabel className={classes.labelInput}>
                  Contador Asignado
                  </InputLabel>
                  <Select
                    value={this.state.accountantId}
                    disabled={!isActiveSection}
                    onChange={this.onChangeAccountantId}
                    className={classes.textInput}
                    inputProps={{
                      classes: {
                        root: classes.textInput,
                        disabled: classes.textInput
                      }
                    }}
                  >
                    {this.state.accountants.map((accountant, key) => {
                      return (
                        <MenuItem value={accountant.id} key={key}>
                          {accountant.name}{' '}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <FormControl className={classes.select}>
                  <InputLabel className={classes.labelInput}>
                  Estado del Cliente
                  </InputLabel>
                  <Select
                    value={this.state.isActive}
                    disabled={!isActiveSection}
                    onChange={this.onChangeIsActive}
                    className={classes.textInput}
                    inputProps={{
                      classes: {
                        root: classes.textInput,
                        disabled: classes.textInput
                      }
                    }}
                  >
                    <MenuItem value>Activo</MenuItem>
                    <MenuItem value={false}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <FormControl className={classes.select}>
                  <InputLabel className={classes.labelInput}>
                  Origen de datos
                  </InputLabel>
                  <Select
                    value={this.state.originReport}
                    disabled={!isActiveSection}
                    onChange={this.onChangeOriginReport}
                    className={classes.textInput}
                    inputProps={{
                      classes: {
                        root: classes.textInput,
                        disabled: classes.textInput
                      }
                    }}
                  >
                    <MenuItem value="Dora">Dora</MenuItem>
                    <MenuItem value="Formulario">Formulario</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {this.state.originReport == 'Dora' && (
                <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                  <TextField
                    value={this.state.tokenDora}
                    autoFocus
                    onChange={this.onChangeTokenDora}
                    disabled={this.disableIsActive()}
                    fullWidth
                    label="Token de Dora"
                    className={classes.input}
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      }
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </Grid>
              )}
            
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <TextField
                  value={this.state.folderLink}
                  autoFocus
                  disabled={!isActiveSection}
                  onChange={this.onChangeFolderLink}
                  fullWidth
                  label="Link de carpeta compartida"
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput}}}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
                {this.state.folderLink ? <Link target="_blank" href={this.state.folderLink}>Link de carpeta compartida</Link> : ''}
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Paper className={classes.FormPaper}>
          <Typography className={classes.title}>
            Información de firma electrónica
          </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <InlineDatePicker
                  value={this.state.signatureExpiration || null}
                  fullWidth
                  clearable
                  onChange={(date) => {
                  // const signatureExpiration = moment(date).format(dateFormat);
                    this.onchangeSignatureExpirationDate(date);
                  }}
                  label="Información de firma electrónica"
                  format={dateFormat}
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput}}}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>     
            </Grid>     
          </form>
      
        </Paper>
        <Paper className={classes.FormPaper}>
          <Typography className={classes.title}>
            Información de vencimiento de nombramientos
          </Typography>
          <div className={classes.wrapDivider} align="center" >
            <Divider className={classes.divider} />
          </div>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <InlineDatePicker
                  value={this.state.nominationExpiration || null}
                  fullWidth
                  clearable
                  onChange={(date) => {
                  // const signatureExpiration = moment(date).format(dateFormat);
                    this.onchangeNominationExpirationDate(date);
                  }}
                  label="Fecha de Vencimiento"
                  format={dateFormat}
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput}}}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>     
            </Grid>     
          </form>
      
        </Paper>
      </>
    );
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      return (
        <CustomSnackbar
          open
          onClose={() => {}}
          message={message}
          variant={error === null ? 'success' : 'error'}
        />
      );
    }
    return '';
  }

  renderAccountantToolBar() {
    const {classes, isAdmin, isAccountant} = this.props;
    if (isAdmin || isAccountant) {
      return (
        <Toolbar style={{padding: 0}}>
          <Button
            variant="contained"
            className={classes.backddButton}
            onClick={this.handleGoBack}
          >
            Clientes
          </Button>
          <div style={{width: 10, marginLeft: 5, marginRight: 5}}>
            <Typography variant="subtitle1" color="secondary">
              /
            </Typography>
          </div>
          <Typography variant="h5" className={classes.titleAppBar}>
            Perfil
          </Typography>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              this.onSaveChanges();
            }}
            className={classes.button}
            align="center"
            disabled={
              !this.state.changed ||
              this.state.cellPhone.length < 10 ||
              this.state.name.length <= 0 ||
              this.state.ruc.length <= 0
            }
          >
            Actualizar
          </Button>
        </Toolbar>
      );
    }
    return (
      <Toolbar style={{paddingLeft: 20}}>
        <Typography variant="h5" className={classes.titleAppBar}>
          Perfil
        </Typography>
        <Button
          type="submit"
          variant="contained"
          className={classes.button}
          align="center"
          disabled={
            !this.state.changed ||
            this.state.cellPhone.length < 10 ||
            this.state.name.length <= 0 ||
            this.state.ruc.length <= 0
          }
          onClick={() => {
            this.onSaveChanges();
          }}
        >
          Guardar
        </Button>
      </Toolbar>
    );
  }

  render() {
    console.log(this.props.customer, 'this.props.customer');
    const {isLoading, classes} = this.props;
    return isLoading === true ? (
      <CircularProgress className={classes.progress} size={50} thickness={7} />
    ) : (
      <div className={classes.main}>
        <AppBar position="fixed" className={classes.appBar}>
          {this.renderAccountantToolBar()}
        </AppBar>
        <div>
          {this.renderPlanInformation()}

          <InformationCustomer
            customer={{
              ruc: this.state.ruc,
              name: this.state.name,
              lastName: this.state.lastName,
              birthDate: this.state.birthDate,
              email: this.state.email,
              cellPhone: this.state.cellPhone,
              phone: this.state.phone,
              disability: this.state.disability,
              address: this.state.address,
              city: this.state.city,
              employees: this.state.employees
            }}
            citys={this.props.citys}
            onChangeRuc={this.onChangeRuc}
            onChangeName={this.onChangeName}
            onChangeLastName={this.onChangeLastName}
            onChangeBirthDate={this.onChangeBirthDate}
            onChangeCellPhone={this.onChangeCellPhone}
            onChangePhone={this.onChangePhone}
            onChangeDisability={this.onChangeDisability}
            onChangeAddress={this.onChangeAddress}
            onChangeCity={this.onChangeCity}
            onChangeEmployees={this.onChangeEmployees}
          />

          <ContactPerson
            contact={this.props.customer.contact}
            updateContact={this.props.onContactChange}
            onChangeCellPhone={this.onChangeCellPhone}
            email={this.props.customer.email}
            cellPhone={this.props.customer.cellPhone}
            address={this.props.customer.address}
            onChange={this.onChange}
          />

          <Accounts
            accounts={this.props.customer.accounts}
            updateAccounts={this.props.onAccountsChange}
            entitys={this.props.entitys}
            onChange={this.onChange}
          />

          {/* <Childrens
            childrens={this.props.customer.childrens}
            updateChildrens={this.props.onChildrensChange}
            onChange={this.onChange}
          /> */}

          {/* <Income
            incomes={this.props.customer.income}
            isLoading={this.props.isLoading}
            updateIncomes={this.props.onIncomesChange}
            onChange={this.onChange}
          />

          <ExpensesEstimation
            expensesEstimation={this.props.customer.expensesEstimation}
            updateExpensesEstimation={this.props.onExpensesEstimationChange}
            onChange={this.onChange}
          /> */}

          <Taxes
            customerTaxes={this.props.customer.taxes}
            ruc={this.props.customer.ruc}
            taxes={this.props.taxes}
            updateTaxes={this.props.onTaxesChange}
            onChange={this.onChange}
            isCustomer={this.props.isCustomer}
          />

          {/* <BankAccount
            bankAccount={this.props.customer.bankAccount}
            entitys={this.props.bankEntitys}
            updateBankAccount={this.props.onBankAccountChange}
            onChange={this.onChange}
          /> */}

          {/* <Documents
            documents={this.props.customer.documents}
            onChange={this.onChange}
            onDocumentsChange={this.onDocumentsChange}
            haschanged={this.hasChanged}
            id={this.props.customer.id}
            upload={this.props.upload}
            isLoadingDocument={this.props.isLoadingDocument}
            customerRuc={this.props.customer.ruc}
          /> */}

          <Paper className={classes.FormPaper}>
            <Typography className={classes.title}>
            Observaciones
            </Typography>

            <div className={classes.wrapDivider} align="center">
              <Divider className={classes.divider} />
            </div>

            <form className={classes.container} noValidate autoComplete="off">
              <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12} className={classes.inputWrapper}>
                  <TextField
                    value={this.state.observations}
                    onChange={this.onChangeObservations}
                    fullWidth
                    label="Observaciones"
                    InputProps={{
                      classes: {
                        input: classes.textInput
                      }
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        root: classes.labelInput
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>

        <Paper className={classes.FormPaper}>
          <Typography className={classes.title}>
              Comentario sobre el tipo de servicio que busca
          </Typography>
          <Typography className={classes.subTitle}>
            {this.state.comments}
          </Typography>
          
        </Paper>

{/*         <Paper className={classes.FormPaper}>
          <Typography className={classes.title}>
              Información de firma electrónica
          </Typography>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                <InlineDatePicker
                  value={this.state.signatureExpiration || null}
                  fullWidth
                  clearable
                  onChange={(date) => {
                    // const signatureExpiration = moment(date).format(dateFormat);
                    this.onchangeSignatureExpirationDate(date);
                  }}
                  label="Información de firma electrónica"
                  format={dateFormat}
                  className={classes.input}
                  InputProps={{
                    classes: {
                      input: classes.textInput}}}
                  InputLabelProps={{
                    FormLabelClasses: {
                      root: classes.labelInput
                    }
                  }}
                />
              </Grid>
            </Grid>
          </form>
          
        </Paper> */}


        <Dialog open={this.state.open} onClose={this.handleClose}>
          <Typography id="form-dialog-title" className={classes.dialogTitle}>
            ¿Desea guardar los cambios antes de salir?{' '}
          </Typography>
          <div className={classes.wrapDivider} align="center">
            <Divider className={classes.divider} />
          </div>
          <DialogActions>
            <Button onClick={this.handleGoBackConfirmation} color="primary">
              NO
            </Button>
            <Button
              onClick={this.onSaveChangesAndGoBack}
              color="primary"
              disabled={
                !this.state.changed ||
                this.state.cellPhone.length < 10 ||
                this.state.name.length <= 0 ||
                this.state.ruc.length <= 0
              }
            >
              SI
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Enterprise.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  message: PropTypes.string.isRequired,
  error: PropTypes.object,
  customer: PropTypes.object.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  citys: PropTypes.array.isRequired,
  entitys: PropTypes.array.isRequired,
  taxes: PropTypes.array.isRequired,
  accountants: PropTypes.array.isRequired,
  bankEntitys: PropTypes.array.isRequired,
  civilStatus: PropTypes.array.isRequired,
  onRucChange: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onMonthlyPaymentChange: PropTypes.func.isRequired,
  onAccountantIdChange: PropTypes.func.isRequired,
  onIsActiveChange: PropTypes.func.isRequired,
  onPlanChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onEmployeesChange: PropTypes.func.isRequired,
  onLastNameChange: PropTypes.func.isRequired,
  onCleanPatchCustomer: PropTypes.func.isRequired,
  onDisabilityChange: PropTypes.func.isRequired,
  onObservationsChange: PropTypes.func.isRequired,
  onBirthdateChange: PropTypes.func.isRequired,
  onPhoneChange: PropTypes.func.isRequired,
  onCellPhoneChange: PropTypes.func.isRequired,
  onCityChange: PropTypes.func.isRequired,
  onSpouseChange: PropTypes.func.isRequired,
  onChildrensChange: PropTypes.func.isRequired,
  onIncomesChange: PropTypes.func.isRequired,
  onExpensesEstimationChange: PropTypes.func.isRequired,
  onTaxesChange: PropTypes.func.isRequired,
  onAccountsChange: PropTypes.func.isRequired,
  onBankAccountChange: PropTypes.func.isRequired,
  onDocumentsChange: PropTypes.func.isRequired,
  onTokenDoraChange: PropTypes.func.isRequired,
  onFolderLinkChange: PropTypes.func.isRequired,
  onContactChange: PropTypes.func.isRequired,
  onOriginReportChange: PropTypes.func.isRequired,
  handleUpdateCustomer: PropTypes.func.isRequired,
  deleteUploaded: PropTypes.func.isRequired,
  isLoadingDocument: PropTypes.bool.isRequired,
  upload: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAccountant: PropTypes.bool.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  onchangeSignatureExpirationDate: PropTypes.func.isRequired,
  onchangeNominationExpirationDate: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  const {customer} = state;
  return {
    message: customer.message,
    error: customer.error
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Enterprise));
