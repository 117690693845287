import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Link from '@material-ui/core/Link';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import styles from './styles';
import CustomSnackbar from '../common/customSnackbar';
import {getDashboardEmailsHistory, resendDashboardEmailsHistory} from '../../actions/customers';
import {isEmail} from '../../utils/utils';


const DashboardEmailsHistory = ({customerId, date}) => {
  const [open, setOpen] = useState(false);
  const classes = styles();
  const [viewForm, setViewForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const [hasValidEmailList, setHasValidEmailList] = useState(false);
  const [emailList, setEmailList] = useState(null);
  const {dashboardHistory, message, hasError} = useSelector(state => state.historyReport);
  console.log('dashboardHistory', dashboardHistory);

  useEffect(() => {
    dispatch(getDashboardEmailsHistory(customerId, date));
  }, []);

  const handleChange = (event) => {
    setEmailList(event.target.value);
    console.log('event.target.value', event.target.value);
    if (event.target.value) {
      const formattedEmail = event.target.value.split(',');
      formattedEmail.every((emailItem) => {
        console.log(emailItem);
        if (!isEmail(emailItem)) {
          setHasValidEmailList(false);
          return false;
        }
        setHasValidEmailList(true);
        return true;
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewForm(false);
  };

  const setOpenForm = (rowItem) => {
    setViewForm(true);
    setSelectedItem(rowItem);
  };

  const renderSendDate = (sendDate) => {
    return sendDate ? moment.utc(sendDate).format('YYYY-MM-DD') : '';
  };

  const resendAction = async () => {
    console.log('send');
    dispatch(resendDashboardEmailsHistory({customerId: selectedItem.customerId, emails: emailList, date: selectedItem.date, url: selectedItem.url}));
    setViewForm(false);
    setEmailList(null);
    handleClose();
    // onResendEmail();
  };

  const renderSnackBar = () => {
    if (message !== '') {
      return (
        <CustomSnackbar
          variant={hasError ? 'error' : 'success'}
          open
          message={message}
          className={classes.snackbar}
        />
      );
    }
    return '';
  };

  const BasicTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fecha de envío</TableCell>
              <TableCell>Reporte de</TableCell>
              <TableCell align="left">Emails</TableCell>
              <TableCell align="left">Reporte</TableCell>
              <TableCell align="left">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dashboardHistory.map(rowItem => (
              <TableRow key={rowItem.id}>
                <TableCell component="th" scope="row">
                  {renderSendDate(rowItem.sendDate)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {(rowItem.date)}
                </TableCell>
                <TableCell align="left">{rowItem.emails.join(', ')}</TableCell>
                <TableCell align="left">   
                  <Link href={rowItem.url} target="_blank">
                    Link
                  </Link>
                </TableCell>
                <TableCell align="left">   
                  <Button onClick={() => setOpenForm(rowItem)} color="primary" autoFocus>
            Reenviar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderForm = () => {
    return (
      <div className={classes.root}>
        <TextField
          id="emailList"
          className={classes.textField}
          label="Correos"
          value={emailList}
          onChange={handleChange}
          placeholder="email1@email.com,email2@gmail.com"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className={classes.actionContainer}>
          <br />
          <Button onClick={resendAction} disabled={!hasValidEmailList} variant="outlined" color="primary" > 
            Enviar
          </Button>
          <br />
        </div>
      </div>);
  };


  return (
    <div>
      {renderSnackBar()}
      {dashboardHistory.length > 0 && (
        <Button color="primary" onClick={handleClickOpen} className={classes.descriptionButton}>
        Historial
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">Histórico de envío
        </DialogTitle>
        <DialogContent>
          {viewForm ? renderForm() : <BasicTable />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


DashboardEmailsHistory.propTypes = {
  customerId: PropTypes.string,
  date: PropTypes.string

};

export default DashboardEmailsHistory;
  
