import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';
import {changePassword, activePassword} from '../../actions/security';
import {getCustomer} from '../../actions/customers';
import CustomSnackbar from '../common/customSnackbar';
import logo from '../../assets/proserint_logo.png';
import styles from './styles';


export class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmation: '',
      token: '',
      activation: false
    };
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onConfirmationChange = this.onConfirmationChange.bind(this);
    this.renderSnack = this.renderSnack.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillMount() {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const activation = url.searchParams.get('activation');
    this.setState({token, activation});
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
  }

  onConfirmationChange(e) {
    this.setState({confirmation: e.target.value});
  }

  async onChangePassword() {
    const {password, confirmation, token, activation} = this.state;
    if (activation) {
      await this.props.activePassword(password, confirmation, token);
      await this.props.getCustomer(this.props.user.id);
      console.log('this.props.user.id: ', this.props.user.id);
      console.log('customer: ', this.props.customer);
      console.log('password: ', this.props.password);

      console.log('registra en dora');
      // const apiDoraRegister = `https://www.practisis.net/triggerRegistroOutside.php?email=${this.props.customer.email}&pass=${this.props.password}&apikey=xmai2skjdl912039kshqpdwwir21242&url=https://app.micontador.ec&repass=${this.props.password}&nombre=${this.props.customer.name}`;
      // window.open(apiDoraRegister, '_blank');

      window.location.replace('https://app.micontador.ec/login');
    } else {
      await this.props.changePassword(password, confirmation, token);
      if (!this.props.error) {
        window.location.replace('https://app.micontador.ec/login');
      }
    }
  }

  validate() {
    const {password, confirmation} = this.state;
    if (password !== '' && confirmation !== '') {
      return false;
    }
    return true;
  }

  renderSnack() {
    const {message, error} = this.props;
    if (message) {
      console.log('message: ', message);
      console.log('error: ', error);
      return (
        <div>
          <CustomSnackbar
            open
            onClose={() => {}}
            message={message}
            variant={error === null ? 'success' : 'error'}
          />
        </div>
      );
    }
    return '';
  }

  renderButton() {
    const {classes, isLoading} = this.props;
    if (!isLoading) {
      return (
        <Button
          type="submit"
          variant="contained"
          disabled={this.validate()}
          onClick={this.onChangePassword}
          className={classes.button}
        >
          CONFIRMAR
        </Button>
      );
    }
    return <CircularProgress className={classes.progress} />;
  }

  render() {
    const {classes} = this.props;
    const states = this.state;
    return (
      <div className={classes.main}>
        <Paper className={classes.paper}>
          <img src={logo} alt="logo" className={classes.logo} />

          <Typography gutterBottom style={{color: '#697B8C', fontSize: 22}}>
            Cambiar contraseña
          </Typography>

          <Divider className={classes.divider} />

          <Typography style={{color: '#8CA0B3', fontSize: 14}}>
            Ingrese su nueva contraseña
          </Typography>
          <form className={classes.form}>
            <TextField
              value={states.password}
              onChange={this.onPasswordChange}
              fullWidth
              type="password"
              label="Contraseña"
              className={classes.input}
            />
            <TextField
              value={states.confirmation}
              onChange={this.onConfirmationChange}
              fullWidth
              type="password"
              label="Confirme su contraseña"
              className={classes.input}
            />
            {this.renderButton()}
            {this.renderSnack()}
          </form>
        </Paper>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  customer: PropTypes.object,
  user: PropTypes.object,
  message: PropTypes.string,
  password: PropTypes.string.isRequired,
  activePassword: PropTypes.func,
  getCustomer: PropTypes.func,
  changePassword: PropTypes.func
};

const mapStateToProps = (state) => {
  const {user, customer} = state;
  return {
    isLoading: user.sending,
    error: user.error,
    user: user.user,
    customer: customer.customer,
    password: customer.password,
    message: user.message};
};

const mapDispatchToProps = {
  changePassword,
  activePassword,
  getCustomer
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePassword));
