import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {InlineDatePicker} from 'material-ui-pickers';
import {dateFormat, isText} from '../../../utils/utils';
import {styles} from './styles';

export class Spouse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spouse: {}
    };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeCi = this.onChangeCi.bind(this);
    this.onChangeCivilStatus = this.onChangeCivilStatus.bind(this);
    this.onChangeDisability = this.onChangeDisability.bind(this);
    this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
    this.handleUpdateSpouse = this.handleUpdateSpouse.bind(this);
  }

  componentWillMount() {
    this.setState({spouse: this.props.spouse});
  }

  onChangeName(e) {
    if (isText(e.target.value)) {
      const newSpouse = this.state.spouse;
      newSpouse.name = e.target.value;
      this.setState({spouse: newSpouse});
      this.handleUpdateSpouse();
    }
  }

  onChangeCi(e) {
    const newSpouse = this.state.spouse;
    newSpouse.ci = e.target.value;
    this.setState({spouse: newSpouse});
    this.handleUpdateSpouse();
  }

  onChangeCivilStatus(e) {
    const newSpouse = this.state.spouse;
    newSpouse.civilStatus = e.target.value;
    this.setState({spouse: newSpouse});
    this.handleUpdateSpouse();
  }

  onChangeDisability(e) {
    const newSpouse = this.state.spouse;
    newSpouse.disability = e.target.value;
    this.setState({spouse: newSpouse});
    this.handleUpdateSpouse();
  }

  onChangeBirthDate(date) {
    const birthdate = moment(date).format(dateFormat);
    const newSpouse = this.state.spouse;
    newSpouse.birthDate = birthdate;
    this.setState({spouse: newSpouse});
    this.handleUpdateSpouse();
  }

  handleUpdateSpouse() {
    const {spouse} = this.state;
    this.props.updateSpouse(spouse);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    const {spouse} = this.state;
    return (
      <Paper className={classes.FormPaper}>
        <Typography className={classes.title}>
              Información del Cónyuge
        </Typography>
        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>
        <form className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={3} className={classes.gridContainer}>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={spouse.ci}
                autoFocus
                onChange={(e) => { this.onChangeCi(e); }}
                fullWidth
                label="Identificación"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <TextField
                value={spouse.name}
                onChange={this.onChangeName}
                fullWidth
                label="Nombre del cónyugue"
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <FormControl className={classes.select}>
                <InputLabel htmlFor="civilStatus" className={classes.labelInput}>Estado Civil</InputLabel>
                <Select
                  value={spouse.civilStatus}
                  onChange={(e) => { this.onChangeCivilStatus(e); }}
                  className={classes.textInput}
                >
                  {
                    this.props.civilStatus.map((status, key) => {
                      return (
                        <MenuItem value={status.name} key={key}>{status.name}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <InlineDatePicker
                value={spouse.birthDate !== null ? spouse.birthDate.substring(0, 10) : null}
                fullWidth
                clearable
                onChange={(e) => { this.onChangeBirthDate(e); }}
                label="Fecha de nacimiento"
                format={dateFormat}
                className={classes.input}
                InputProps={{
                  classes: {
                    input: classes.textInput}}}
                InputLabelProps={{
                  FormLabelClasses: {
                    root: classes.labelInput
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
              <FormControl className={classes.select}>
                <InputLabel htmlFor="disability" className={classes.labelInput}>¿Tiene discapacidad?</InputLabel>
                <Select
                  value={spouse.disability}
                  onChange={(e) => { this.onChangeDisability(e); }}
                  className={classes.textInput}
                >
                  <MenuItem value>Si</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Paper>

    );
  }
}

Spouse.propTypes = {
  classes: PropTypes.object.isRequired,
  spouse: PropTypes.object.isRequired,
  civilStatus: PropTypes.array.isRequired,
  updateSpouse: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(Spouse));

