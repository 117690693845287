import axios from 'axios';
import * as customerActions from './types/customer';
import * as historyReportActions from './types/historyReport';
import {axiosMaker, errorMessage, axiosMakerFilters, axiosMakerLoginDora} from '../utils/utils';
import store from '../store';

export const getCustomers = (searchText, page, rowsPerPage, orderBy, order, active = true) => async (dispatch) => {
  dispatch({
    type: customerActions.GET_CUSTOMERS_SENDING,
    payload: {searchText, page, rowsPerPage, orderBy, order}
  });
  try {
    const data = {
      searchText,
      page,
      limit: rowsPerPage,
      sortBy: orderBy,
      order,
      active
    };
    const response = await axios(axiosMakerFilters('GET', 'customers', data, true));
    return dispatch({
      type: customerActions.GET_CUSTOMERS_SUCCESS,
      payload: {
        customers: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_CUSTOMERS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createCustomer = (data, plan) => async (dispatch) => {
  dispatch({
    type: customerActions.CREATE_CUSTOMER_SENDING,
    payload: {

    }
  });
  try {
    await axios(axiosMaker('POST', `customers/${plan}`, data, false));
    return dispatch({
      type: customerActions.CREATE_CUSTOMER_SUCCESS,
      payload: {
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.CREATE_CUSTOMER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const loginDora = data => async (dispatch) => {
  const respons = await axios(axiosMakerLoginDora('POST', data));
  return dispatch({
    type: customerActions.LOGIN_DORA,
    payload: {
    }
  });
};

export const patchCustomer = id => async (dispatch) => {
  dispatch({
    type: customerActions.EDIT_CUSTOMER_SENDING,
    payload: {
    }
  });
  try {
    const data = store.getState().customer.patchCustomer;
    await axios(axiosMaker('PATCH', `customers/${id}`, data, true));
    return dispatch({
      type: customerActions.EDIT_CUSTOMER_SUCCESS,
      payload: {
        patchCustomer: {}
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.EDIT_CUSTOMER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const cleanPatchCustomer = () => async (dispatch) => {
  return dispatch({
    type: customerActions.CLEAN_PATCHCUSTOMER,
    payload: {
      patchCustomer: {}
    }
  });
};

export const getCustomer = id => async (dispatch) => {
  dispatch({
    type: customerActions.GET_CUSTOMER_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', `customers/${id}`, null, true));
    dispatch({
      type: customerActions.GET_CUSTOMER_SUCCESS,
      payload: {
        customer: response.data.result,
        password: response.data.password
      }
    });
    return response.data.result;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_CUSTOMER_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getTaxes = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_TAXES_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'taxes', null, true));
    return dispatch({
      type: customerActions.GET_TAXES_SUCCESS,
      payload: {
        taxes: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_TAXES_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getTaxesEnterprise = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_TAXES_ENTERPRISE_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'taxes?type=Empresa', null, true));
    return dispatch({
      type: customerActions.GET_TAXES_ENTERPRISE_SUCCESS,
      payload: {
        taxesEnterprise: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_TAXES_ENTERPRISE_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getCitys = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_CITYS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'citys', null, true));
    return dispatch({
      type: customerActions.GET_CITYS_SUCCESS,
      payload: {
        citys: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_CITYS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getEntitys = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_ENTITYS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'entitys', null, true));
    return dispatch({
      type: customerActions.GET_ENTITYS_SUCCESS,
      payload: {
        entitys: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_ENTITYS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getBankEntitys = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_BANKENTITYS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'bankentitys', null, true));
    return dispatch({
      type: customerActions.GET_BANKENTITYS_SUCCESS,
      payload: {
        bankEntitys: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_BANKENTITYS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getCivilStatus = () => async (dispatch) => {
  dispatch({
    type: customerActions.GET_CIVILSTATUS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', 'civilstatus', null, true));
    return dispatch({
      type: customerActions.GET_CIVILSTATUS_SUCCESS,
      payload: {
        civilStatus: response.data.results
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: customerActions.GET_CIVILSTATUS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const changeIsActive = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.isActive = value;
  return dispatch({
    type: customerActions.CHANGE_ISACTIVE_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeAccountantId = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.accountantId = value;
  return dispatch({
    type: customerActions.CHANGE_ACCOUNTANTID_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeMonthlyPayment = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.monthlyPayment = value;
  return dispatch({
    type: customerActions.CHANGE_MONTHLYPAYMENT_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changePlan = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.plan = value;
  return dispatch({
    type: customerActions.CHANGE_PLAN_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeRuc = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.ruc = value;
  return dispatch({
    type: customerActions.CHANGE_RUC_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeEmployees = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.employees = value;
  return dispatch({
    type: customerActions.CHANGE_EMPLOYEES_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeAddress = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.address = value;
  return dispatch({
    type: customerActions.CHANGE_ADDRESS_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeName = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.name = value;
  return dispatch({
    type: customerActions.CHANGE_NAME_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeLastName = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.lastName = value;
  return dispatch({
    type: customerActions.CHANGE_LASTNAME_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeDisability = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.disability = value;
  return dispatch({
    type: customerActions.CHANGE_DISABILITY_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeObservations = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.observations = value;
  return dispatch({
    type: customerActions.CHANGE_OBSERVATIONS_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeBirthdate = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.birthDate = value;
  return dispatch({
    type: customerActions.CHANGE_BIRTHDATE_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeSignatureExpirationDate = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.signatureExpirationDate = value;
  return dispatch({
    type: customerActions.CHANGE_SIGNATURE_EXPIRATION_DATE,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeNominationExpirationDate = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.nominationExpirationDate = value;
  return dispatch({
    type: customerActions.CHANGE_SIGNATURE_EXPIRATION_DATE,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changePhone = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.phone = value;
  return dispatch({
    type: customerActions.CHANGE_PHONE_INPUT,
    payload: {
      // customer: {address: value},
      patchCustomer: origin
    }
  });
};

export const changeCellPhone = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.cellPhone = value;
  return dispatch({
    type: customerActions.CHANGE_CELLPHONE_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeCity = value => async (dispatch) => {
  const origin = store.getState().customer.patchCustomer;
  origin.city = value;
  return dispatch({
    type: customerActions.CHANGE_CITY_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeSpouse = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.spouse = value;
  return ({
    type: customerActions.CHANGE_SPOUSE_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeChildrens = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.childrens = value;
  return ({
    type: customerActions.CHANGE_CHILDRENS_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeIncomes = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.income = value;
  return ({
    type: customerActions.CHANGE_INCOME_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeExpensesEstimation = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.expensesEstimation = value;
  return ({
    type: customerActions.CHANGE_EXPENSESESTIMATION_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeTaxes = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.taxes = value;
  return ({
    type: customerActions.CHANGE_TAXES_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeAccounts = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.accounts = value;
  return ({
    type: customerActions.CHANGE_ACCOUNTS_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeBankAccount = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.bankAccount = value;
  return ({
    type: customerActions.CHANGE_BANKACCOUNT_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeDocuments = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.documents = value;
  return ({
    type: customerActions.CHANGE_DOCUMENTS_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeTokenDora = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.tokenDora = value;
  return ({
    type: customerActions.CHANGE_TOKEN_DORA_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeFolderLink = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.folderLink = value;
  return ({
    type: customerActions.CHANGE_FOLDER_LINK_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeOriginReport = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.originReport = value;
  return ({
    type: customerActions.CHANGE_ORIGIN_REPORT_INPUT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const changeContact = (value) => {
  const origin = store.getState().customer.patchCustomer;
  origin.contact = value;
  return ({
    type: customerActions.CHANGE_CONTACT,
    payload: {
      patchCustomer: origin
    }
  });
};

export const getDashboardEmailsHistory = (id, date) => async (dispatch) => {
  dispatch({
    type: historyReportActions.GET_DASHBOARD_HISTORY_EMAILS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', `customer/dashboardHistory/${id}?date=${date.format('YYYY-MM')}`, null, true));
    console.log('response', response);
    dispatch({
      type: historyReportActions.GET_DASHBOARD_HISTORY_EMAILS_SUCCESS,
      payload: {
        dashboardHistory: response.data.history
      }
    });
    return response.data.history;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: historyReportActions.GET_DASHBOARD_HISTORY_EMAILS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const resendDashboardEmailsHistory = data => async (dispatch) => {
  dispatch({
    type: historyReportActions.RESEND_DASHBOARD_HISTORY_EMAILS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('POST', 'customer/dashboardHistory', data, true));
    console.log('response', response);
    dispatch({
      type: historyReportActions.RESEND_DASHBOARD_HISTORY_EMAILS_SUCCESS,
      payload: {
      }
    });
    return response.data.history;
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: historyReportActions.RESEND_DASHBOARD_HISTORY_EMAILS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

