import {green600, amber700, red600, cyan900} from 'material-ui/styles/colors';

export const styles = theme => ({
  success: {
    backgroundColor: green600,

  },
  error: {
    backgroundColor: red600,

  },
  info: {
    backgroundColor: cyan900,

  },
  warning: {
    backgroundColor: amber700,

  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
});

export default styles;
