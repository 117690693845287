import * as checklistActions from '../actions/types/checklist';

const initialState = {
  checklist: [],
  message: '',
  dataLength: 0,
  sending: true,
  error: null,
  page: 0,
  rowsPerPage: 10,
  order: 'asc',
  orderBy: 'name',
  searchText: ''
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {checklist, error, message, dataLength, page, rowsPerPage, order, orderBy, searchText} = action.payload;
  switch (action.type) {
  case checklistActions.GET_CHECKLIST_SENDING:
    return {...state, sending: true, dataLength, page, rowsPerPage, order, orderBy, searchText};
  case checklistActions.GET_CHECKLIST_SUCCESS:
    return {...state, sending: false, checklist, error: null};
  case checklistActions.GET_CHECKLIST_FAILED:
    return {...state, sending: false, checklist: [], error, message};
  case checklistActions.PATCH_CHECKLIST_SENDING:
    return {...state, sending: true};
  case checklistActions.PATCH_CHECKLIST_SUCCESS:
    return {...state, sending: false, message: 'Checklist modificado satisfactoriamente', error: null};
  case checklistActions.PATCH_CHECKLIST_FAILED:
    return {...state, sending: false, checklist: [], error, message};
  default:
    return state;
  }
};
