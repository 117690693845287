export const appBarHeight = 64;
const drawerWidth = 240;

export const styles = theme => ({
  main: {
    display: 'block',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: `calc(${appBarHeight + 26}px )`
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginBottom: 25
  },
  FormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 25,
    paddingBottom: 30,
    paddingTop: 15
  },
  select: {
    width: '100%',
    maxWidth: 500,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  FirstFormPaper: {
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: 25,
    paddingBottom: 30,
    paddingTop: 15,
    marginTop: 150
  },
  toolbar: {
    height: 300
  },
  papperWraper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 20
  },
  labelInput: {
    color: '#8CA0B3',
    fontSize: 18
  },
  textInput: {
    color: '#697B8C',
    fontWeight: 500,
    fontSize: 14
  },
  labelInputFile: {
    color: '#8CA0B3',
    fontSize: 16
  },
  title:
  {
    flex: 'auto',
    paddingLeft: 40,
    color: '#697B8C',
    fontSize: 20,
    fontWeight: 'bold'
  },
  form: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  button: {
    backgroundColor: '#2797E8',
    color: 'white',
    fontSize: 14,
    fontWeight: 'normal',
    margin: 10,
    marginRight: 40,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 10,
    paddingBottom: 10
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: 20
  },
  input: {
    maxWidth: 500,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  inputWrapper: {
    paddingLeft: 20,
    paddingRight: 20
  },
  gridContainer:
  {
    width: '95%'
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    width: '95%'
  },
  wrapDivider: {
    justifyContent: 'center'
  },
  appBar: {
    marginTop: 90,
    display: 'flex',
    right: 'auto',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - (${drawerWidth}px + 65px))`
    },
    background: '#FFFFFF'
  },
  observationsInput: {
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  uploadButton: {
    marginTop: 20,
    marginBottom: 20
  }
});
