import 'moment/locale/es';
import React, {Component} from 'react';
import {Provider} from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import store from './store';
import Router from './router';
import {theme} from './utils/theme';

moment.locale('es');

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale="es" moment={moment}>
          <MuiThemeProvider theme={theme}>
            <Router />
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    );
  }
}
