import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {styles} from './styles';

export class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: []
    };
    this.onChangeEntity = this.onChangeEntity.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteRegister = this.onDeleteRegister.bind(this);
    this.handleUpdateAccounts = this.handleUpdateAccounts.bind(this);
  }

  componentWillMount() {
    const accontsArray = [];
    this.props.accounts.forEach((account) => {
      const newAccount = {
        entity: account.entity,
        user: account.user,
        password: account.password,
        showPassword: false
      };
      accontsArray.push(newAccount);
    });
    this.setState({accounts: accontsArray});
  }

  onAddClick() {
    const accountsArray = this.state.accounts;
    const account = {
      entity: '',
      user: '',
      password: '',
      showPassword: true
    };
    accountsArray.push(account);
    this.setState({accounts: accountsArray});
  }

  onDeleteRegister(key, e) {
    const accountsArray = this.state.accounts;
    accountsArray.splice(key, 1);
    this.setState({accounts: accountsArray});
    this.handleUpdateAccounts();
  }

  onChangeUser(key, e) {
    const accountsArray = this.state.accounts;
    accountsArray[key].user = e.target.value;
    this.setState({accounts: accountsArray});
    this.handleUpdateAccounts();
  }

  onChangeEntity(key, e) {
    const accountsArray = this.state.accounts;
    accountsArray[key].entity = e.target.value;
    this.setState({accounts: accountsArray});
    this.handleUpdateAccounts();
  }

  onChangePassword(key, e) {
    const accountsArray = this.state.accounts;
    accountsArray[key].password = e.target.value;
    this.setState({accounts: accountsArray});
    this.handleUpdateAccounts();
  }

  handleClickShowPassword(key, e) {
    const accountsArray = this.state.accounts;
    if (accountsArray[key].showPassword) {
      accountsArray[key].showPassword = false;
    } else {
      accountsArray[key].showPassword = true;
    }
    this.setState({accounts: accountsArray});
  }

  handleUpdateAccounts() {
    const accontsArray = [];
    this.state.accounts.forEach((account) => {
      const newAccount = {
        entity: account.entity,
        user: account.user,
        password: account.password
      };
      accontsArray.push(newAccount);
    });
    this.props.updateAccounts(accontsArray);
    this.props.onChange();
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.FormPaper}>
        <Toolbar className={classes.toolbarWrapper}>
          <Typography className={classes.title}>
              Usuarios y contraseñas
          </Typography>
          <div className={classes.spacer} />
          <Button variant="contained" className={classes.addButton} onClick={this.onAddClick} >
            <AddIcon style={{cursor: 'pointer', color: '#697B8C'}} />
              Agregar registro
          </Button>
        </Toolbar>
        <div className={classes.wrapDivider} align="center" >
          <Divider className={classes.divider} />
        </div>

        <form className={classes.container} noValidate autoComplete="off">
          {
            this.state.accounts.map((account, key) => {
              return (
                <Grid container spacing={3} className={classes.gridWrapper} key={key} >
                  <Grid item xs={11} className={classes.inputWrapper}>
                    <Grid container spacing={3} className={classes.gridContainer} key={key} >
                      <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                        <FormControl className={classes.select}>
                          <InputLabel htmlFor="entity" className={classes.labelInput}>Entidad</InputLabel>
                          <Select
                            value={account.entity}
                            onChange={(e) => { this.onChangeEntity(key, e); }}
                            displayEmpty
                            className={classes.textInput}
                          >
                            {
                              this.props.entitys.map((entity, identifier) => {
                                return (
                                  <MenuItem value={entity.name} key={identifier} >{entity.name}</MenuItem>
                                );
                              })
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                        <TextField
                          value={account.user}
                          onChange={(e) => { this.onChangeUser(key, e); }}
                          fullWidth
                          label="Usuario"
                          className={classes.input}
                          InputProps={{
                            classes: {
                              input: classes.textInput}}}
                          InputLabelProps={{
                            FormLabelClasses: {
                              root: classes.labelInput
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} className={classes.inputWrapper}>
                        <TextField
                          value={account.password}
                          onChange={(e) => { this.onChangePassword(key, e); }}
                          fullWidth
                          type={account.showPassword ? 'text' : 'password'}
                          label="Contraseña"
                          className={classes.input}
                          InputLabelProps={{
                            FormLabelClasses: {
                              root: classes.labelInput
                            }
                          }}
                          InputProps={{
                            classes: {
                              input: classes.textInput
                            },
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={(e) => { this.handleClickShowPassword(key, e); }}
                                >
                                  {account.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.inputWrapper}>
                    <div className={classes.deleteContainer}>
                      <Button variant="contained" className={classes.addButton} onClick={(e) => { this.onDeleteRegister(key, e); }} >
                        <DeleteIcon style={{cursor: 'pointer', color: '#697B8C'}} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              );
            })
          }
        </form>
      </Paper>
    );
  }
}

Accounts.propTypes = {
  classes: PropTypes.object.isRequired,
  entitys: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default (withStyles(styles)(Accounts));
