import * as securityActions from '../actions/types/security';

const initialState = {
  user: null,
  sending: false,
  token: null,
  error: null,
  message: null
};

export default (state, action) => {
  if (state === undefined) {
    return initialState;
  }
  if (action === undefined && action.type === undefined) {
    return initialState;
  }
  const {error, token, user, message} = action.payload;
  switch (action.type) {
  case securityActions.LOGIN_SENDING:
    return {...state, sending: true, response: false, error: null};
  case securityActions.LOGIN_SUCCESS:
    return {...state, sending: false, response: true, user, token, message: 'Inicio de sesión satisfactorio', error: null};
  case securityActions.LOGIN_FAILED:
    return {...state, sending: false, response: true, error, message};
  case securityActions.REQUEST_CHANGE_PASSWORD_SENDING:
    return {...state, sending: true, response: false, error: null};
  case securityActions.REQUEST_CHANGE_PASSWORD_SUCCESS:
    return {...state, sending: false, response: true, message: 'Revisa tu correo para cambiar tu contraseña', error: null};
  case securityActions.REQUEST_CHANGE_PASSWORD_FAILED:
    return {...state, sending: false, response: true, error, message};
  case securityActions.CHANGE_PASSWORD_SENDING:
    return {...state, sending: true, response: false, error: null};
  case securityActions.CHANGE_PASSWORD_SUCCESS:
    return {...state, sending: false, response: true, message, error: null};
  case securityActions.CHANGE_PASSWORD_FAILED:
    return {...state, sending: false, response: true, error, message};
  case securityActions.ACTIVE_PASSWORD_SENDING:
    return {...state, sending: true, response: false, error: null};
  case securityActions.ACTIVE_PASSWORD_SUCCESS:
    return {...state, sending: false, response: true, user, message, error: null};
  case securityActions.ACTIVE_PASSWORD_FAILED:
    return {...state, sending: false, error, message};
  default:
    return state;
  }
};
