import axios from 'axios';
import * as documentsActions from './types/documents';
import {axiosMaker, errorMessage, axiosMakerFilters, axiosFormData} from '../utils/utils';
// import store from '../store';

export const getDocumentsCustomer = (id, searchText, page, rowsPerPage, orderBy, order, month) => async (dispatch) => {

  dispatch({
    type: documentsActions.GET_DOCUMENTS_SENDING,
    payload: {searchText, page, rowsPerPage, orderBy, order, month}
  });
  try {

    const filter = {};
    if (month) {
      filter.month = month;
    }
    const data = {
      searchText,
      page,
      limit: rowsPerPage,
      sortBy: orderBy,
      order,
      filter
    };
    const response = await axios(axiosMakerFilters('GET', `customer/${id}/documents`, data, true));
    return dispatch({
      type: documentsActions.GET_DOCUMENTS_SUCCESS,
      payload: {
        documents: response.data.results,
        dataLength: response.data.total
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.GET_DOCUMENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const createDocument = data => async (dispatch) => {
  dispatch({
    type: documentsActions.CREATE_DOCUMENT_SENDING,
    payload: {
    }
  });
  try {
    const response = await axios(axiosMaker('POST', 'documents', data, true));
    return dispatch({
      type: documentsActions.CREATE_DOCUMENT_SUCCESS,
      payload: {
        user: response.data.user,
        token: response.data.token
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.CREATE_DOCUMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const getDocument = id => async (dispatch) => {
  dispatch({
    type: documentsActions.GET_DOCUMENT_SENDING,
    payload: {}
  });
  try {
    await axios(axiosFormData('GET', `documents/${id}`, null, true));
    return dispatch({
      type: documentsActions.GET_DOCUMENT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.GET_DOCUMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const deleteDocument = id => async (dispatch) => {
  dispatch({
    type: documentsActions.DELETE_DOCUMENT_SENDING,
    payload: {}
  });
  try {
    await axios(axiosFormData('DELETE', `documents/${id}`, null, true));
    return dispatch({
      type: documentsActions.DELETE_DOCUMENT_SUCCESS,
      payload: {}
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.DELETE_DOCUMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const patchDocumentsCustomer = id => async (dispatch) => {
  dispatch({
    type: documentsActions.GET_DOCUMENTS_SENDING,
    payload: { }
  });
  try {
    const response = await axios(axiosMaker('GET', `customers/${id}`, null, true));
    return dispatch({
      type: documentsActions.GET_DOCUMENTS_SUCCESS,
      payload: {
        customer: response.data.result
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.GET_DOCUMENTS_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};

export const resendEmail = data => async (dispatch) => {
  dispatch({
    type: documentsActions.RESEND_EMAIL_DOCUMENT_SENDING,
    payload: {
    }
  });
  try {
    const response = await axios(axiosMaker('POST', 'documents/resendEmail', data, true));
    return dispatch({
      type: documentsActions.RESEND_EMAIL_DOCUMENT_SUCCESS,
      payload: {
        resendResponse: true
      }
    });
  } catch (e) {
    const error = e.response;
    return dispatch({
      type: documentsActions.RESEND_EMAIL_DOCUMENT_FAILED,
      payload: {
        error,
        message: errorMessage(error)
      }
    });
  }
};
