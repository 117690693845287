import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {styles} from './styles';
import {resendEmail} from '../../actions/documents';
import CustomSnackbar from '../common/customSnackbar';
import {isEmail} from '../../utils/utils';


const EmailsHistory = (props) => {
  const [open, setOpen] = React.useState(false);
  const [viewForm, setViewForm] = React.useState(false);
  const {description, classes, row, resending, resendingError, messageResending, onResendEmail} = props;
  const {sendHistory} = row;
  const [hasValidEmailList, setHasValidEmailList] = React.useState(false);
  const [emailList, setEmailList] = React.useState(null);

  const handleChange = (event) => {
    setEmailList(event.target.value);
    console.log('event.target.value', event.target.value);
    if (event.target.value) {
      const formattedEmail = event.target.value.split(',');
      formattedEmail.every((emailItem) => {
        console.log(emailItem);
        if (!isEmail(emailItem)) {
          setHasValidEmailList(false);
          return false;
        }
        setHasValidEmailList(true);
        return true;
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewForm(false);
  };

  const setOpenForm = () => {
    setViewForm(true);
  };

  const renderSendDate = (sendDate) => {
    console.log('sendDate', sendDate);
    return sendDate ? moment.utc(sendDate).format('YYYY-MM-DD') : '';
  };

  const resendAction = async () => {
    console.log('send');
    const sendEmail = await props.resendEmail({emailList, documentId: row.id});
    console.log('sendEmail', sendEmail);
    setViewForm(false);
    setEmailList(null);
    handleClose();
    onResendEmail();
  };

  const renderSnackBar = () => {
    if (messageResending) {
      return (
        <CustomSnackbar
          variant={resendingError ? 'error' : 'success'}
          open
          message={messageResending}
          className={classes.snackbar}
        />
      );
    }
    return '';
  };

  const BasicTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fecha de envío</TableCell>
              <TableCell align="left">Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sendHistory.map(rowItem => (
              <TableRow key={rowItem.name}>
                <TableCell component="th" scope="row">
                  {renderSendDate(rowItem.sendDate)}
                </TableCell>
                <TableCell align="left">{rowItem.emails.join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderForm = () => {
    return (
      <div className={classes.root}>
        <TextField
          id="emailList"
          classes={classes.textField}
          label="Correos"
          value={emailList}
          onChange={handleChange}
          placeholder="email1@email.com,email2@gmail.com"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
        />
        <div className={classes.actionContainer}>
          <br />
          <Button onClick={resendAction} variant="outlined" color="primary" disabled={resending || !hasValidEmailList}> 
            Enviar
          </Button>
          <br />
        </div>
      </div>);
  };


  return (
    <div>
      {renderSnackBar()}
      <Button color="primary" onClick={handleClickOpen} className={classes.descriptionButton}>
        {description}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">Histórico de envío
        </DialogTitle>
        <DialogContent>
          {viewForm ? renderForm() : <BasicTable />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cerrar
          </Button>
          { viewForm ? '' :
            (
              <Button onClick={setOpenForm} color="primary">
            Reenviar
              </Button>)}
        </DialogActions>
      </Dialog>
    </div>
  );
};


EmailsHistory.propTypes = {
  description: PropTypes.bool.isRequired, 
  classes: PropTypes.bool.isRequired,
  row: PropTypes.object,
  resendEmail: PropTypes.func.isRequired,
  resending: PropTypes.bool,
  resendingError: PropTypes.bool,
  messageResending: PropTypes.string,
  onResendEmail: PropTypes.func

};

const mapStateToProps = (state) => {
  const {documents} = state;
  
  return {
    resending: documents.resending,
    resendingError: documents.resendingError,
    messageResending: documents.messageResending
  };
};

const mapDispatchToProps = {
  resendEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EmailsHistory));
  
