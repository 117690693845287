export const GET_CHECKLIST_SENDING = 'GET_CHECKLIST_SENDING';

export const GET_CHECKLIST_SUCCESS = 'GET_CHECKLIST_SUCCESS';

export const GET_CHECKLIST_FAILED = 'GET_CHECKLIST_FAILED';

export const PATCH_CHECKLIST_SENDING = 'PATCH_CHECKLIST_SENDING';

export const PATCH_CHECKLIST_SUCCESS = 'PATCH_CHECKLIST_SUCCESS';

export const PATCH_CHECKLIST_FAILED = 'PATCH_CHECKLIST_FAILED';
