import * as commentsActions from '../actions/types/comments';

const initialState = {
    comments: null,
    message: null,
    loading: true,
    error: null,
};

export const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case commentsActions.GET_COMMENT_SENDING:
            return { ...state, loading: action.payload.loading };
        case commentsActions.GET_COMMENT_SUCCESS:
            return {
                ...state,
                comments: action.payload.comments,
                loading: action.payload.loading
            };
        case commentsActions.GET_COMMENT_FAILED:
            return {
                ...state,
                loading: action.payload.loading,
                error: action.payload.error,
                message: action.payload.message,
            };
        case commentsActions.CREATE_COMMENT_SENDING:
            return { ...state, loading: action.payload.loading };
        case commentsActions.CREATE_COMMENT_SUCCESS:
            return {
                ...state,
                loading: action.payload.loading
            };
        case commentsActions.GET_COMMENT_FAILED:
            return {
                ...state,
                loading: action.payload.loading,
                error: action.payload.error,
                message: action.payload.message,
            };

        default:
            return state;
    }
};
