export default theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  logo: {
    width: '60%',
    marginBottom: 20
  },
  form: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(5),
    backgroundColor: '#2797E8',
    color: 'white',
    fontSize: 13,
    marginBottom: theme.spacing(3),
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: '100',
    '&:hover': {
      backgroundColor: '#697B8C'
    }
  },

  planTitle: {
    color: '#8CA0B3'
  },

  input: {
    marginTop: theme.spacing(2)
  },

  divider: {
    marginTop: 20,
    marginBottom: 20,
    width: '25%',
    backgroundColor: '#2797E8'
  },
  wrapDivider: {
    justifyContent: 'center'
  }
});
